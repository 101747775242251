import React, { Fragment } from 'react';

const Footer = () => {
    return (
        <Fragment >
            <div className='mt-5 pt-5'></div>
            <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100" hidden>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start fw-bold">
                            <p className="mb-2 mb-md-0 fw-bold">Grupo Defensa © 2022</p>
                        </div>
                        <div className="col-md-6 text-center text-md-end text-gray-400">
                            <p className="mb-0">Version 0.4.0</p>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;
