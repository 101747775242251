import React, { Fragment, useState, useEffect } from 'react'
import { Card, Form, Button, FloatingLabel, Modal, Toast, Col, Row, Table, Tab, Tabs } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import tableCustomStyles from '../components/TableStyle'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'

import { getUseDocsTotalAction, getUseDocsMonthAction, getUseDocsByUserAction } from '../Redux/GeneralDucks'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import { Chart } from "react-google-charts"



const paginationOptions = {
    rowsPerPageText: 'Mostrar',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
}
const FilterComponent = ({ onFilter, onClear, filterText }) => (
    <>
        <Col>
            <Form.Control
                type="text"
                placeholder="Buscar por Nombre"
                value={filterText}
                onChange={onFilter}
            />
        </Col>
        <Col>
            <Button type="button" onClick={onClear}>X</Button>
        </Col >
    </>
)


const UsoDocumentosPage = () => {
    const dispatch = useDispatch()

    const UseDocsTotal = useSelector(store => store.general.getUseDocsTotal)
    const UseDocsMonth = useSelector(store => store.general.getUseDocsMonth)
    const UseDocsByUsers = useSelector(store => store.general.getUseDocsByUsers)

    const [DataMensual, setDataMensual] = useState([])
    const [ColumnaMensual, setColumnaMensual] = useState([{ name: "Nombre", cell: (row) => row.name, selector: row => row.name, sortable: true }])

    const [filterText, setFilterText] = useState('')
    const [filterTextMonth, setFilterTextMonth] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const [resetPaginationToggleMonth, setResetPaginationToggleMonth] = useState(false)
    const [listUsersByDoc, setListUsersByDoc] = useState([])


    useEffect(() => {
        if (UseDocsTotal.length > 0) {
            for (let i = 0; i < UseDocsTotal.length; i++) {
                UseDocsTotal[i].percent = Number(UseDocsTotal[i].percent).toFixed(1)
            }
        }
    }, [UseDocsTotal])

    useEffect(() => {
        if (UseDocsByUsers.length > 0) {
            console.log(UseDocsByUsers)
        }else{
            setListUsersByDoc([])
        }
    }, [UseDocsByUsers])


    useEffect(() => {
        dispatch(getUseDocsTotalAction())
        dispatch(getUseDocsMonthAction())
        dispatch(getUseDocsByUserAction())

    }, [])

    useEffect(() => {
        if (UseDocsMonth.length > 0) {
            // Crear columnas dinámicamente
            let auxC = [{ name: "Nombre", selector: row => row.name, sortable: true }]
            const getObjectKeys = Object.keys(UseDocsMonth[0].activities)
            getObjectKeys.forEach(fecha => {
                let obj = { name: `${fecha}`, selector: row => row[fecha], sortable: true }
                auxC.push(obj)
            })
            setColumnaMensual(auxC)

            // Crear datos dinámicamente
            let aux = UseDocsMonth.map(user => ({
                name: user.nombre,
                ...user.activities
            }))
            setDataMensual(aux)

            console.log(UseDocsMonth)
        }
    }, [UseDocsMonth])



    const handleShow = (row) => {
        const docs = UseDocsByUsers.find(item => item.name == row.name )
        setListUsersByDoc(docs.users)

        /*setShow(true)
        setRutActivo(row)
*/
    }

    const columnaDocsByUsers = [
        {
            name: "Nombre", cell: (row) => <Button onClick={() => handleShow(row)} variant="link"> {row.name} </Button>,
            ignoreRowClick: true,
            button: true,
            width: '15rem'
        },
        { name: "Total", cell: (row) => row.total, selector: row => row.total, sortable: true },
    ]
    const columnaUsersFromDoc = [
        { name: "Usuario", cell: (row) => row.user, selector: row => row.user, sortable: true },
        { name: "Creados", cell: (row) => row.count, selector: row => row.count, sortable: true },
    ]

    const columnasTotal = [
        { name: "Nombre", cell: (row) => row.name, selector: row => row.name, sortable: false },
        { name: "Total", cell: (row) => row.count, selector: row => row.count, sortable: true },
        { name: "Porcentaje", cell: (row) => row.percent, selector: row => row.percent, sortable: true }
    ]

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText('')
            }
        }

        return (<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />)
    }, [filterText, resetPaginationToggle])

    const subHeaderComponentMemoMonth = React.useMemo(() => {
        const handleClearMonth = () => {
            if (filterTextMonth) {
                setResetPaginationToggleMonth(!resetPaginationToggleMonth)
                setFilterTextMonth('')
            }
        }
        return (<FilterComponent onFilter={e => setFilterTextMonth(e.target.value)} onClear={handleClearMonth} filterText={filterTextMonth} />)
    }, [filterTextMonth, resetPaginationToggleMonth])


    const FilterUseDocsTotal = UseDocsTotal.filter(item => {
        return filterText ? item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) : true
    })

    const FilterUseDocsMonth = DataMensual.filter(item => {
        return filterTextMonth ? item.name && item.name.toLowerCase().includes(filterTextMonth.toLowerCase()) : true
    })

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <Card className="mb-4 w-100" style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title><h2 className="page-heading">Uso de Documentos</h2></Card.Title>
                        <Tabs
                            defaultActiveKey="Total"
                            id="uncontrolled-tab-example"
                            className="mb-1">
                            <Tab eventKey="Total" title="Total">
                                {
                                    <DataTable className='table'
                                        data={FilterUseDocsTotal}
                                        columns={columnasTotal}
                                        customStyles={tableCustomStyles}
                                        sortable={true}
                                        pagination
                                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                        subHeaderComponent={subHeaderComponentMemo}
                                        paginationComponentOptions={paginationOptions}
                                        subHeader
                                        persistTableHead />
                                }
                            </Tab>
                            <Tab eventKey="Mensual" title="Mensual">
                                {DataMensual.length > 0 && ColumnaMensual.length > 1 ?
                                    <DataTable className='table'
                                        data={FilterUseDocsMonth}
                                        columns={ColumnaMensual}
                                        customStyles={tableCustomStyles}
                                        sortable={true}
                                        pagination
                                        paginationResetDefaultPage={resetPaginationToggleMonth} // optionally, a hook to reset pagination to page 1
                                        subHeaderComponent={subHeaderComponentMemoMonth}
                                        paginationComponentOptions={paginationOptions}
                                        subHeader
                                        persistTableHead /> : null
                                }
                            </Tab>
                            <Tab eventKey="PorDocumentos" title="Por Documentos">
                                <Row>
                                    <Col sm={6}>
                                        <DataTable className='table'
                                            data={UseDocsByUsers}
                                            columns={columnaDocsByUsers}
                                            persistTableHead 
                                            />
                                    </Col>
                                    <Col sm={6}>
                                        <DataTable className='table'
                                            data={listUsersByDoc}
                                            columns={columnaUsersFromDoc}
                                            /*customStyles={tableCustomStyles}
                                            sortable={true}
                                            pagination
                                            paginationResetDefaultPage={resetPaginationToggleMonth} // optionally, a hook to reset pagination to page 1
                                            subHeaderComponent={subHeaderComponentMemoMonth}
                                            paginationComponentOptions={paginationOptions}
                                            subHeader*/
                                            persistTableHead />
                                    </Col>
                                </Row>


                            </Tab>
                        </Tabs>





                    </Card.Body>
                </Card>
            </div>



        </Fragment>


    )
}

export default UsoDocumentosPage
