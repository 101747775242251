import React, { Fragment, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';

const Menu = () => {
    const [openSubMenu, setOpenSubMenu] = useState("");
    const marginIcons = {
        marginLeft:"10px",
        marginRight:"5px"
    }
    const SubMenuPortafolio=[
        {id:'submenu-portafolio-causa',icon:<Icon.Bank style={marginIcons} />, name:'Causas',toLink: "/portafolio/causas"},
        //{id:'submenu-portafolio-movimiento',icon:<Icon.ExclamationSquare style={marginIcons} />, name:'Movimientos',toLink: "/portafolio/movimientos"},
        {id:'submenu-portafolio-novedad',icon:<Icon.Newspaper style={marginIcons} />, name:'Novedades',toLink: "/portafolio/novedades"},
        {id:'submenu-portafolio-estado-diario',icon:<Icon.Briefcase style={marginIcons} />, name:'Estado Diario',toLink: "/portafolio/estado-diario"},
        {id:'submenu-portafolio-estado-diario',icon:<Icon.Magnet style={marginIcons} />, name:'Monitoreo Rut',toLink: "/portafolio/monitoreo-rut"},
    ]
    const SubMenuSac=[
        {id:'submenu-admin-clientes',icon:<Icon.People style={marginIcons} />, name:'Clientes',toLink: "/administracion/clientes"},
        //{id:'submenu-admin-clientes',icon:<Icon.People style={marginIcons} />, name:'Clientes',toLink: "/administracion/clientes"},
        //{id:'submenu-sac-cliente',icon:<Icon.People style={marginIcons} />, name:'Clientes',toLink: "/sac/cliente"},
        //{id:'submenu-sac-causa',icon:<Icon.Bank style={marginIcons} />, name:'Causas',toLink: "/sac/causas"},
    ]

    const SubMenuGestion=[
        {id:'submenu-gestion-tareas',icon:<Icon.JournalCheck style={marginIcons} />, name:'Tareas',toLink: "/gestion/tareas"},
        {id:'submenu-gestion-calendario',icon:<Icon.Calendar3 style={marginIcons} />, name:'Calendario',toLink: "/gestion/calendario"},
        {id:'submenu-gestion-flujos',icon:<Icon.Bezier2 style={marginIcons} />, name:'Flujos',toLink: "/gestion/flujos"},
        {id:'submenu-gestion-plantillas',icon:<Icon.FileEarmarkPpt style={marginIcons} />, name:'Plantillas',toLink: "/gestion/plantillas"},
    ]

    const SubMenuAdmin=[
        //{id:'submenu-admin-clientes',icon:<Icon.People style={marginIcons} />, name:'Clientes',toLink: "/administracion/clientes"},
        //{id:'submenu-admin-datos',icon:<Icon.Clipboard style={marginIcons} />, name:'Administración Datos',toLink: "/administracion/datos"},
        {id:'submenu-admin-usuarios',icon:<Icon.Person style={marginIcons} />, name:'Usuarios',toLink: "/administracion/usuarios"},
        {id:'submenu-admin-documentos',icon:<Icon.File style={marginIcons} />, name:'Uso Documentos',toLink: "/administracion/uso_documentos"},
        //{id:'submenu-admin-usuarios',icon:<Icon.SignpostSplit style={marginIcons} />, name:'Reasignar Tareas',toLink: "/administracion/reasignar_tareas"},
        //{id:'submenu-admin-credenciales',icon:<Icon.PersonVcard style={marginIcons} />, name:'Credenciales',toLink: "/administracion/credenciales"},
        //{id:'submenu-admin-correos',icon:<Icon.Envelope style={marginIcons} />, name:'Correos',toLink: "/administracion/correos"},
    ]
    
    const _Menu = [
        {id:'menu-inicio',icon:<Icon.HouseDoor />, name:'Inicio',toLink: "/inicio", subMenu:null},
        {id:'menu-portafolio',icon:<Icon.Briefcase />, name:'Portafolio',toLink: '#', subMenu:SubMenuPortafolio},
        {id:'menu-sac',icon:<Icon.PersonWorkspace />, name:'S.A.C.',toLink: '#', subMenu:SubMenuSac},
        //{id:'menu-gestion',icon:<Icon.Buildings />, name:'Gestión',toLink: '#', subMenu:SubMenuGestion},
        {id:'menu-admin',icon:<Icon.ColumnsGap />, name:'Administración',toLink: '#', subMenu:SubMenuAdmin},
    ]

    const onClickMenu = (event) =>{
		if (event.target.getAttribute("value") == openSubMenu)
			setOpenSubMenu('')
		else
			setOpenSubMenu(event.target.getAttribute("value")) 
    }

    return (
        <Fragment>
            <div className="sidebar py-0" id="sidebar" style={{maxWidth:'11rem'}}>
                <h6 className="sidebar-heading">Menu</h6>
                <ul className="list-unstyled">
                    {_Menu.map(obj => {
                        return  (
                            <li className="sidebar-list-item" key={obj.id}>
                                <NavLink  onClick={onClickMenu} value={obj.id} className={({ isActive }) => (isActive ? "sidebar-link text-muted" + (openSubMenu==obj.id ?" bg-primary text-white":"") : "sidebar-link text-muted")} to={obj.toLink} role="button" >
                                    <span value={obj.id} className="sidebar-link-title">{obj.icon} {obj.name} </span>
                                </NavLink>
                                {(obj.subMenu && openSubMenu==obj.id ) ? 
                                <ul className="sidebar-menu list-unstyled" id="dashboardsDropdown" >
                                    {obj.subMenu.map(el => {return (
                                        <li className="sidebar-list-item" key={el.id}>
                                            <NavLink className={"sidebar-link  text-muted "} to={el.toLink}>{el.icon} {el.name}</NavLink>
                                        </li>
                                    )})}
                                </ul>:null}
                            </li>)
                    })}
                    
                </ul>
            </div>
        </Fragment>
    );
}

export default Menu;
