import axios from 'axios'
import Cookies from 'universal-cookie'

import { CleanJsonData, ClearCookies } from '../Utilities/Utilities.js'

//const
const data = {
    listUser: { success: false, message: null, list: [] }
}

const LOG_IN_USER = 'LOG_IN_USER'
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'

// reducer
export default function userReducer(state = data, action) {
    switch (action.type) {
        case LOG_IN_USER:
            return { ...state, user: action.payload }            
        case GET_USER_LIST_SUCCESS:
                return { ...state, listUser: action.payload }
        default:
            return state
    }
}


export const GetListUsers = (DATA) => async (dispatch, getState) => {

    const cookies = new Cookies()
    DATA = CleanJsonData(DATA)

    dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: { success: false, message: window.messagesAxios.getListUsersStart, list: [] }
    })

    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_ALLUSER, null,{headers: {'Authorization': `Bearer ${cookies.get('token')}`}})
        //console.log(RES)
        if(RES.status === 200 ){
            dispatch({
                type: GET_USER_LIST_SUCCESS,
                payload: { success: false, message: window.messagesAxios.getListUsersSuccess, list: RES.data }
            })
        }
        else {
            dispatch({
                type: GET_USER_LIST_SUCCESS,
                payload: { success: false, message: window.messagesAxios.getListUsersError, list: [] }
            })
        }

    } catch (error) {
        if (error.response.status === 404) {
            dispatch({
                type: GET_USER_LIST_SUCCESS,
                payload: { success: false, message: error.response.data, list: [] }
            })
        }
        else {
            dispatch({
                type: GET_USER_LIST_SUCCESS,
                payload: { success: false, message: window.messagesAxios.getListUsersError, user: null }
            })
        }
    }
}