import React, { Fragment, useState, useEffect } from 'react'
import { Form, Card, Button, Row, Col, Container, Alert, Spinner, InputGroup  } from 'react-bootstrap'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import { putClientUpdate, getAdminClientsAdvancedAction } from '../Redux/CausasDucks'
import {display_errors} from '../Utilities/Utilities.js'

const AdministracionClientesDetallePage = () => {
    
    const { register, handleSubmit, formState: { errors }, setError, getValues /*, control : control*/ } = useForm()


    const dispatch = useDispatch()
    const [client,setClient]= useState([]) 
    const [aux,setAux]= useState(0)  
    const [byrut,setbyrut]= useState(false)   


    const location = useLocation()
    const clientState = location.state?.data ?location.state?.data: null;

    useEffect(() => { 
        if(clientState){
            console.log(clientState)
            if(clientState.rut){
                dispatch(getAdminClientsAdvancedAction({rut:clientState.rut}))
                setbyrut(true)
            }
        }
        setAux(aux+1)
    }, [clientState]);


	const clientesB = useSelector(store => store.causas.clientes)
	const updateClient = useSelector(store => store.causas.updateClient)
    useEffect(() => { 
        console.log(updateClient)
        if(updateClient.client){
            let aux = client
            let auxUpd = updateClient.client
            setClient({ ...aux, auxUpd })
        }
    }, [updateClient]);
  

    useEffect(() => { 
        console.log(clientesB)
        if(byrut && clientesB){
            setClient(clientesB[0].result)
        }
    }, [clientesB]);


  
    const _Breadcrumb = (
        <div className="page-breadcrumb">
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <NavLink to="/Inicio">Administración</NavLink>
                </li>
                <li className="breadcrumb-item active">
                    <NavLink to="/administracion/clientes">Clientes</NavLink>
                </li>
            </ul>
        </div>)

    const onChangeClient = (e) => {
        try{
        let auxC = client;
        console.log(auxC)
        auxC[e.target.name] = e.target.value
        setClient(auxC)
        setAux(aux+1)
        }catch(error){
            display_errors(error, getValues, setError)
        }
    }
    const onUpdateClient = (e) => {
        e.clientId = client.clientId
        dispatch(putClientUpdate(e))        
    } 

    return (
        <div className="container-fluid px-lg-1 px-xl-3">
            {_Breadcrumb}
            <Container fluid="md">
                <Row md={2}>
                    {client?
                    <Fragment>
                        <Col>
                            <Card className='w-100' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Title>{client.nameClient}</Card.Title>        
                                    <Card.Text>
                                        <Form onSubmit={handleSubmit(onUpdateClient)}>
                                            <Form.Control value={client.clientId} {...register("clientId")} hidden/>
                                            
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nombre Cliente</Form.Label>
                                                <Form.Control value={client.nameClient} /*name={"nameClient"} {...register("nameClient")}*/ readOnly/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Rut Cliente</Form.Label>
                                                <Form.Control value={client.rut} /*name={"rut"} {...register("rut")}*/ readOnly/>
                                            </Form.Group>
                                            
                                            <Form.Group className="mb-3">
                                                <Form.Label className='fw-bold'>Clave Unica</Form.Label>
                                                <Form.Control className='fw-bold' value={client.claveUnica} name={"claveUnica"} {...register("claveUnica", {onChange: onChangeClient})}/>
                                            </Form.Group>

                                            {/*<Form.Group className="mb-3">
                                                <Form.Label>Celular (principal)</Form.Label>
                                                <InputGroup.Text id="basic-addon1">+569</InputGroup.Text>
                                                <Form.Control aria-describedby="basic-addon1" value={client.phone} name={"phone"} {...register("phone", {onChange: onChangeClient})} pattern="569\d{7,9}"/>
                                            </Form.Group>*/}

                                            <InputGroup className="mb-3">
                                                <Form.Label className='w-100 '>Celular (principal)</Form.Label>
                                                <InputGroup.Text id="basic-addon1">+569</InputGroup.Text>
                                                <Form.Control aria-describedby="basic-addon1" value={client.phone} name={"phone"} {...register("phone", {onChange: onChangeClient})} isInvalid={errors.phone} pattern="\d{8}"/>
                                                <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>       
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Label className='w-100 '>Telefono/Celular (opcional)</Form.Label>
                                                <InputGroup.Text id="basic-addon1">+56</InputGroup.Text>
                                                <Form.Control aria-describedby="basic-addon1" value={client.phone2} name={"phone2"} {...register("phone2", {onChange: onChangeClient})} isInvalid={errors.phone2} pattern="\d{9}"/>
                                                <Form.Control.Feedback type="invalid">{errors.phone2?.message}</Form.Control.Feedback>       
                                            </InputGroup>


                                            <Form.Group className="mb-3">
                                                <Form.Label>Direccion Cliente</Form.Label>
                                                <Form.Control value={client.address} name={"address"} {...register("address", {onChange: onChangeClient})}/>
                                            </Form.Group>
                                            {/*<Form.Group className="mb-3">
                                                <Form.Label>Correo Cliente</Form.Label>
                                                <Form.Control value={client.email} name={"email"} {...register("email", {onChange: onChangeClient})}/>
                                            </Form.Group>*/}
                                            <Form.Group className="mb-3">
                                                <Form.Label>Correo Clientes</Form.Label>
                                                <Form.Control type="email" value={client.email} name={"email"}  {...register("email", { onChange: onChangeClient, pattern: { value: /\S+@\S+\.\S+/, message: "El correo no tiene un formato valido" } })}/>
                                            </Form.Group>
                                            

                                            <Form.Group className="mb-3">
                                                <Form.Label>Usuario SAC</Form.Label>
                                                <Form.Control value={client.sac} name={"sac"} {...register("sac", {onChange: onChangeClient})} readOnly/>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control as="textarea" value={client.observations} name={"observations"} {...register("observations", {onChange: onChangeClient})}/>
                                            </Form.Group>
                                            <Button type='submit'>Actualizar Datos</Button>
                                        </Form>
                                    </Card.Text>
                                    { updateClient.status>0 ? 
                                    <Alert variant='info'> {updateClient.status==1 ? <Spinner animation="grow" size='sm'/>:null}  {updateClient.message}</Alert>                                
                                :null}
                                </Card.Body>
                            </Card>
                            <Card className='w-100 mt-4 mb-4' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Title>SAC </Card.Title>
                                    <Alert variant='info'>proximanente</Alert> 
                                    {/*
                                    <Row md={3}>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title text-center fs-4"><Icon.Whatsapp/></h6>
                                                    <p className="card-text text-center">WhatsApp</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title text-center fs-4"><Icon.EnvelopeAt/></h6>
                                                    <p className="card-text text-center">Correo</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title text-center fs-4"><Icon.ChatLeftText/></h6>
                                                    <p className="card-text text-center">SMS</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title text-center fs-4"><Icon.Voicemail/></h6>
                                                    <p className="card-text text-center">Audios</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title text-center fs-4"><Icon.ClockHistory/></h6>
                                                    <p className="card-text text-center">Historial</p>
                                                </div>
                                            </div> 
                                        </Col>
                                    </Row>
                                    */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='w-100' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Title>Causas</Card.Title>
                                    <Row md={2}>
                                        {client.causas ? client.causas.map((obj, index) => (
                                                <Col key={index} sm={1}>
                                                <Link to={"/portafolio/causas/"+obj.caseId}>
                                                    <div className="card text-white bg-info mb-3" style={{maxWidth:"18rem"}}>
                                                        <div className="card-body">
                                                            <h6 className="card-title text-center">{obj.code}</h6>
                                                            <p className="card-text text-center">{obj.title}</p>
                                                            <p className="card-text text-center">{obj.juzgado}</p>
                                                            <p className="card-text text-center">{obj.lineaDeNegocio}</p>
                                                            <div hidden>{obj.caseId}</div>
                                                            <div hidden>{obj.lineaNegocioId}</div>
                                                        </div>
                                                    </div> 
                                            </Link>
                                                </Col>
                                        )): "sin causas que mostar"}
                                    </Row>
                                </Card.Body>
                                <Card.Body>
                                    <Card.Title>Estado del Cliente   </Card.Title>
                                    <Alert variant='info'>proximanente</Alert>
                                    {/*
                                    <Row md={3}>
                                        <Col sm={1}>
                                            <div className="card text-white bg-warning mb-3" style={{minHeight:"10rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title fw-normal text-center">Solicitud <br/>Kushki</h6>
                                                    <p className="card-text text-center fw-bold">sin dato</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-warning mb-3" style={{minHeight:"10rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title fw-normal text-center">Suscripcion <br/>Kushki</h6>
                                                    <p className="card-text text-center fw-bold">sin dato</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-success mb-3" style={{minHeight:"10rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title fw-normal text-center">Estado <br/>Pagos</h6>
                                                    <p className="card-text text-center fw-bold">sin dato</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-success mb-3" style={{minHeight:"10rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title fw-normal text-center">Estado <br/>MediSmart</h6>
                                                    <p className="card-text text-center fw-bold">sin dato</p>
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col sm={1}>
                                            <div className="card text-white bg-info mb-3" style={{minHeight:"10rem"}}>
                                                <div className="card-body">
                                                    <h6 className="card-title fw-normal text-center">Lineas <br/>de Negocio</h6>
                                                    <p className="card-text text-center fw-bold">sin dato</p>
                                                </div>
                                            </div> 
                                        </Col>
                                    </Row>
                                        */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Fragment>
                    :null}
                </Row>
            </Container>
        </div>
    );
}

export default AdministracionClientesDetallePage;