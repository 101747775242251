import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap"
import params from "../configs/apirest.json"
import axios from 'axios';
//import logo from '../img/defensalogo.webp';
import logo from '../img/defensalogo.webp';
import { useNavigate } from 'react-router-dom';

const baseUri = params["urlBaseUsuarios"]
const apirestUri = params["login"]
const cookies = new Cookies();
//cookies.remove("user_username", { path: "/" });
//cookies.remove("user_nombre", { path: "/" });
//cookies.remove("user_id", { path: "/" });


const LoginPage = () => {


        

    const { register, formState: { errors }, handleSubmit } = useForm();
    const [errorLogin, guardarError] = useState(false);
    const [errorGenerico, guardarErrorGenerico] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {}, [])
    

    const onSubmit = (data) => {
        guardarError(false);

        //const params = { "email": data.username, "password": data.password };
        axios.post(window.API_LOGIN+"?Email="+data.username+"&Password="+data.password/*, params*/)
            .then(res => {
                console.log("Response: " + JSON.stringify(res.data));
                if (res.data.id != 0) {
                    console.log("Login: " + res.data);
                    //alert(JSON.stringify(res.data) );
                    localStorage.setItem("login_client",JSON.stringify(res.data))
                    cookies.set("user_username", res.data.username, { path: "/" });
                    cookies.set("user_id", res.data.userId, { path: "/" });
                    
                    cookies.set("token", res.data.token, { path: "/" });
                    cookies.set("tipoUsuario", res.data.tipoUsuario, { path: "/" });
                    cookies.set("email", res.data.email, { path: "/" });
                    cookies.set("user_nombre", res.data.nombre, { path: "/" });

                    navigate("/inicio");
                }
                else {
                    console.log("No Login: " + res.data.userId);
                    cookies.remove("user_username", { path: "/" });
                    cookies.remove("user_nombre", { path: "/" });
                    cookies.remove("user_id", { path: "/" });
                    guardarError(true);
                    guardarErrorGenerico(false);
                }
            })
            .catch((error) => {
                console.log("Error: " + error);
                if (error.code == 'ERR_BAD_REQUEST') {
                    console.log("Error: " + JSON.stringify(error));
                    guardarError(true);
                    guardarErrorGenerico(false);
                }
                else {
                    guardarError(false);
                    guardarErrorGenerico(true);
                }

            });

    }
    return (
        <Fragment>
        <section className="vh-100">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col col-xl-10">
                    <div className="card" style={{borderRadius: "1rem"}}>
                    <div className="row g-0">
                        <div className="col-md-6 col-lg-5 d-none d-md-block">
                        <img src="./login.jpg" alt="login form" className="img-fluid" style={{borderRadius: "1rem 0 0 1rem"}} />
                        </div>
                        <div className="col-md-6 col-lg-7 d-flex align-items-center">
                        <div className="card-body p-4 p-lg-5 text-black">                        

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex align-items-center mb-3 pb-1">
                                    <i className="fas fa-cubes fa-2x me-3" style={{color: "#ff6219"}}></i>
                                    <span className="w-100 mb-0 d-flex justify-content-center"> <img className='' src="./logo_bg.png" alt="..." width={180} height={110} /></span>
                                </div>

                                <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>Inicie sesión en su cuenta</h5>

                                <FloatingLabel controlId="floatingInput" label="Usuario" className="mb-4" >
                                    <Form.Control type='text' placeholder="Usuario" {...register('username', { required: "Cuenta de usuario es obligatorio" })} />
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label="Contraseña" className="mb-3" >
                                        <Form.Control type='password' placeholder="Contraseña" {...register('password', {     required: "Contraseña es obligatorio" })} />
                                    </FloatingLabel>
                                    {errors.username && <span className="text-danger text-small d-block mb-2"> {errors.username.message} </span> }
                                    {errors.password && <span className="text-danger text-small d-block mb-2"> {errors.password.message} </span> }
                                    <br />
                                    {errorLogin && <span className="text-danger text-small d-block mb-2"> Contraseña Incorrecta </span> }
                                    {errorGenerico && <span className="text-danger text-small d-block mb-2"> Error al conectar el servicio </span>
                                    }
                                    <div> <Button type='submit' className='btn btn-primary btn-lg'>Ingresar</Button> </div>
                            </Form>


                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>                     
        </Fragment>
      
    );
}
export default LoginPage;