import React, { Fragment, useState, useEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Form, Modal, Col, Row, InputGroup, Button, Collapse, Alert, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import tableCustomStyles from '../components/TableStyle'
import { NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import NuevoCliente from '../components/Clientes/NuevoCliente'


import { useDispatch, useSelector } from 'react-redux'
import { getAdminClientsAction, getAdminClientsAdvancedAction } from '../Redux/CausasDucks'

const AdministracionClientesPage = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { register: register, setValue, handleSubmit: handleSubmit } = useForm()
    const { register: register2, handleSubmit: handleSubmit2 } = useForm()
    const [buttonPopup, setButtonPopup] = useState(false);
    const [open, setOpen] = useState(false);
	
    const clientes = useSelector(store => store.causas.clientes)
    const clientesBusqueda = useSelector(store => store.causas.clientesBusqueda)
	const InfoClients = useSelector(store => store.clientes.InfoClients)

    const onSearch = (event) => dispatch(getAdminClientsAction(event))
    const onSearchAdvance = (event) => dispatch(getAdminClientsAdvancedAction(event))
    
    useEffect(() => {

     }, [clientes]);
    useEffect(() => { console.log(clientesBusqueda) }, [clientesBusqueda]);
    useEffect(() => { console.log(InfoClients) }, [InfoClients]);

    const CloseModal = () => { setButtonPopup(!buttonPopup); }

    const openClientDetail = (RUT) =>{
        const client = clientes.find(({ rut }) => rut === RUT);
        //navigate("/administracion/clientes/"+RUT, {state: {data:{ clientes, RUT, client } }})
        navigate("/administracion/clientes/"+RUT, {state: {data:{ rut:RUT } }})
    }

    const columnasClientes = [
        {
            name: "Rut", cell: (row) =><Button onClick={(e)=>openClientDetail(row.result.rut)} className='w-100' size="sm" variant='outline-info'>{row.result.rut}</Button> ,
            ignoreRowClick: false,
            button: true
        },
        { name: "Nombre", selector: "result.nameClient" },
        { name: "Teléfono", selector: "result.phone" },
        { name: "Correo", selector: "result.email" },
        { name: "SAC", selector: "result.sac" },
        { name: "Sucursal", selector: "result.sucursal" }
       ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const _Breadcrumb = (
        <div className="page-breadcrumb">
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <NavLink to="/Inicio">Administración</NavLink>
                </li>
                <li className="breadcrumb-item active">
                    <NavLink to="/administracion/clientes">Clientes</NavLink>
                </li>
            </ul>
        </div>)

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
               {_Breadcrumb}
                <section>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className='row row-cols-md-auto g-10 align-items-center'>
                                <div className="col-md-10 mb-2">
                                    <h1 className="page-heading">Clientes</h1>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSearch)} className="row row-cols-md-auto">
                                <InputGroup>
                                    <Form.Control placeholder="Buscar Rut, Nombre, Telefono, Dirección..." {...register("text")}/>
                                    <Button type="submit" variant="primary"><Icon.Search/> Buscar</Button>
                                    <Button variant="outline-secondary" onClick={() => setOpen(!open)} aria-controls="collapse-form" aria-expanded={open}><Icon.Funnel/> Avanzado</Button>
                                    <Button type="submit" variant="outline-success" onClick={()=> {setButtonPopup(true)}}><Icon.NodePlusFill/> Agregar Cliente</Button>
                                </InputGroup>
                            </form>
                            <div className="col-2 pt-2">
                                <Button className="btn btn-primary" type="button"><Icon.Download/> Descargar Excel</Button>
                            </div>

                        </div>
                        <div className="card-body">
                            <Collapse in={open} className="card-body">
                                <Form onSubmit={handleSubmit2(onSearchAdvance)} className='mb-2'>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2"> Nombre </Form.Label>
                                                <Col sm="3"> <Form.Control type="text" className="form-control" {...register2("name")}/> </Col>
                                                <Form.Label column sm="2"> Identificador (Rut) </Form.Label>
                                                <Col sm="3"> <Form.Control type="text" className="form-control" {...register2("rut")}/> </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2"> Correo </Form.Label>
                                                <Col sm="3"> <Form.Control type="text" className="form-control" {...register2("email")}/> </Col>
                                                <Form.Label column sm="2"> Telefono </Form.Label>
                                                <Col sm="3"> <Form.Control type="text" className="form-control" {...register2("telefono")}/> </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2"> Sucursal </Form.Label>
                                                <Col sm="3"> <Form.Control type="text" className="form-control" {...register2("sucursalId")}/> </Col>
                                            </Form.Group>
                                            <Row className="mb-2">
                                                <Col lg="9" sm="12">
                                                    <Button variant='primary' type="submit">Buscar</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Collapse>

                            { clientes ?
                                <DataTable className='table'
                                    columns={columnasClientes}
                                    data={clientes}
                                    customStyles={tableCustomStyles}
                                    noDataComponent="No hay clientes que cumplan el criterio de busqueda"
                                    pagination
                                    paginationComponentOptions={paginationOptions}/>
                                :
                                clientesBusqueda.status!=0 ? <Alert variant='light' className='pb-0 mb-2 fs-4'> {clientesBusqueda.status==1 ? <Spinner animation="grow" variant="primary" />:null} {clientesBusqueda.message} </Alert>: null
                            }
                        </div>
                    </div>
                </section>
            </div>

            <Modal
                show={buttonPopup}
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <NuevoCliente CloseModal={CloseModal}/>
            </Modal>

        </Fragment>
    );
}

export default AdministracionClientesPage;