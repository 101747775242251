import React from 'react';
import { Route, Routes} from 'react-router-dom';
import Error404Page from './pages/Error404Page';
import AdministracionClientesPage from './pages/AdministracionClientesPage';
import AdministracionClientesDetallePage from './pages/AdministracionClientesDetallePage';
import AdministracionDatosPage from './pages/AdministracionDatosPage';
import AdministracionUsuariosPage from './pages/AdministracionUsuariosPage';
import AdministracionCredencialesPage from './pages/AdministracionCredencialesPage';
import AdministracionCorreosPage from './pages/AdministracionCorreosPage';
import InicioPage from './pages/InicioPage';

import PortafolioPage from './pages/PortafolioPage';
import PortafolioCausasPage from './pages/PortafolioCausasPage';
import PortafolioMovimientosPage from './pages/PortafolioMovimientosPage';
import PortafolioNovedadesPage from './pages/PortafolioNovedadesPage';
import PortafolioEstadoDiarioPage from './pages/PortafolioEstadoDiarioPage';
import PortafolioEstadoMonitoreoRut from './pages/PortafolioEstadoMonitoreoRut';

import GestionPage from './pages/GestionPage';
import GestionTareasPage from './pages/GestionTareasPage';
import GestionCalendarioPage from './pages/GestionCalendarioPage';
import GestionFlujosPage from './pages/GestionFlujosPage';
import GestionPlantillasPage from './pages/GestionPlantillasPage';
import PortafolioCausasDetallePage from './pages/PortafolioCausasDetallePage';
import SACClientePage from './pages/SACClientePage';
import SACClienteDetallePage from './pages/SACClienteDetallePage';
import SACClienteDetalleMovimientosPage from './pages/SACClienteDetalleMovimientosPage';
import SACClienteDetalleTareasPage from './pages/SACClienteDetalleTareasPage';
import SACCausasPage from './pages/SACCausasPage';
import InformacionPage from './pages/InformacionPage';
import AdministracionReasignarTareas from './pages/AdministracionReasignarTareas';
import UsoDocumentosPage from './pages/UsoDocumentosPage';


import {Provider} from 'react-redux'
import generateStore from './Redux/store'

// CACHES
import CacheBuster from 'react-cache-buster';
import { Alert } from 'react-bootstrap';


const App = () => {
    const store = generateStore()
    //const isProduction = process.env.NODE_ENV === 'production';
    const alert = <Alert variant='danger'>Nueva Version</Alert>
    return (
        <CacheBuster
        currentVersion={"0.9.5.3"}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        loadingComponent={alert} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
            <Provider store={store}>
                <Routes>
                    <Route path='/inicio' element={<InicioPage />} />
                    <Route path='/portafolio' element={<PortafolioPage />} />
                    <Route path='/portafolio/causas' element={<PortafolioCausasPage />} />
                    <Route path='/portafolio/causas/:id' element={<PortafolioCausasDetallePage />} />
                    <Route path='/portafolio/movimientos' element={<PortafolioMovimientosPage />} />
                    <Route path='/portafolio/novedades' element={<PortafolioNovedadesPage />} />
                    <Route path='/portafolio/estado-diario' element={<PortafolioEstadoDiarioPage />} />
                    <Route path='/portafolio/monitoreo-rut' element={<PortafolioEstadoMonitoreoRut />} />
        

                    <Route path='/sac/cliente' element={<SACClientePage />} />
                    <Route path='/sac/cliente/:id' element={<SACClienteDetallePage />} />
                    <Route path='/sac/cliente/:id/mov:idJuicio' element={<SACClienteDetalleMovimientosPage />} />
                    <Route path='/sac/cliente/:id/tar:idJuicio' element={<SACClienteDetalleTareasPage />} />
                    <Route path='/sac/causas' element={<SACCausasPage />} />

                    <Route path='/gestion' element={<GestionPage/>} />
                    <Route path='/gestion/tareas' element={<GestionTareasPage />} />
                    <Route path='/gestion/calendario' element={<GestionCalendarioPage />} />
                    <Route path='/gestion/flujos' element={<GestionFlujosPage />} />
                    <Route path='/gestion/plantillas' element={<GestionPlantillasPage />} />

                    <Route path='/administracion/clientes/:id' element={<AdministracionClientesDetallePage/>} />
                    <Route path='/administracion/clientes' element={<AdministracionClientesPage/>} />
                    <Route path='/administracion/datos' element={<AdministracionDatosPage/>} />
                    <Route path='/administracion/usuarios' element={<AdministracionUsuariosPage/>} />
                    <Route path='/administracion/credenciales' element={<AdministracionCredencialesPage/>} />
                    <Route path='/administracion/correos' element={<AdministracionCorreosPage/>} />
                    <Route path="/administracion/uso_documentos" element={<UsoDocumentosPage/>} />
                    <Route path='/informacion' element={<InformacionPage/>} />


                    
                    {/*<Route path='/administracion/reasignar_tareas' element={<AdministracionReasignarTareas/>} />*/}
                    <Route path='*' element={<Error404Page />} />
                </Routes>
        </Provider>
     </CacheBuster>
    );
}

export default App;