import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import causasReducer from './CausasDucks'
import clientesReducer from './ClientesDucks'
import movementReducer from './MovementDucks'
import generalReducer from './GeneralDucks'
import estadioDiariosReducer from './EstadoDiarioDucks'

import userReducer from './UsersDucks'
import tareasReducer from './TareasDucks'

const rootReducer = combineReducers({
    causas: causasReducer,
    clientes: clientesReducer,
    movimientos: movementReducer,
    general: generalReducer,
    estadoDiario: estadioDiariosReducer,
    users : userReducer,
    tareas: tareasReducer
})

export default function generateStore() {
    const store = createStore( rootReducer, composeWithDevTools( applyMiddleware(thunk) ) )
    return store
}