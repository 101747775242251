import React, { Fragment, DataGrid } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';


const AdministracionDatosPage = () => {

    const [state,setstate]= useState(1); 

    const action = (index) => {
        setstate(index);
    }


    return (
        <Fragment>

            <div className="col-md-10">
                <h1 className="page-heading">Adminitración Datos</h1>

                <div className='box'>

                    <div className='custom-tabs'>

                        <div onClick={ () => action(1) } className = {`${state===1 ? 'custom-tab custom-active-tab':'custom-tab'}`}>
                            Asuntos
                        </div>

                        <div className = {`${state===2 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(2) }>
                            Materias
                        </div>

                        <div className = {`${state===3 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(3) }>
                            Submaterias
                        </div>

                        <div className = {`${state===4 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(4) }>
                            Etapas
                        </div>

                        <div className = {`${state===5 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(5) }>
                            Estados Internos
                        </div>

                        <div className = {`${state===6 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(6) }>
                            Juzgados
                        </div>

                        <div className = {`${state===7 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(7) }>
                            Roles
                        </div>

                        <div className = {`${state===8 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(8) }>
                            Monedas
                        </div>

                        <div className = {`${state===9 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(9) }>
                            Cuadernos
                        </div>

                        <div className = {`${state===10 ? 'custom-tab custom-active-tab':'custom-tab'}`} onClick={ () => action(10) }>
                            Tipo
                        </div>
                      

                    </div>

                    {/*Contenido*/}
                    <div className = 'contents'>

                        <div className={`${state===1? 'content active-content': 'content'}`}>

                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-outline">
                                        <label className="form-label" for="form6Example2">Cliente</label>
                                        <select className="form-control" name="cliente" data-live-search="true">
                                            <option selected>Seleccione ...</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>DESCRIPCION</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Convenio Salmon Austral</th>
                                        <th></th>
                                        <th>1</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className={`${state===2? 'content active-content': 'content'}`}>

                            <div className="row mb-4">
                                <div className="col-auto">                         
                                    <label className="col-form-label" for="form6Example2">Limite de Dias por Defecto</label>                                              
                                </div>
                                <div className="col-auto">
                                    <input type="text" id="form6Example2" className="form-control" />
                                </div>
                                <div className="row-auto">
                                    <Button>Guardar</Button>
                                </div>

                            </div>

                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>LIMITE DE DIAS PARA CONGELAR CAUSAS</th>
                                        <th>SUBMATERIAS</th>
                                        <th>ETAPAS</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Civil</th>
                                        <th>30 (valor por defecto del sistema)</th>
                                        <th>53</th>
                                        <th>93</th>
                                        <th>8</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                             </table>


                        </div>

                        <div className={`${state===3? 'content active-content': 'content'}`}>

                            <div className="row mb-4">
                                <div className="col-auto">
                                    <div className="form-outline">
                                        <label className="form-label" for="form6Example2">Materia</label>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <div className="form-outline">
                                        <select className="form-control" name="materia" data-live-search="true">
                                            <option selected>Seleccione ...</option>
                                            <option value="civil">Civil</option>
                                            <option value="penal">Penal</option>
                                            <option value="familia">Familia</option>
                                            <option value="laboral">Laboral</option>
                                            <option value="cobranza">Cobranza Laboral</option>
                                            <option value="corporativa">Corporativa</option>
                                            <option value="otra">Otra</option>
                                            <option value="tributaria">Tributaria/Aduanera</option>
                                            <option value="fiscalia">Fiscalía</option>
                                            <option value="tesoreria">Tesorería</option>
                                            <option value="dir">DIR</option>
                                            <option value="sac">SAC</option>
                                            <option value="eepp">EEPP</option>
                                            <option value="arbitral">Arbitral</option>
                                            <option value="corte">Corte de Apelaciones</option>
                                            <option value="policia">Policia Local</option>
                                            <option value="ejecutivo">Ejecutivo</option>
                                            <option value="apelaciones">Corte Apelaciones</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                           <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Acción de prescripción</th>
                                        <th>0</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`${state===4? 'content active-content': 'content'}`}>

                            <div className="row mb-4">
                                <div className="col-auto">                         
                                    <label className="col-form-label" for="form6Example2">Materia</label>                                              
                                </div>
                                <div className="col-auto">
                                    <div className="form-outline">
                                        <select className="form-control" name="materia2" data-live-search="true">
                                            <option selected>Seleccione ...</option>
                                            <option value="civil">Civil</option>
                                            <option value="penal">Penal</option>
                                            <option value="familia">Familia</option>
                                            <option value="laboral">Laboral</option>
                                            <option value="cobranza">Cobranza Laboral</option>
                                            <option value="corporativa">Corporativa</option>
                                            <option value="otra">Otra</option>
                                            <option value="tributaria">Tributaria/Aduanera</option>
                                            <option value="fiscalia">Fiscalía</option>
                                            <option value="tesoreria">Tesorería</option>
                                            <option value="dir">DIR</option>
                                            <option value="sac">SAC</option>
                                            <option value="eepp">EEPP</option>
                                            <option value="arbitral">Arbitral</option>
                                            <option value="corte">Corte de Apelaciones</option>
                                            <option value="policia">Policia Local</option>
                                            <option value="ejecutivo">Ejecutivo</option>
                                            <option value="apelaciones">Corte Apelaciones</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-auto">                         
                                    <label className="col-form-label" for="form6Example2">Limite de Dias de la Materia</label>                                              
                                </div>

                                <div className="col-auto">
                                    <input type="text" id="form6Example2" className="form-control" />
                                </div>

                                <div className="col-auto">
                                    <Button>Guardar</Button>
                                </div>

                            </div>


                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>LIMITE DE DIAS PARA CONGELAR CAUSAS</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>0 Exhorto</th>
                                        <th>30 (valor por defecto de la materia)</th>
                                        <th>0</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`${state===5? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>BLOQUEADO</th>
                                        <th>0</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>DADO DE BAJA</th>
                                        <th>10</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>REACTIVADO</th>
                                        <th>1</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>

                                </tbody>
                            </table>

                            <div className="col-auto">
                                    <input type="text" id="form6Example2" className="form-control" placeholder="Nombre" />
                            </div>

                        </div>

                        <div className={`${state===6? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>URL</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Corte Suprema</th>
                                        <th>http://www.pjud.cl/corte-suprema</th>
                                        <th>1</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`${state===7? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Socio</th>
                                        <th>3</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`${state===8? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>CODIGO</th>
                                        <th>SIMBOLO</th>
                                        <th>TASA DE CAMBIO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>UF</th>
                                        <th>CLF</th>
                                        <th>UF</th>
                                        <th>34788.6484</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="row mb-4">
                                <div className="col-auto">
                                    <input type="text" id="form6Example2" className="form-control" placeholder="Nombre" />
                                </div>
                                <div className="col-auto">
                                    <input type="text" id="form6Example3" className="form-control" placeholder="Codigo" />
                                </div>
                                <div className="col-auto">
                                    <input type="text" id="form6Example3" className="form-control" placeholder="Simbolo" />
                                </div>
                                <div className="col-auto">
                                    <input type="text" id="form6Example3" className="form-control" placeholder="Tasa de Cambio" />
                                </div>
                            </div>

                        </div>

                        <div className={`${state===9? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>2 Apremio Ejecutivo Obligación de Dar</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`${state===10? 'content active-content': 'content'}`}>
                            <table className='table table-striped table-hover card-text'> 
                                <thead>
                                    <tr>
                                        <th>NOMBRE</th>
                                        <th>CAUSAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Abandono de Procedimiento</th>
                                        <th>0</th>
                                        <th>
                                            <Button>Eliminar</Button>
                                        </th>
                                        <th>
                                            <Button>Editar</Button>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>

                </div>

            </div>
         
        </Fragment>
        );
}
    
export default AdministracionDatosPage;