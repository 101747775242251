import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Calendar from '@toast-ui/react-calendar'
import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import moment from 'moment'
import { Tab, Tabs, Spinner, Button, InputGroup, Form } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons'
import Select from 'react-select'
import dateFormat, { masks } from 'dateformat'

import { useDispatch, useSelector } from 'react-redux'
import { getListTarea, getListUsers } from '../Redux/CausasDucks'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { useForm } from 'react-hook-form'
import { JsonToExcel } from "react-json-to-excel"

const InicioPage = () => {
  const cookies = new Cookies()
  const { register, handleSubmit } = useForm()
  const { register: registerFilter, reset, control: controlFilter, handleSubmit: handleSubmitFilter } = useForm()
  const { register: registerFilterSearch, reset: resetB, control: controlFilterSearch, handleSubmit: handleSubmitFilterSearch } = useForm()

  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [searchUsers, setSearchUsers] = useState(true)
  const [tasks, setTasks] = useState([])
  const [tareasUsuario, setTareasUsuario] = useState([])
  const [tareasXls, setTareasXls] = useState([])
  const calendarRef = React.createRef()
  const [spinnerShow, setSpinnerShow] = useState(true)

  const [tareasUsuarioFiltro, setTareasUsuarioFiltro] = useState([])
  const [idUsuario, setIdUsuario] = useState([])
  const [tareasXlsFiltro, setTareasXlsFiltro] = useState([])

  const navigate = useNavigate()

  const usuarios = useSelector((store) => store.causas.usuarios)
  const tareas_usuario = useSelector((store) => store.causas.tareas_usuario)
  const tareas_usuario_filter = useSelector((store) => store.causas.ListTasksUserFilter)
  const LoaderListTasksUser = useSelector((store) => store.causas.LoaderListTasksUser)
  const LoaderListTasksUserFilter = useSelector((store) => store.causas.LoaderListTasksUserFilter)

  // Se preprara la tarea para mostrar en el Modo calendario
  useEffect(() => {
    var date = new Date()
    let auxCalendar = []
    let auxList = []
    tareas_usuario.map((task, index) => {
      auxCalendar.push({
        calendarId: '0',
        category: 'time',
        title: task.titulo,
        id: task.id,
        start: dateFormat(task.fechaLimite, 'yyyy-mm-dd') + 'T09:00:00',
        end: dateFormat(task.fechaLimite, 'yyyy-mm-dd') + 'T21:00:00',
      })

      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
    })
    setTasks(auxCalendar)
    setSpinnerShow(false)
    setTareasUsuario(auxList)

  }, [tareas_usuario])

//  useEffect(()=> {alert(LoaderListTasksUser )},[LoaderListTasksUser ])

  useEffect(() => {
    console.log(tareas_usuario_filter)
    let auxList = []
    let auxExcel = []
      tareas_usuario_filter.list.map((task, index) => {
        auxList.push(
          <tr>
            <td>{(index + 1)}</td>
            <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
            <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
            <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
            <td scope="col">{task.rol}</td>
            <td scope="col">{task.titulo}</td>
            <td scope="col">{task.tribunal}</td>
          </tr>
        )
        auxExcel.push(task)
      })
    setTareasUsuarioFiltro(auxList)
    setTareasXlsFiltro(auxExcel)

  }, [tareas_usuario_filter])

  useEffect(() => {
    const idUsuario = cookies.get('user_id')
    dispatch(getListTarea("principal",idUsuario, { statusId: 2 }))
  }, [])

  useEffect(() => {
    dispatch(getListUsers())
  }, [])

  useEffect(() => {
    let aux = usuarios.map((item) => {
      if (item.active)
        return { value: item.id, label: item.nombre }
      else
        return null
    })
    for (var propName in aux)
      if (aux[propName] === null) delete aux[propName]

    setUsers(aux)
    setSearchUsers(false)
  }, [usuarios])

  const onSearch = (event) => {
    setSpinnerShow(true)
    dispatch(getListTarea("principal",event.value, { statusId: 2 }))
    setIdUsuario(event.value)
  }

  const ShowEvent = function (e) {
    const idTask = e.event.id
    const task = tareas_usuario.find(({ id }) => id === idTask)
    //navigate("/portafolio/causas/"+task.caseId)
    navigate('/portafolio/causas/' + task.caseId, {
      state: { data: { id: idTask, type: 'task' } },
    })
  }
  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
  }
  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
  }

  const onFechaRangoSubmit = (e) => {
    var desde = new Date(e.desde)
    var hasta = new Date(e.hasta)
    hasta = hasta.setDate(hasta.getDate() + 1);
    let auxList = []
    let auxExcel = []

    tareas_usuario.map((task, index) => {
      var fechaLimite = new Date(task.fechaLimite)
      if (fechaLimite >= desde && fechaLimite <= hasta) {
        auxList.push(
          <tr>
            <td>{(index + 1)}</td>
            <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
            <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
            <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
            <td scope="col">{task.rol}</td>
            <td scope="col">{task.titulo}</td>
            <td scope="col">{task.tribunal}</td>
          </tr>
        )
        auxExcel.push(task)
      }
    })
    setTareasXls(auxExcel)
    setTareasUsuario(auxList)
  }
  const limpiarSubmit = (e) => {
    let auxList = []
    let auxExcel = []
    tareas_usuario.map((task, index) => {
      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
      auxExcel.push(task)
    })
    setTareasXls(auxExcel)
    setTareasUsuario(auxList)
  }

  const onSearchFechaRangoSubmitFilter = (e) => {
    if (e.taskStatus == "0") return;
    dispatch(getListTarea("filtro", idUsuario, { statusId: e.taskStatus }))
  }
  const onFechaRangoSubmitFilter = (e) => { 
    var desde = new Date(e.desde)
    var hasta = new Date(e.hasta)
    hasta = hasta.setDate(hasta.getDate() + 1);
    let auxList = []
    tareas_usuario_filter.list.map((task, index) => {
      var fechaLimite = new Date(task.fechaLimite)
      if (fechaLimite >= desde && fechaLimite <= hasta) {
        auxList.push(
          <tr>
            <td>{(index + 1)}</td>
            <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
            <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
            <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
            <td scope="col">{task.rol}</td>
            <td scope="col">{task.titulo}</td>
            <td scope="col">{task.tribunal}</td>
          </tr>
        )
      }
    })
    setTareasUsuarioFiltro(auxList)

  }

  const limpiarSubmitFilter = (e) => { 

    let auxList = []
    let auxExcel = []
    tareas_usuario_filter.list.map((task, index) => {
      auxExcel.push(task)
      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
    })
    setTareasUsuarioFiltro(auxList)
    setTareasXlsFiltro(auxExcel)
  }
  return (
    <Fragment>
      <div className="container-fluid px-lg-1 px-xl-3">
        <section>
          <div className="card mb-4">
            <div className="card-header">
              <h4>Calendario De Tareas</h4>
            </div>
            <div className="card-body text-muted">
              Seleccione usuario para mostrar sus tareas
              <Select
                onChange={(e) => onSearch(e)}
                options={users}
                className="basic-single mb-4"
                classNamePrefix="select"
                //defaultValue={colourOptions[0]}
                isDisabled={false}
                isLoading={searchUsers}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
              />
              {LoaderListTasksUser? <Fragment>Cargando Tareas <Spinner animation="grow" size="sm" /></Fragment> :null}
              {spinnerShow ? (
                <div>
                  Cargando Calendario <Spinner animation="grow" size="sm" />
                </div>
              ) : (
                <Tabs
                  defaultActiveKey="Calendario"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Calendario" title="Modo Calendario">

                    <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickPrevButton}><Icon.ChevronCompactLeft /> Mes anterior</Button>
                    <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickTodayButton}><Icon.Calendar3 /> Mes Actual</Button>
                    <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickNextButton}>Mes siguiente <Icon.ChevronCompactRight /> </Button>

                    <Calendar
                      ref={calendarRef}
                      onClickEvent={ShowEvent}
                      height="600px"
                      view="month"
                      calendars={[{ id: '0', name: 'Tareas', bgColor: '#4650dd', borderColor: '#4650dd', color: '#fff', },]}
                      disableDblClick={true}
                      disableClick={false}
                      isReadOnly={true}
                      month={{ dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado',], startDayOfWeek: 1, }}
                      events={tasks}
                      theme={{ 'month.weekend.backgroundColor': 'red' }}
                      week={{ showTimezoneCollapseButton: true, timezonesCollapsed: true, }}
                      useCreationPopup={false}
                      useDetailPopup={false}
                    />
                  </Tab>
                  <Tab eventKey="Listado" title="Modo Lista">

                    <div className="col-md-12 mb-9 mb-3 mt-4">
                      <Form onSubmit={handleSubmit(onFechaRangoSubmit)} className="row row-cols-md-auto">
                        <InputGroup>
                          <span className="input-group-text">Desde</span>
                          <Form.Control type='date' {...register("desde")} required />
                          <span className="input-group-text">Hasta</span>
                          <Form.Control type='date' {...register("hasta")} required />
                          <Button type="submit" variant="primary"><Icon.Filter /> Filtrar</Button>
                          <Button type="button" onClick={limpiarSubmit} variant="primary"><Icon.Trash /> Limpiar</Button>
                          <JsonToExcel
                            btnClassName="btn btn-primary p-2 rounded-0 rounded-right"
                            title={"Descargar"}
                            data={tareasXls}
                            fileName="ListaDeTareas"
                          />
                        </InputGroup>
                      </Form>
                    </div>


                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col"></th>
                          <th scope="col">F.Limite</th>
                          <th scope="col">Descripción</th>
                          <th scope="col">Causa</th>
                          <th scope="col">Titulo</th>
                          <th scope="col">Tribunal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tareasUsuario}
                      </tbody>
                    </table>
                  </Tab>
                  <Tab eventKey="Filtro" title="Filtro Tareas">
                    <div className="col-md-12 mb-9 mb-3 mt-4">
                      <Form onSubmit={handleSubmitFilterSearch(onSearchFechaRangoSubmitFilter)} className="row row-cols-md-auto">
                        <InputGroup>
                          <Form.Select aria-label="Default select example" {...registerFilterSearch("taskStatus")}>
                            <option value="0">Select</option>
                            <option value="1">Aprobado</option>
                            <option value="2">En Progreso</option>
                            <option value="4">Rechazado</option>
                          </Form.Select>
                          <Button type="submit" variant="primary"><Icon.Search /> Buscar</Button>
                        </InputGroup>
                      </Form>
                    </div>

                    {LoaderListTasksUserFilter? <Fragment>Cargando Tareas Filtradas <Spinner animation="grow" size="sm" /></Fragment> :null}


                    <div className="col-md-12 mb-9 mb-3 mt-4">
                      <Form onSubmit={handleSubmitFilter(onFechaRangoSubmitFilter)} className="row row-cols-md-auto">
                        <InputGroup>
                          <span className="input-group-text">Desde</span>
                          <Form.Control type='date' {...registerFilter("desde")} required />
                          <span className="input-group-text">Hasta</span>
                          <Form.Control type='date' {...registerFilter("hasta")} required />
                          <Button type="submit" variant="primary"><Icon.Filter /> Filtrar</Button>
                          <Button type="button" onClick={limpiarSubmitFilter} variant="primary"><Icon.Trash /> Limpiar</Button>
                          {tareasXlsFiltro.length > 0 ? <JsonToExcel
                            btnClassName="btn btn-primary p-2 rounded-0 rounded-right"
                            title={"Descargar"}
                            data={tareasXlsFiltro}
                            fileName="ListaDeTareas"
                          /> : <Button className="btn btn-secondary p-2 rounded-0 rounded-right" disabled> Descargar</Button>}
                        </InputGroup>
                      </Form>
                    </div>

                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col"></th>
                          <th scope="col">F.Limite</th>
                          <th scope="col">Descripción</th>
                          <th scope="col">Causa</th>
                          <th scope="col">Titulo</th>
                          <th scope="col">Tribunal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tareasUsuarioFiltro}
                      </tbody>
                    </table>
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default InicioPage


/*
import React, { Fragment, useEffect, useState } from 'react'
import Calendar from '@toast-ui/react-calendar'
import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import { Tab, Tabs, Spinner, Button, InputGroup, Form } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons'
import Select from 'react-select'
import dateFormat from 'dateformat'
import { useDispatch, useSelector } from 'react-redux'


import { GetListTasksUser } from '../Redux/TareasDucks'
//import { GetListUsers } from '../Redux/UsersDucks'

import {getListUsers } from '../Redux/CausasDucks'

import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useForm } from 'react-hook-form'
import { JsonToExcel } from "react-json-to-excel"




const InicioPage = () => {
  const cookies = new Cookies()
  const { register, handleSubmit } = useForm()
  const { register: registerFilter, reset, control: controlFilter, handleSubmit: handleSubmitFilter } = useForm()
  const { register: registerFilterSearch, reset: resetB, control: controlFilterSearch, handleSubmit: handleSubmitFilterSearch } = useForm()


  const dispatch = useDispatch()
  const [users, setUsers] = useState([])
  const [searchUsers, setSearchUsers] = useState(true)
  const [tasks, setTasks] = useState([])
  const [tareasUsuario, setTareasUsuario] = useState([])
  const [tareasUsuarioFiltro, setTareasUsuarioFiltro] = useState([])
  const [idUsuario, setIdUsuario] = useState([])

  const [tareasXls, setTareasXls] = useState([])
  const [tareasXlsFiltro, setTareasXlsFiltro] = useState([])
  

  const calendarRef = React.createRef();

  const [spinnerShow, setSpinnerShow] = useState(true)

  const navigate = useNavigate()

  const usuarios = useSelector((store) => store.causas.listUser)
  const tareas_usuario = useSelector((store) => store.tareas.ListTasksUser)
  const tareas_usuario_filter = useSelector((store) => store.tareas.ListTasksUserFilter)

  // Se preprara la tarea para mostrar en el Modo calendario
  useEffect(() => {
    var date = new Date()
    let auxCalendar = []
    let auxList = []
    let auxExcel = []
    tareas_usuario.list.map((task, index) => {
      auxCalendar.push({
        calendarId: '0',
        category: 'time',
        title: task.titulo,
        id: task.id,
        start: dateFormat(task.fechaLimite, 'yyyy-mm-dd') + 'T09:00:00',
        end: dateFormat(task.fechaLimite, 'yyyy-mm-dd') + 'T21:00:00',
      })

      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
      auxExcel.push(task)
    })
    setTasks(auxCalendar)
    setSpinnerShow(false)
    setTareasUsuario(auxList)
    setTareasXls(auxExcel)


  }, [tareas_usuario])

  useEffect(() => {
    let auxList = []
    let auxExcel = []
    tareas_usuario_filter.list.map((task, index) => {
      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
      auxExcel.push(task)
    })
    setTareasUsuarioFiltro(auxList)
    setTareasXlsFiltro(auxExcel)

  }, [tareas_usuario_filter])

  useEffect(() => {
    dispatch(GetListTasksUser("principal",cookies.get('userId'), { statusId: 2 }))
    setIdUsuario(cookies.get('userId'))
  }, [])

  useEffect(() => {
    dispatch(getListUsers())
  }, [])

  useEffect(() => {
    console.log(usuarios)
    let aux = usuarios.list.map((item) => {
      if (item.active)
        return { value: item.id, label: item.nombre }
      else
        return null
    })
    for (var propName in aux)
      if (aux[propName] === null) delete aux[propName]

    setUsers(aux)
    setSearchUsers(false)
  }, [usuarios])

  const onSearch = (event) => {
    setSpinnerShow(true)
    dispatch(GetListTasksUser("principal",event.value, { statusId: 2 }))
    setIdUsuario(event.value)
  }

  const ShowEvent = function (e) {
    const idTask = e.event.id
    const task = tareas_usuario.list.find(({ id }) => id === idTask)
    //navigate("/portafolio/causas/"+task.caseId)
    navigate('/portafolio/causas/' + task.caseId, {
      state: { data: { id: idTask, type: 'task' } },
    })
  }
  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
  }
  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
  }

  const onFechaRangoSubmit = (e) => {
    var desde = new Date(e.desde)
    var hasta = new Date(e.hasta)
    hasta = hasta.setDate(hasta.getDate() + 1);
    let auxList = []
    let auxExcel = []

    tareas_usuario.list.map((task, index) => {
      var fechaLimite = new Date(task.fechaLimite)
      if (fechaLimite >= desde && fechaLimite <= hasta) {
        auxList.push(
          <tr>
            <td>{(index + 1)}</td>
            <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
            <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
            <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
            <td scope="col">{task.rol}</td>
            <td scope="col">{task.titulo}</td>
            <td scope="col">{task.tribunal}</td>
          </tr>
        )
        auxExcel.push(task)
      }
    })
    setTareasXls(auxExcel)
    setTareasUsuario(auxList)
  }

  const limpiarSubmit = (e) => {
    let auxList = []
    let auxExcel = []
    tareas_usuario.list.map((task, index) => {
      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
      auxExcel.push(task)
    })
    setTareasXls(auxExcel)
    setTareasUsuario(auxList)
  }

  const onSearchFechaRangoSubmitFilter = (e) => {
    if (e.taskStatus == "0") return;
    dispatch(GetListTasksUser("filtro", idUsuario, { statusId: e.taskStatus }))
  }

  const onFechaRangoSubmitFilter = (e) => { 
    var desde = new Date(e.desde)
    var hasta = new Date(e.hasta)
    hasta = hasta.setDate(hasta.getDate() + 1);
    let auxList = []
    tareas_usuario_filter.list.map((task, index) => {
      var fechaLimite = new Date(task.fechaLimite)
      if (fechaLimite >= desde && fechaLimite <= hasta) {
        auxList.push(
          <tr>
            <td>{(index + 1)}</td>
            <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
            <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
            <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
            <td scope="col">{task.rol}</td>
            <td scope="col">{task.titulo}</td>
            <td scope="col">{task.tribunal}</td>
          </tr>
        )
      }
    })
    setTareasUsuarioFiltro(auxList)

  }
  const limpiarSubmitFilter = (e) => { 

    let auxList = []
    let auxExcel = []
    tareas_usuario_filter.list.map((task, index) => {
      auxExcel.push(task)
      auxList.push(
        <tr>
          <td>{(index + 1)}</td>
          <td scope="col"><Button onClick={() => ShowEvent({ event: { id: task.id } })}>Abrir</Button></td>
          <td scope="col">{task.fechaLimite ? task.fechaLimite.split("T")[0] : "sin fecha"}</td>
          <td scope="col">{task.descripcion?.split('<br/>').map((it, i) => <div className='text-justify' key={'x' + i}>{it}</div>)}</td>
          <td scope="col">{task.rol}</td>
          <td scope="col">{task.titulo}</td>
          <td scope="col">{task.tribunal}</td>
        </tr>
      )
    })
    setTareasUsuarioFiltro(auxList)
    setTareasXlsFiltro(auxExcel)
  }

  return (
    <Fragment>
      <div className="container-fluid px-lg-1 px-xl-3">
        <section>
          <div className="card mb-4">
            <div className="card-header">
              <h4>Calendario De Tareas</h4>
            </div>
            <div className="card-body text-muted">
              Seleccione usuario para mostrar sus tareas
              <Select
                onChange={(e) => onSearch(e)}
                options={users}
                className="basic-single mb-4"
                classNamePrefix="select"
                //defaultValue={colourOptions[0]}
                isDisabled={false}
                isLoading={searchUsers}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
              />
              {spinnerShow ? (
                <div>
                  Cargando Calendario <Spinner animation="grow" size="sm" />
                </div>
              ) : (null)}

              <Tabs
                defaultActiveKey="Calendario"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Calendario" title="Modo Calendario">

                  <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickPrevButton}><Icon.ChevronCompactLeft /> Mes anterior</Button>
                  <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickTodayButton}><Icon.Calendar3 /> Mes Actual</Button>
                  <Button className='me-1 mb-2' variant="outline-info" size="sm" onClick={handleClickNextButton}>Mes siguiente <Icon.ChevronCompactRight /> </Button>

                  <Calendar
                    ref={calendarRef}
                    onClickEvent={ShowEvent}
                    height="600px"
                    view="month"
                    calendars={[{ id: '0', name: 'Tareas', bgColor: '#4650dd', borderColor: '#4650dd', color: '#fff', },]}
                    disableDblClick={true}
                    disableClick={false}
                    isReadOnly={true}
                    month={{ dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado',], startDayOfWeek: 1, }}
                    events={tasks}
                    theme={{ 'month.weekend.backgroundColor': 'red' }}
                    week={{ showTimezoneCollapseButton: true, timezonesCollapsed: true, }}
                    useCreationPopup={false}
                    useDetailPopup={false}
                  />
                </Tab>
                <Tab eventKey="Listado" title="Modo Lista">

                  <div className="col-md-12 mb-9 mb-3 mt-4">
                    <Form onSubmit={handleSubmit(onFechaRangoSubmit)} className="row row-cols-md-auto">
                      <InputGroup>
                        <span className="input-group-text">Desde</span>
                        <Form.Control type='date' {...register("desde")} required />
                        <span className="input-group-text">Hasta</span>
                        <Form.Control type='date' {...register("hasta")} required />
                        <Button type="submit" variant="primary"><Icon.Filter /> Filtrar</Button>
                        <Button type="button" onClick={limpiarSubmit} variant="primary"><Icon.Trash /> Limpiar</Button>
                        {tareasXls.length>0 ? <JsonToExcel
                          btnClassName="btn btn-primary p-2 rounded-0 rounded-right"
                          title={"Descargar"}
                          data={tareasXls}
                          fileName="ListaDeTareas"
                        /> : <Button className="btn btn-secondary p-2 rounded-0 rounded-right" disabled> Descargar</Button>}
                      </InputGroup>
                    </Form>
                  </div>


                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col"></th>
                        <th scope="col">F.Limite</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Causa</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">Tribunal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tareasUsuario}
                    </tbody>
                  </table>
                </Tab>
                <Tab eventKey="Filtro" title="Filtro Tareas">
                  <div className="col-md-12 mb-9 mb-3 mt-4">
                    <Form onSubmit={handleSubmitFilterSearch(onSearchFechaRangoSubmitFilter)} className="row row-cols-md-auto">
                      <InputGroup>
                        <Form.Select aria-label="Default select example" {...registerFilterSearch("taskStatus")}>
                          <option value="0">Select</option>
                          <option value="1">Aprobado</option>
                          <option value="2">En Progreso</option>
                          <option value="4">Rechazado</option>
                        </Form.Select>
                        <Button type="submit" variant="primary"><Icon.Search /> Buscar</Button>
                      </InputGroup>
                    </Form>
                  </div>


                  <div className="col-md-12 mb-9 mb-3 mt-4">
                    <Form onSubmit={handleSubmitFilter(onFechaRangoSubmitFilter)} className="row row-cols-md-auto">
                      <InputGroup>
                        <span className="input-group-text">Desde</span>
                        <Form.Control type='date' {...registerFilter("desde")} required />
                        <span className="input-group-text">Hasta</span>
                        <Form.Control type='date' {...registerFilter("hasta")} required />
                        <Button type="submit" variant="primary"><Icon.Filter /> Filtrar</Button>
                        <Button type="button" onClick={limpiarSubmitFilter} variant="primary"><Icon.Trash /> Limpiar</Button>
                          {tareasXlsFiltro.length>0 ? <JsonToExcel
                          btnClassName="btn btn-primary p-2 rounded-0 rounded-right"
                          title={"Descargar"}
                          data={tareasXlsFiltro}
                          fileName="ListaDeTareas"
                        /> : <Button className="btn btn-secondary p-2 rounded-0 rounded-right" disabled> Descargar</Button>}
                      </InputGroup>
                    </Form>
                  </div>

                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col"></th>
                        <th scope="col">F.Limite</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Causa</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">Tribunal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tareasUsuarioFiltro}
                    </tbody>
                  </table>
                </Tab>


              </Tabs>

            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default InicioPage
*/