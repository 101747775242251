import axios from 'axios'
import Cookies from 'universal-cookie'
// constantes

const data = {
    InfoClients: [],
    NewClient: []
}

const GET_INFO_CLIENT_SUCCESS = 'GET_INFO_CLIENT_SUCCESS'
const SET_NEW_CLIENT_SUCCESS = 'SET_NEW_CLIENT_SUCCESS'

// reducer
export default function clientesReducer(state = data, action){
    switch(action.type){
        case GET_INFO_CLIENT_SUCCESS:
            return {...state, InfoClients: action.payload}
        case SET_NEW_CLIENT_SUCCESS:
            return {...state, NewClient: action.payload}            
        default:
            return state
    }
}


//  para la causa
export const SendNewClient = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA) 
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies()
    DATA.creatorId = cookies.get('user_id')


    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_NEW_CLIENT,DATA)
        //console.log(RES)
        if(RES.status === 200 ){
            dispatch({
                type: SET_NEW_CLIENT_SUCCESS,
                payload: RES.data
            })
        }else{
            console.log(RES)
        }

    } catch (error) {
        console.log(error)
    }
}


// Parametros para la causa
export const GetInfoClient = () => async (dispatch, getState) => {
    
    try { // nombre rut telefono direccion
        const RES = await axios.get(window.API_INFO_CLIENT)
        //console.log(RES)
        if(RES.status === 200 ){

            let branchOffices = []
            let countries = []
            let sacUsers = []
            let regions= []
            RES.data.branchOffices.map(item => { 
                branchOffices.push({ value: item.id, label: item.description, code:item.abreviatura })
            })
            RES.data.countries.map(item => { 
                countries.push({ value: item.id, label: item.description})
            })
            RES.data.sacUsers.map(item => { 
                sacUsers.push({ value: item.id, label: item.email})
            })
            RES.data.regions.map(item => { 
                regions.push({ value: item.id, label: item.name})
            })
            

            RES.data.branchOffices = branchOffices
            RES.data.countries = countries
            RES.data.sacUsers = sacUsers
            RES.data.regions = regions
            
            dispatch({
                type: GET_INFO_CLIENT_SUCCESS,
                payload: RES.data
            })
        }else{
            console.log(RES)
        }

    } catch (error) {
        console.log(error)
    }
}