import { NavLink, useLocation, useParams, useNavigate  } from 'react-router-dom';
import React, { Fragment, useState,useEffect } from 'react';

import * as Icon from 'react-bootstrap-icons'
import TareasCausa from "../components/TareasCausa"
import MovimientosCausa from "../components/MovimientosCausa"
import DetalleCausa from "../components/DetalleCausa"

import { Button, Card, Col, Container, Row, Badge, Form, Modal, Toast,
Nav, Breadcrumb, Alert, Spinner } from "react-bootstrap"
import { getCaseDetailAction,getListUsers, putCaseUpdate } from '../Redux/CausasDucks'
import { getInternalStateAction } from '../Redux/GeneralDucks';
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import Cookies from 'universal-cookie'

const PortafolioCausasDetallePage = () => {
    
    const cookies = new Cookies();
    const navigate = useNavigate()
    const [showCase,setShowCase]= useState(false)
    const [showInfoBaja,setshowInfoBaja]= useState(false)
    
    const [showTab,setShowTab]= useState(1)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showModalUser, setshowModalUser] = useState(false)
    const [showModalInternalState, setShowModalInternalState] = useState(false)
    const [usersGeneral,setUsersGeneral]= useState([])
    const [estadoInternoGeneral,setEstadoInternoGeneral]= useState([])
    const [estadoInterno, setEstadoInterno] = useState(null);
    const dispatch = useDispatch()

    const { register: registerCase, handleSubmit: handleSubmitUpdateUsers, control : controlUpdateUsers } = useForm()
    const { register: registerCaseb, handleSubmit: handleSubmitUpdateInternalState, control : controlUpdateInternalState } = useForm()
    
    let { id } = useParams();

	const detalleCausa = useSelector(store => store.causas.detalleCausa)

	const usuarios = useSelector(store => store.causas.usuarios)
	const updateCase = useSelector(store => store.causas.updateCase)
    const internalStateList = useSelector(store => store.general.internalState)

    const actionShowTab = (index) => { setShowTab(index); }

    const addUsersCase=()=> { setshowModalUser(true) }
    const handleClose = () =>{setshowModalUser(false)}
    const openInternalState=(Istate)=>{
        const userId = parseInt(cookies.get('user_id'))
        const valids = window.validUsers
        if(valids.includes(userId)) // es un usuario permitido
            setShowModalInternalState(true)
        else{ //no esta permitido
            alert("CAUSA BLOQUEADA, COMUNIQUESE CON EL AREA DE BAJAS")
        } 
    }
    const handleCloseInternalState = () =>{setShowModalInternalState(false)}

    const breadcrumb = <Breadcrumb>
                <Breadcrumb.Item active>Portafolio</Breadcrumb.Item>
                <Breadcrumb.Item active>Causas</Breadcrumb.Item>
                <Breadcrumb.Item active>{id}</Breadcrumb.Item>
            </Breadcrumb>
    
    const location = useLocation()

    const calendario = location.state?.data ?location.state?.data: null;

    useEffect(() => { 
        if(calendario)
            if(calendario.type && calendario.type=="task")
                actionShowTab(3)
    }, [calendario]);

    useEffect(() => {  dispatch(getCaseDetailAction(id)) }, [id]);

    useEffect(()=>{ if(updateCase.status>0) {setshowModalUser(false); setShowModalInternalState(false)} },[updateCase])
    
    
    useEffect(() => {  
        if(usuarios.length>0){
            let gen = [] 
            usuarios.map( item => { 
            if(item.active) gen.push({ value: item.id , label : item.nombre })
            })
            setUsersGeneral(gen)
        }else{
            dispatch(getListUsers())
        }

    }, [usuarios]);
    // const [estadoInternoGeneral,setEstadoInternoGeneral]= useState([])

    useEffect(() => {  
        if(internalStateList.length>0){
            let gen = [] 
            internalStateList.map( item => { 
                gen.push({ value: item.id , label : item.description })
            })
            setEstadoInternoGeneral(gen)
        }
    }, [internalStateList]);

    const openClientDetail = (RUT) =>{
        navigate("/administracion/clientes/"+RUT, {state: {data:{ rut:RUT } }})
    }

    const onSubmitTaskUsersUpdate = (e) =>{ 
        e.caseId = detalleCausa.case.id
        dispatch(putCaseUpdate(e))
    }
    const onSubmitInternalState = (e) =>{ 
        e.caseId = detalleCausa.case.id
        dispatch(putCaseUpdate(e))
        //setShowModalInternalState(false)
    }
    
    
    useEffect(()=>{
        const userId = parseInt(cookies.get('user_id'))
        const valids = window.validUsers
        if(detalleCausa!=null)
            if(detalleCausa.case.internalState!=null  ){
                const Istate = detalleCausa.case.internalState;
                setEstadoInterno(
                    <Row className='fw-bolder mb-2'><Col> <Button onClick={(e)=>openInternalState(Istate)} className='w-100' size="sm" variant={Istate.blockManager?'outline-danger':'outline-info'}>{Istate.description}</Button> </Col></Row>
                )
                if(valids.includes(userId)){
                    setShowCase(true)
                    setshowInfoBaja(Istate.blockManager)
                }else{
                    setShowCase(!Istate.blockManager)
                    setshowInfoBaja(Istate.blockManager)
                }
            }else{
                setEstadoInterno(
                    <Row className='fw-bolder mb-2'><Col> <Button onClick={(e)=>openInternalState(null)} className='w-100' size="sm" variant='outline-warning'>Estado no definido</Button> </Col></Row>
                )
                setShowCase(true);
            }
    },[detalleCausa])

    
    useEffect(()=>{
        dispatch(getInternalStateAction())
    },[])


    return (
        <Fragment>
            {breadcrumb}
            { showInfoBaja ? <Alert key={"danger"} variant={"danger"}> Causa Bloqueada comuniquese con el AREA DE BAJAS</Alert> : null }

            {detalleCausa ?
            <Container fluid>
                <Row className="justify-content-md-start">
                    <Col xs lg="8">
                        {showCase ? <>
                            <Nav justify variant="tabs" className='mb-4'>
                                <Nav.Item> <Nav.Link className={`${showTab===1? 'active fs-5 fw-bolder': ''}`} onClick={() => actionShowTab(1)}>Causa</Nav.Link> </Nav.Item>
                                <Nav.Item> <Nav.Link className={`${showTab===2? 'active fs-5 fw-bolder': ''}`} onClick={() => actionShowTab(2)}>Movimientos</Nav.Link> </Nav.Item>
                                <Nav.Item> <Nav.Link className={`${showTab===3? 'active fs-5 fw-bolder': ''}`} onClick={() => actionShowTab(3)}>Tareas</Nav.Link> </Nav.Item>
                            </Nav>
                            
                            { showTab===1 ? <DetalleCausa causa={detalleCausa}/> :null}  
                            { showTab===2 ? <MovimientosCausa causa={detalleCausa}/> :null}  
                            { showTab===3 ? <TareasCausa causa={detalleCausa} calendario={calendario}/> :null}      
                        </> :null}
                    </Col>
                    <Col xs lg="4">
                        <Card className='w-100 m-2'>
                            <Card.Body>
                                <Row className='text-center fw-bolder fs-4 mb-2'> 
                                    <Col>{detalleCausa.case.active? <Badge bg="success">Causa Activa</Badge>: <Badge bg="danger">Causa Inactiva</Badge>}</Col>
                                    <Col>{estadoInterno? estadoInterno: null}</Col>
                                </Row>
                                <Row className='fw-bolder mb-2'><Col>Titulo: {detalleCausa.case.title}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>Rol: {detalleCausa.case.code}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>Tribunal: {detalleCausa.case.tribunal.nombre}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>Grupo: {detalleCausa.case.group?.description ? detalleCausa.case.group.description:"sin grupo"}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>Estado Interno Causa: {detalleCausa.case.caseInternalState?.name ? detalleCausa.case.caseInternalState.name:null}</Col></Row>
                            </Card.Body>
                        </Card>
                        <Card className='w-100 mt-3 m-2'>
                            <Card.Body>
                                <Row className='fw-bolder mb-2'><Col> <Button onClick={(e)=>openClientDetail(detalleCausa.case.client.identifier)} className='w-100' size="sm" variant='outline-info'>{detalleCausa.case.client?.name}</Button> </Col></Row>
                                <Row className='fw-bolder mb-2'><Col><Icon.PersonVcardFill className='me-3'/>{detalleCausa.case.client.identifier}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col><Icon.PhoneFill className='me-3'/>{detalleCausa.case.client?.telephone}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>C. Unica: {detalleCausa.case.client.claveUnica ? detalleCausa.case.client.claveUnica: "sin Clave"}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>SAC: {(detalleCausa.case.client.extra_select_10==null || detalleCausa.case.client.extra_select_10=="") ? detalleCausa.case.client.extra_select_5: detalleCausa.case.client.extra_select_10}</Col></Row>
                                <Row className='fw-bolder mb-2'><Col>Sucursal: {detalleCausa.sucursal?.description}</Col></Row>
                                        
                            </Card.Body>
                        </Card>
                        <Card className='w-100 mt-3 m-2'>
                            <Card.Body>
                                <Row className='fw-bolder mb-2'><Col> Usuarios de la causa <Button variant='outline-primary' onClick={addUsersCase}> <Icon.PersonFillAdd/> </Button> </Col></Row>
                                {detalleCausa.casesUsers.map(item => (
                                <Fragment key={item.id}>
                                    <Row className='fst-italic mb-2' hidden><Col> {item.id} </Col></Row>
                                    <Row className='fst-italic mb-2'><Col> <Icon.PersonSquare className='me-2'/> {item.nombre} </Col></Row>
                                    <Row className='fst-italic mb-2' hidden><Col> {item.email} </Col></Row>
                                </Fragment>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                    
                </Row>
            </Container>
            :<Alert key={"info"} variant={"info"}> <Spinner animation="grow" size='sm'/> Cargando informacion de la causa. Esto puede demorar, espere por favor</Alert>}
            
            <Modal show={showModalUser} onHide={handleClose}>
                <Modal.Header closeButton> <Modal.Title className='me-4'>Asignar Usuarios</Modal.Title> </Modal.Header>
                <Modal.Body>
                        <Form onSubmit={handleSubmitUpdateUsers(onSubmitTaskUsersUpdate)}>
                            <Controller rules={{ required: false }} control={controlUpdateUsers} name="newUsers" render={({ field: { onChange, value, name, ref } }) => (<>
                                <Form.Label>Lista Usuarios <div className='text-red'>* obligatorio</div> </Form.Label>
                                <Select inputRef={ref} classNamePrefix="addl-class" options={usersGeneral} value={usersGeneral.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} 
                                isMulti/>
                            </>)}/>
                            <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Actualizar Usuarios </Button>
                        </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalInternalState} onHide={handleCloseInternalState}>
                <Modal.Header closeButton> <Modal.Title className='me-4'>Cambiar Estado Interno</Modal.Title> </Modal.Header>
                <Modal.Body>
                        <Form onSubmit={handleSubmitUpdateInternalState(onSubmitInternalState)}>
                            <Controller rules={{ required: false }} control={controlUpdateInternalState} name="newInternalState" render={({ field: { onChange, value, name, ref } }) => (<>
                                <Form.Label>Estados Internos <div className='text-red'>* obligatorio</div> </Form.Label>
                                <Select inputRef={ref} classNamePrefix="addl-class" options={estadoInternoGeneral} value={estadoInternoGeneral.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} 
                                isMulti/>
                            </>)}/>
                            <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Actualizar Estado Interno </Button>
                        </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default PortafolioCausasDetallePage;
