import React, { Fragment, useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import tableCustomStyles from '../components/TableStyle';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { useDispatch, useSelector } from 'react-redux'
import { getListUsers } from '../Redux/CausasDucks'

import Export from "react-data-table-component"

const AdministracionDatosPage = () => {
    const dispatch = useDispatch()
    const { register, setValue, handleSubmit } = useForm();
    const [buttonPopupUsuario, setButtonPopupUsuario] = useState(false);
    
	const usuarios = useSelector(store => store.causas.usuarios)

    const [state, setState] = useState({data: []});

    const columnasUsuarios = [
        { name: "Nombre", selector: "nombre" },
        { name: "Correo", selector: "email" },
        {
            name: "", cell: (row) => <Fragment>
                <DropdownButton title="" as={ButtonGroup} id="bg-nested-dropdown" drop="start" >
                    <Dropdown.Item eventKey="1" onClick={() => alert(row.id)}>Modificar</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => alert(row.id)}>Bloquear</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={() => alert(row.id)}>Cambiar Contraseña</Dropdown.Item>
                </DropdownButton>
            </Fragment>,
            ignoreRowClick: true,
            button: true
        }];

    const paginationOptionsUsuarios = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const onClickButtonAddUser = () => {
        setButtonPopupUsuario(true);
    }

    const onClickButtonSerchUser = (data) => {
        console.log("SERCH BUTTON USER: " + data['s_serch_user']);

        setValue("s_serch_user", "");
    }

    useEffect(()=> {
        const data = usuarios.map( item => { return { id: item.id , nombre : item.nombre, email:item.email }; })
        setState({data})
    },[usuarios])

    useEffect(() => { dispatch(getListUsers()) }, [])

    return (
        <Fragment>
            <div className="container-fluid px-lg-0 px-xl-1">
                <section>
                    <div className="card-header">
                        <div className='row row-cols-md-auto g-10 align-items-center'>
                            <div className="col-md-10">
                                <h1 className="page-heading">Usuarios</h1>
                            </div>
                            <div className="card-body">
                                <Form className="row row-cols-md-auto" onSubmit={handleSubmit(onClickButtonSerchUser)}>
                                    <div className="col-md-8">
                                        <Form.Control type="text" className="form-control col-md-9" placeholder="Buscar usuario"  {...register('s_serch_user')}  />
                                    </div>
                                    <div className="col-2">
                                        <Button className="btn btn-primary" type="submit">Buscar</Button>
                                    </div>
                                    <div className="col-2">
                                        <Button className="btn btn-primary" type="button" onClick={onClickButtonAddUser}><Icon.Plus />Agregar</Button>
                                    </div>
                                </Form>
                                <DataTable className='table'
                                    columns={columnasUsuarios}
                                    data={state.data}
                                    customStyles={tableCustomStyles}
                                    noDataComponent="No hay usuarios que cumplan el criterio de despliegue"
                                    pagination
                                    paginationComponentOptions={paginationOptionsUsuarios}
                                >
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default AdministracionDatosPage;