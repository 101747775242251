import React, { Fragment, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Form, Col, Row, InputGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import params from "../configs/apirest.json"
import Collapse from 'react-bootstrap/Collapse';
import tableCustomStyles from '../components/TableStyle';

const baseUriCliente = params["urlBaseClientes"]
const apirestClienteLista = params["cliente_lista"]

const SACClientePage = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const [state, setState] = useState({
        data: []
    });

    const setDataOut = (data_in) => {
        data_in.map(elemento => (
            elemento.status = false
        ));
        setState({
            data: data_in
        });
    }

    React.useEffect(() => {
        console.log("URL:" +baseUriCliente + apirestClienteLista);
        axios.post(baseUriCliente + apirestClienteLista)
            .then(res => {
                setDataOut(res.data);
            }).catch((error) => {
                console.log("Error: "+ error + JSON.stringify(error));
            });
    }, []);

    const toDetalleCliente = (idCliente) => {
        console.log("idCliente: "+idCliente);
        navigate("/sac/cliente/" + idCliente);


    }

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const columnasClientes = [
        //{ name: "N°", selector: "id" },
        { name: "Nombre", selector: "nameClient" },
        { name: "Rut", selector: "rut" },
        { name: "Teléfono", selector: "phone" },
        { name: "Correo", selector: "email" },
        {
            name: "Acciones", cell: (row) => 
            <Fragment>
                <button className={"btn btn-outline-primary me-1"} type="button" onClick={() => toDetalleCliente(row.id)}><Icon.ArchiveFill/></button>
                <button className={"btn btn-outline-info"} type="button" onClick={() => toDetalleCliente(row.id)}><Icon.PenFill/></button>
            </Fragment>,
            ignoreRowClick: true,
            button: true
        }];

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <div className="page-breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to={null}>S.A.C.</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/sac/cliente">Cliente</NavLink>
                        </li>
                    </ul>
                </div>
                <section>
                    <div className="card mb-4">
                        <div className="card-header">
                            <h3 className="page-heading p-1 "><strong>S.A.C. Clientes</strong></h3>
                            <Form className="row row-cols-md-auto">
                                <div className="col-md-8">
                                    <InputGroup>
                                        <Form.Control
                                        placeholder="Buscar Rut, Nombre, Telefono, Correo"
                                        />
                                        <Button variant="outline-secondary" onClick={() => setOpen(!open)} aria-controls="collapse-form" aria-expanded={open}><Icon.Funnel/></Button>
                                        <Button variant="primary"><Icon.Search/></Button>
                                    </InputGroup>
                                </div>
                            </Form>
                            <Collapse in={open} className="card-body">
                                <Form className='mb-4'>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2"> Nombre </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                                <Form.Label column sm="2">
                                                    Identificador
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2">
                                                    Correo
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                                <Form.Label column sm="2">
                                                    Telefono
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm="2">
                                                    Tipo Cliente
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                                <Form.Label column sm="2">
                                                    Sucursal
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control type="text" className="form-control" />
                                                </Col>
                                            </Form.Group>
                                            <Row className="mb-2">
                                                <Col sm="9">                                                
                                                </Col>
                                                <Col sm="2">
                                                    <Button className="btn btn-primary" type="button">Filtrar</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Collapse>
                        </div>
                        <div className="card-body text-muted">
                            <Form>
                                    <DataTable className='table'
                                        columns={columnasClientes}
                                        data={state.data}
                                        customStyles={tableCustomStyles}
                                        noDataComponent="No hay clientes que cumplan el criterio de despliegue"
                                        pagination
                                        paginationComponentOptions={paginationOptions}
                                    />
                            </Form>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default SACClientePage;
