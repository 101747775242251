import React, { Fragment, DataGrid } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const AdministracionCorreosPage = () => {
    return (
        <Fragment>
            <div className="col-md-10">
                <h1 className="page-heading">Correos</h1>

                <div className='box'>
                    <h3>Filtros</h3>

                    <div className="row mb-4">
                        <div className="col-auto">
                            <label className="col-form-label" for="form6Example2">Tipo</label> 
                        </div>
                        <div className="col-auto">
                            <input className="form-control col-md-9" type="text" placeholder="Novedades Diarias" />
                        </div>

                        <div className="col-auto">
                            <label className="col-form-label" for="form6Example2">Destinatario</label> 
                        </div>
                        <div className="col-auto">
                            <input className="form-control col-md-9" type="text" placeholder="Novedades Diarias" />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-auto">
                            <label className="col-form-label" for="form6Example2">Fecha </label> 
                        </div>
                        <div className="col-auto">
                            <input className="form-control col-md-9" type="text" placeholder="Fecha Desde" />
                        </div>
                        <div className="col-auto">
                            <input className="form-control col-md-9" type="text" placeholder="Fecha Hasta" />
                        </div>

                        <div className="col-auto">
                            <Button>Guardar</Button>
                        </div>

                    </div>


                </div>



                <div className='box'>

                    <table className='table table-striped table-hover card-text'> 
                        <thead>
                            <tr>
                            <th>TIPO</th>
                            <th>BDESTINATARIO</th>
                            <th>ASUNTO</th>
                            <th>GENERADO</th>
                            <th>ENVIADO</th>
                            <th>LEIDO</th>
                            <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            </tr>
                        </tbody>
                    </table>
                
                </div>
                
            </div>
        </Fragment>
        )
    }

export default AdministracionCorreosPage;