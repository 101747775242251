import React, { Fragment, DataGrid } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const AdministracionCredencialesPage = () => {
    return (
        <Fragment>
            <div className="col-md-10">
                <h1 className="page-heading">Credenciales</h1>
                
                <div className='box'>
                   <p>Las credenciales guardadas se podrán usar al agregar nuevas causas usando los buscadores respectivos. Si se activa la opción de compartirlas, todos los usuarios podrán seleccionarlas al momento de buscar una causa, en caso contrario sólo el usuario que la haya ingresado podrá hacerlo.</p> 
                </div>

                <div className='box'>
                    <table className='table table-striped table-hover card-text'> 
                        <thead>
                            <tr>
                            <th>N°</th>
                            <th>BUSCADOR</th>
                            <th>NOMBRE DE REFERENCIAS</th>
                            <th>USUARIO</th>
                            <th>CREADOR</th>
                            <th>COMPARTIDA</th>
                            <th>CAUSAS</th>
                            <th>BLOQUEADAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
        )
    }

export default AdministracionCredencialesPage;