const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '20px',
        justifyContent: 'left',
        backgroundColor: '#dadcf8',
        color: "#0d6efd"
      },
    },
  }
  export default tableCustomStyles ;