import React, { Fragment, useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Form, Modal, ModalHeader, Col, Row, ModalBody, ModalFooter, Card, DropdownButton, ButtonGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import axios from 'axios';
import params from "../configs/apirest.json"
import Collapse from 'react-bootstrap/Collapse';

const SACClienteDetallePage = () => {
    const navigate = useNavigate();
    const [breadcrums, setBreadcrums] = useState([]);

    const toDetalleMovimiento = (idJuicio) => {
        console.log("idJuicio: "+idJuicio);
        var idCliente = 1;
        navigate("/sac/cliente/" + idCliente + "/mov"+ idJuicio);
    }

    const toDetalleTareas = (idJuicio) => {
        console.log("idJuicio: "+idJuicio);
        var idCliente = 1;
        navigate("/sac/cliente/" + idCliente + "/tar"+ idJuicio);
    }

    const toVolver = () => {
        navigate("/sac/cliente");
    }
    
    useEffect(()=>{
        setBreadcrums(
            <div className="page-breadcrumb">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item"> <NavLink to={null}>S.A.C.</NavLink> </li>
                    <li className="breadcrumb-item"> <NavLink to="/sac/cliente">Cliente</NavLink> </li>
                    <li className="breadcrumb-item"> <NavLink to="/sac/cliente">Detalle</NavLink> </li>
                </ul>
            </div>
        )
    },[])

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                {breadcrums}
                <section className='mb-2 d-flex flex-row'>
                    <Card className='me-2' style={{width: '50%'}}>
                        <Card.Body>
                            <Card.Title className='text-center'>Juan Jose Linch</Card.Title>
                            <Card.Text>
                                <Row> <Col> <Icon.PersonVcard className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">123456789-0</Col> </Row>
                                <Row> <Col> <Icon.Telephone className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">+56998315438</Col> </Row>
                                <Row> <Col> <Icon.EnvelopeAt className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">example@domain.com</Col> </Row>
                                <Row> <Col> <Icon.EnvelopeAt className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">pasaje isla coja 222, Maipu</Col> </Row>
                                <Row> <Col> <Icon.Key className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">Clave@1234</Col> </Row>
                            </Card.Text>
                            <Card.Text>
                                <Row> <Col> <Icon.PersonWorkspace className='text-info fs-4 mb-1'/></Col><Col className="text-muted text-end">Juan Rivera <b>(SAC)</b></Col> </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className='' style={{width: '50%'}}>
                        <Card.Body>
                            <Card.Title className='text-center mb-3'>Lineas de Negocio</Card.Title>
                            <Card.Text>
                                <Row> <Col className="text-muted text-start fw-bold">Linea</Col> <Col className="text-muted text-start fw-bold">Clasificación</Col><Col className="text-muted text-start fw-bold">Documentos</Col> </Row>
                                <Row> <Col className="text-start">Deudores</Col> <Col className="text-start">AL_DIA</Col><Col>
                                
                                <UncontrolledDropdown>
                                    <DropdownToggle caret className='btn bg-dark'>
                                        Documentos
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Contrato</DropdownItem>
                                        <DropdownItem>Hoja de Atención</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Col> </Row>
                                <Row> <Col className="text-start">Laboral</Col> <Col className="text-start">Finiquitado por baja</Col> <Col>
                                
                                <UncontrolledDropdown>
                                    <DropdownToggle caret className='btn bg-dark'>
                                        Documentos
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Contrato</DropdownItem>
                                        <DropdownItem>Hoja de Atención</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Col> </Row>
                                <Row> <Col className="text-start">Victima</Col> <Col className="text-start">Plan de pago Pagado</Col><Col>
                                
                                <UncontrolledDropdown>
                                    <DropdownToggle caret className='btn bg-dark'>
                                        Documentos
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Contrato</DropdownItem>
                                        <DropdownItem>Hoja de Atención</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Col>  </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </section>
                <section>
                    <div className="card mb-1">
                        <div className="card-header">
                            <div className="card-heading">Causa N° 0001</div>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col>
                                    Código
                                </Col>
                                <Col>
                                    D-52855-2014
                                </Col>
                                <Col>
                                    Causa
                                </Col>
                                <Col>
                                    causa test
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Cliente
                                </Col>
                                <Col>
                                    Cliente test
                                </Col>
                                <Col>
                                    Etapa
                                </Col>
                                <Col>
                                    estapa test
                                </Col>
                            </Row>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                    <Button type="button" variant='secondary' onClick={() => toDetalleMovimiento(1)}>Movimientos</Button>&nbsp;
                                    <Button type="button" variant='secondary'onClick={() => toDetalleTareas(1)}>Tareas</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="card-heading">Causa N° 0002</div>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col>
                                    Código
                                </Col>
                                <Col>
                                    D-52855-2014
                                </Col>
                                <Col>
                                    Causa
                                </Col>
                                <Col>
                                    causa test
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Cliente
                                </Col>
                                <Col>
                                    Cliente test
                                </Col>
                                <Col>
                                    Etapa
                                </Col>
                                <Col>
                                    estapa test
                                </Col>
                            </Row>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                    <Button type="button" variant='secondary' onClick={() => toDetalleMovimiento(2)}>Movimientos</Button>&nbsp;
                                    <Button type="button" variant='secondary'onClick={() => toDetalleTareas(2)}>Tareas</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card mb-4">
                        <div className="card-body text-muted">
                            <Button variant='primary' type="button" onClick={toVolver}>Volver</Button>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default SACClienteDetallePage;