import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form, Modal, ModalHeader, Col, Row, ModalBody, ModalFooter, FormGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Collapse from 'react-bootstrap/Collapse';

const PortafolioMovimientosPage = () => {
    const [stateTab, setstateTab] = useState(1);

    const action = (index) => {
        setstateTab(index);
    }

    const [buttonPopup, setButtonPopup] = useState(false);
    const [open, setOpen] = useState(false);

    const [state, setState] = useState({
        data: []
    });

    const setDataOut = (data_in) => {
        setState({
            ...state,
            data: data_in
        });
    }

    var data_out = [];
    React.useEffect(() => {
        /*console.log("Uri " + baseUri + apirestUri);
        axios.get(baseUri + apirestUri)
            .then(res => {
                console.log("DATA: " + JSON.stringify(res.data));
                data_out.push({
                    "id": res.data.id,
                    "identifier": res.data.identifier,
                    "nombre": res.data.name,
                    //"email": data_item.rut,
                    //"estado_cliente": data_item.estado_cliente
                });*/
        data_out.push({
            "id": "1",
            "identifier": "1234",
            "nombre": "Prueba",
            //"email": data_item.rut,
            //"estado_cliente": data_item.estado_cliente
        });

        setDataOut(data_out);
        //});
    }, []);

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <div className="page-breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to="/inicio">Inicio</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/portafolio">Portafolio</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/portafolio/movimientos">Movimientos</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="page-header">
                    <h1 className="page-heading">Movimientos</h1>
                </div>
                <section>
                    <div className="card mb-4">

                        <div className="card-header">
                            <div className='box'>
                                <div className="col-md-12 mb-9">
                                    <form className="row row-cols-md-auto">
                                        <div className="col-md-9">
                                            <input className="form-control col-md-9" type="text" placeholder="Buscar Causas por n°, codigo, nombre, cliente, asunto, juzgado etc..." />
                                            <label className="visually-hidden" for="inlineFormInputGroupUsername2">Buscar</label>
                                        </div>
                                        <div className="col-2">
                                            <Button className="btn btn-primary" type="button"
                                                onClick={() => setOpen(!open)}
                                                aria-controls="collapse-form"
                                                aria-expanded={open}><Icon.ArrowDown /></Button>
                                        </div>
                                        <div className="col-2">
                                            <button className="btn btn-primary" type="submit">Buscar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Collapse in={open} className="card-body">
                            <Form className='mb-4'>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2">
                                                N°
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                            <Form.Label column sm="2">
                                                Codigo
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2">
                                                Nombre
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                            <Form.Label column sm="2">
                                                Cliente
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-2">
                                            <Form.Label column sm="2">
                                                Asunto
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                            <Form.Label column sm="2">
                                                Juzgado
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Control type="text" className="form-control" />
                                            </Col>
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </Form>
                        </Collapse>

                        <div className="card-body text-muted">

                            <div className='box'>

                                <Row>
                                    <Col lg={12}>
                                        <Row className="mb-4">
                                            <Col className="form-outline">
                                                <Form.Label className="form-label" >Sólo Mis Causas</Form.Label>
                                            </Col>
                                            <Col className="form-outline">
                                                <Form.Check />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <FormGroup className="col">
                                                <Form.Label>Fecha Oficial</Form.Label>
                                                <Form.Control type="date" />
                                            </FormGroup>
                                            <FormGroup className="col" >     
                                            <Form.Label> &nbsp;</Form.Label>                                           
                                                <Form.Control type="date" />
                                            </FormGroup>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">Fecha De Creación</Form.Label>
                                                <Form.Control type="date" className="form-control" />
                                            </Col>
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">&nbsp;</Form.Label>
                                                <Form.Control type="date" className="form-control" />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">Fecha De Actualización</Form.Label>
                                                <Form.Control type="date" className="form-control" />
                                            </Col>
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">&nbsp; </Form.Label>
                                                <Form.Control type="date" className="form-control" />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">Cuaderno</Form.Label>
                                                <Form.Select className="form-control" >
                                                    <option selected>Seleccione ...</option>
                                                </Form.Select>
                                            </Col>
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">Clasificaón</Form.Label>
                                                <Form.Control type="text" placeholder="Clasificación" className="form-control" />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col className="form-outline">
                                                <Form.Label className="form-label">Que Contenga</Form.Label>
                                                <Form.Control type="text" placeholder="Que Contenga" className="form-control" />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="form-outline">
                                        <div className="col-2">
                                            <button className="btn btn-primary" type="submit">Buscar</button>
                                        </div>
                                    </Col>
                                    <Col className="form-outline">
                                        <button className="btn btn-primary" type="submit"><Icon.FileExcel />Descargar Excel</button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default PortafolioMovimientosPage;
