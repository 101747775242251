import React, { useState, useEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Button, Form, OverlayTrigger , Tooltip, InputGroup, Toast, Spinner, Row, Col } from "react-bootstrap"
import { useForm , onChange} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addCommentTask } from '../Redux/CausasDucks'
import Select from 'react-select'

export default function ComentTaskForm({IdTask, IdCase, Especial}) {
  
  const [showToast, setShowToast] = useState(false)
  const [messageToast, setMessageToast] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [files, setFiles] = useState(null);
  const [content, setContent] = useState(null);
  
  const { register: register, handleSubmit: handleSubmit, reset, setValue, formState: { errors }   } = useForm() //comentar
  const dispatch = useDispatch()
	const commentTask = useSelector(store => store.causas.commentTask)


  useEffect(() => { 
    //console.log(IdCase)
  }, [IdCase])

  
  useEffect(() => {
    var classic = true;
    var Options = null;
    switch(Especial.name){
      case "LLAM/COB": 
        Options = OPLLAMCOB; 
        classic=false; 
        break;
      case "INFO/CAUSA/MENSUAL": 
        Options = OPINFOCAUSAMENSUAL; 
        classic=false;  
        break;
    }

    if(classic){
      setContent(<>
        <Form.Control type="text" placeholder="Comentar" {...register("content")} required/>
        <Button variant="outline-secondary" onClick={()=> document.getElementById("comment-task"+IdTask).click()}>
          <OverlayTrigger className="w-auto"  placement="right" delay={{ show: 50, hide: 300 }} overlay={renderTooltipUpload} >
          <Icon.Upload/>
          </OverlayTrigger>  
        </Button>

        </>)
    }else{
      setContent( <>
        <Form.Control type="text" placeholder="Comentar" {...register("content", { minLength: 1 })} hidden required/>
        <Select onChange={(e) =>  setValue("content",e.value)} options={Options} 
            styles={{width:'auto'}}
            className="basic-single form-control"
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="color"
            required />
      </>)
    }

  }, [Especial])

  

  useEffect(() => {  
      //console.log(commentTask)
      setShowSpinner(commentTask.show)
      if(commentTask.message){
        setShowToast(true)
        setMessageToast(commentTask.message)
      }

      if(commentTask.taskId>0 && commentTask.comment){
        reset(formValues => ({
          ...formValues,
          content: null,
        }))
        setFiles(null)
      }

  }, [commentTask])


  const onSubmitNewComment = (e)=>{
    if(files) e.file = files
    else e.file = null //no existe entonces se borra
    dispatch(addCommentTask(e))
  }
  
  const deleteFile=(e)=>{
    setFiles(null)
  }
  
  const onFileChange = (e)=>{  
    const archivo = e.target.files
    if(archivo.length>0)
      setFiles(archivo[0])
  }
  
  const renderTooltipUpload = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Subir Archivo
    </Tooltip>
  );

  
  const OPLLAMCOB = [
    { value: null, label: "seleccione" },
    { value: "Pago", label: "Pago" },
    { value: "Compromiso de pago", label: "Compromiso de pago" },
    { value: "Finiquito", label: "Finiquito" },
    { value: "Sin respuesta", label: "Sin respuesta" }
  ]
  const OPINFOCAUSAMENSUAL = [
    { value: null, label: "seleccione" },
    { value: "Llamada/Informo estado de causa", label: "Llamada/Informo estado de causa" },
    { value: "Correo/ Informo estado de causa", label: "Correo/ Informo estado de causa" },
    { value: "WhatsApp/ Informo estado de causa", label: "WhatsApp/ Informo estado de causa" },
    { value: "Presencial/ Informo estado de causa", label: "Presencial/ Informo estado de causa" },
    { value: "No hay respuesta de cliente", label: "No hay respuesta de cliente" }
  ]
  


  return (
    <>
      <Form className="pb-2" onSubmit={handleSubmit(onSubmitNewComment)} >
        <InputGroup>
            <Form.Control id={"comment-task"+IdTask} type="file" {...register("file",  { onChange: (e) => onFileChange(e) })} hidden/>  
            
            {content}
            
            <Form.Control type="number" value="1" {...register("tipoCometario")} hidden/>
            <Form.Control type="number" value={IdTask} {...register("commentableId")} hidden/>

            <Button type="submit" variant="outline-primary">
                <Icon.Send/>
            </Button>
            {(showSpinner && IdTask==commentTask.taskId ) ? <Spinner animation="grow" variant="primary" />: null} 
        </InputGroup>
        {files ? 
          <Row className='mt-1'>
            <Col> {files.name} <Button onClick={deleteFile} variant="secondary" size="sm">x</Button></Col>
          </Row>
        :null}
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={3000} autohide>
          <Toast.Header>
              <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              />
              <strong className="me-auto">Atencion!</strong>
          </Toast.Header>
          <Toast.Body className='text-white'>
              {messageToast}
          </Toast.Body>
        </Toast>
      </Form>

    {/*
    (Especial.creator_Id==736 || Especial.creator_Id==767) & Especial.name=="LLAM/COB" ? 
    
      <Form className="pb-2" onSubmit={handleSubmit(onSubmitNewComment)} >
        <InputGroup>
            <Form.Control id={"comment-task"+IdTask} type="file" {...register("file",  { onChange: (e) => onFileChange(e) })} hidden/>  
            <Form.Control type="text" placeholder="Comentar1" {...register("content", { minLength: 1 })} hidden required/>
            
            <Select onChange={(e) =>  setValue("content",e.value)} options={OPLLAMCOB} 
                styles={{width:'auto'}}
                className="basic-single form-control"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                required />
            
            <Form.Control type="number" value="1" {...register("tipoCometario")} hidden/>
            <Form.Control type="number" value={IdTask} {...register("commentableId")} hidden/>

            <Button variant="outline-secondary" onClick={()=> document.getElementById("comment-task"+IdTask).click()} hidden>
                <OverlayTrigger className="w-auto"  placement="right" delay={{ show: 50, hide: 300 }} overlay={renderTooltipUpload} >
                <Icon.Upload/>
                </OverlayTrigger>  
            </Button>

            <Button type="submit" variant="outline-primary">
                <Icon.Send/>
            </Button>
            {(showSpinner && IdTask==commentTask.taskId ) ? <Spinner animation="grow" variant="primary" />: null} 
        </InputGroup>
        {files ? 
          <Row className='mt-1'>
            <Col> {files.name} <Button onClick={deleteFile} variant="secondary" size="sm">x</Button></Col>
          </Row>
        :null}
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={3000} autohide>
          <Toast.Header>
              <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              />
              <strong className="me-auto">Atencion!</strong>
          </Toast.Header>
          <Toast.Body className='text-white'>
              {messageToast}
          </Toast.Body>
        </Toast>
      </Form>
      :
      <Form className="pb-2" onSubmit={handleSubmit(onSubmitNewComment)} >
          <InputGroup>
              <Form.Control id={"comment-task"+IdTask} type="file" {...register("file",  { onChange: (e) => onFileChange(e) })} hidden/>
              
              <Form.Control type="text" placeholder="Comentar2" {...register("content")} required/>

              <Form.Control type="number" value="1" {...register("tipoCometario")} hidden/>
              <Form.Control type="number" value={IdTask} {...register("commentableId")} hidden/>

              <Button variant="outline-secondary" onClick={()=> document.getElementById("comment-task"+IdTask).click()}>
                  <OverlayTrigger className="w-auto"  placement="right" delay={{ show: 50, hide: 300 }} overlay={renderTooltipUpload} >
                  <Icon.Upload/>
                  </OverlayTrigger>  
              </Button>

              <Button type="submit" variant="outline-primary">
                  <Icon.Send/>
              </Button>
              {(showSpinner && IdTask==commentTask.taskId ) ? <Spinner animation="grow" variant="primary" />: null} 
          </InputGroup>
          {files ? 
            <Row className='mt-1'>
              <Col> {files.name} <Button onClick={deleteFile} variant="secondary" size="sm">x</Button></Col>
            </Row>
          :null}
          <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={3000} autohide>
            <Toast.Header>
                <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
                />
                <strong className="me-auto">Atencion!</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>
                {messageToast}
            </Toast.Body>
          </Toast>
      </Form>
    */}
    </>
  )
}