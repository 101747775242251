import React, { useState, useEffect, useRef } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Button, Form, OverlayTrigger , Tooltip, InputGroup, Toast, Spinner, Row, Col  } from "react-bootstrap"
import { Fragment } from 'react'
import { useForm, Controller  } from 'react-hook-form'
import Select from 'react-select'
import dateFormat from "dateformat"
import { useDispatch, useSelector } from 'react-redux'
import { addCommentMovement } from '../Redux/CausasDucks'

export default function ComentMovementForm({IdMovement}) {
  
  const [showToast, setShowToast] = useState(false)
  const [messageToast, setMessageToast] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [files, setFiles] = useState(null)
  
  const { register: register, handleSubmit: handleSubmit,reset } = useForm() //comentar
  const dispatch = useDispatch()
	const commentMovement = useSelector(store => store.causas.commentMovement)

  
  useEffect(() => {  
      //console.log(commentMovement)
      setShowSpinner(commentMovement.show)
      if(commentMovement.message && commentMovement.movementId==IdMovement){
        setShowToast(true)
        setMessageToast(commentMovement.message)
      }

      if(commentMovement.movementId>0 && commentMovement.comment){
        reset(formValues => ({
          ...formValues,
          content: null,
        }))
        setFiles(null)
      }
      
  }, [commentMovement])

  const onSubmitNewComment = (e)=>{
    if(files) e.file = files
    else e.file = null //no existe entonces se borra
    dispatch(addCommentMovement(e))
  }
  const deleteFile=(e)=>{
    setFiles(null)
  }

  const onFileChange = (e)=>{  
    const archivo = e.target.files
    if(archivo.length>0)
      setFiles(archivo[0])
  }

  
  const renderTooltipUpload = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Subir Archivo
    </Tooltip>
  );


 
  return (
    <Form className="pb-2" onSubmit={handleSubmit(onSubmitNewComment)} >
        <InputGroup>
        
            <Form.Control id={"comment-movement"+IdMovement} type="file" {...register("file",  { onChange: (e) => onFileChange(e) })} hidden/>
            
            <Form.Control type="text" placeholder="Comentar" {...register("content")} required/>
            <Form.Control type="number" value="2" {...register("tipoCometario")} hidden/>
            <Form.Control type="number" value={IdMovement} {...register("commentableId")} hidden/>

            <Button variant="outline-secondary" onClick={()=> document.getElementById("comment-movement"+IdMovement).click()}>
                <OverlayTrigger className="w-auto"  placement="right" delay={{ show: 50, hide: 300 }} overlay={renderTooltipUpload} >
                <Icon.Upload/>
                </OverlayTrigger>  
            </Button>

            <Button type="submit" variant="outline-primary">
                <Icon.Send/>
            </Button>
            {(showSpinner && IdMovement==commentMovement.movementId ) ? <Spinner animation="grow" variant="primary" />: null} 
        </InputGroup>
        {files ? 
          <Row className='mt-1'>
            <Col> {files.name} <Button onClick={deleteFile} variant="secondary" size="sm">x</Button></Col>
          </Row>
        :null}
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={3000} autohide>
          <Toast.Header>
              <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              />
              <strong className="me-auto">Atencion!</strong>
          </Toast.Header>
          <Toast.Body className='text-white'>
              {messageToast}
          </Toast.Body>
        </Toast>


    </Form>
  )
}