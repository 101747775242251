import React, { Fragment, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getActualizaciones } from '../Redux/CausasDucks'

const InformacionPage = () => {
  const dispatch = useDispatch()
  
	const actualizaciones = useSelector(store => store.causas.actualizaciones)
  const [version,setVersion]= useState(null) 

  useEffect(()=>{
    dispatch(getActualizaciones())
  },[])

  useEffect(()=>{
    console.log(actualizaciones)
    if(actualizaciones){
      setVersion(actualizaciones[0])
    }
  },[actualizaciones])
  return (
    <Fragment>
        <h2>Actualizaciones</h2>
        
        <h5>Version: {version? version.version:null}</h5>
        <h5>Ultima Actualizacion {version? version.fecha:null}</h5>
        
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col" className='w-auto'>Id</th>
            <th scope="col" className='w-auto'>Codigo</th>
            <th scope="col">Texto</th>
            <th scope="col">Fecha</th>
          </tr>
        </thead>
        <tbody>
          {actualizaciones.map(el => {return (
            <tr className='p-0' key={el.id}>
                <th scope="row" className='w-auto'>{el.id}</th>
                <th className='w-auto' >{el.codigo}</th>
                <th >{el.texto}</th>
                <td style={{width: '100px'}}>{el.fecha.split("T")[0]}</td>
            </tr>
        )})}
         
        </tbody>
      </table>
    </Fragment>
  )
}
export default InformacionPage