import React, { useState, useEffect, useRef } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Button, Card, Form, Modal, ListGroup, Row, Badge, OverlayTrigger , Tooltip,
  Table, Alert, Toast, Spinner, InputGroup} from "react-bootstrap"
import { Fragment } from 'react'
import { useForm, Controller  } from 'react-hook-form'
import Select from 'react-select'
import dateFormat, {i18n} from "dateformat"
import { useDispatch, useSelector } from 'react-redux'
import { getUpdateTarea, getNewTask, updateLikeTask, getListUsers, getFileAction, deleteTaskUser } from '../Redux/CausasDucks'
import ComentTaskForm from './ComentTaskForm'
import TareaUpdateDeadLine from './TareaUpdateDeadLine'
import { useNavigate } from 'react-router-dom'
//import TareaUpdateUsers from './TareaUpdateUsers'

export default function TareasCausa({calendario}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const myRef = useRef(null);

  i18n.monthNames = [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", ];
  
  const [auxMini, setAuxMini] = useState(0)
  /* modal */
  const [show, setShow] = useState(false)
  const [showAddUsers, setShowAddUsers] = useState(false)
  const [showTaskUser, setShowTaskUser] = useState(false)
  
  
  const [detalleshow, setdetalleshow] = useState(false)
  const [detalleCausa, setdetalleCausa] = useState(null)
  const [toastState, setToastState] = useState(false)
  const [showCalendario, setShowCalendario] = useState(null)
  const [updateUsersidTask, setUpdateUsersidTask] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [messageToast, setMessageToast] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [spinner, setSpinner] = useState(null);
  const [spinnerShow, setSpinnerShow] = useState(false)
  const [downloadId, setDownloadId] = useState(0)
  const [filterTask, setFilterTask] = useState({type: false, order: false}) /* type: 0 creacion, 1 limite; order: true desc, 1 asc */
  const [removeTaskUserId, setRemoveTaskUserId] = useState(null)
  const [removeTaskUserName, setRemoveTaskUserName] = useState(null)
  const [removeTaskId, setRemoveTaskId] = useState(null)
  

  const handleClose = () =>{setShow(false)}
  const handleShow = () => {setShow(true); UsersNewTask();}
  const handleCloseUsers = () => setShowAddUsers(false)
  const handleCloseTaskUsers = () => setShowTaskUser(false)
  
  const handleFilter = (type) => {
    if(type==filterTask.type){
      setFilterTask({type: filterTask.type, order: !filterTask.order})
    }else{
      setFilterTask({type: type, order: true})
    }
  }

  useEffect(() => {
    let newOrder= []
    let auxCausas = detalleCausa;
    if(auxCausas && auxCausas.taskComments){
      console.log(filterTask)
      if(filterTask.type){
        newOrder = auxCausas.taskComments.reverse();
        if(filterTask.order)
          newOrder = auxCausas.taskComments.sort((a,b)=>new Date(a.task.create_At)-new Date(b.task.create_At));
      }else{
        newOrder = auxCausas.taskComments.reverse();
        if(filterTask.order)
          newOrder = auxCausas.taskComments.sort((a,b)=>new Date(a.task.deadlineTime)-new Date(b.task.deadlineTime));
      }
      auxCausas.taskComments = newOrder
      console.log(auxCausas)
      setdetalleCausa(auxCausas)
      setAuxMini((auxMini+1))
    }
  }, [filterTask])
  
  /*form*/
  const { register: register, control, setValue, handleSubmit: handleSubmit} = useForm()
  const { register: registerUpdateUsers, reset, control:controlUpdateUsers, handleSubmit: handleSubmitUpdateUsers } = useForm()
  const { register: registerRemoveTaskUser, handleSubmit: handleSubmitRemoveTaskUser } = useForm()
  const { register: register3, handleSubmit: handleSubmit3 } = useForm() //comentar

  
	const causa = useSelector(store => store.causas.detalleCausa)
	const newTask = useSelector(store => store.causas.newTask)
	const messageUpdateTask = useSelector(store => store.causas.messageUpdateTask)
	const updateTask = useSelector(store => store.causas.updateTask) // bool
	const taskUpdate = useSelector(store => store.causas.taskUpdate) // obj task
	const sendNewTask = useSelector(store => store.causas.sendNewTask) // bool for new sending task
	const messageSendNewTask = useSelector(store => store.causas.messageSendNewTask) // msg for new sending task
	const likeTask = useSelector(store => store.causas.likeTask) // msg for new sending task
	const commentTask = useSelector(store => store.causas.commentTask) // new comment from task
	const usuarios = useSelector(store => store.causas.usuarios)
	const startDownload = useSelector(store => store.causas.startDownload)
	const removeTaskUser = useSelector(store => store.causas.removeTaskUser)

  useEffect(() => {  
    setdetalleCausa(causa)
    setdetalleshow(true)
  }, [causa, detalleshow])
  

  useEffect(() => {  
    dispatch(getListUsers())
  }, [])

  useEffect(() => { 
    if(calendario)
      if(calendario.id){
        setTimeout(() => { setShowCalendario(calendario.id) }, 2000)
      }
  }, [calendario]);
  
  useEffect(() => { 
    //console.log(myRef)
    myRef.current?.scrollIntoView();
  }, [showCalendario]);
  

  /*new task*/
  const onSubmitNewTask = (event) => {
    dispatch(getNewTask(event))
    //handleClose();
  } 

  useEffect(()=>{
    if(newTask){
      let AUX = causa
      let newTask_AUX = newTask
      if(newTask_AUX.task.taskStatus==null || newTask_AUX.task.taskStatus==undefined)
        newTask_AUX.task.taskStatus = { description: "in_progress", id: 2, create_At: "2023-02-09T16:23:44.37", update_At: "2023-02-09T16:23:44.37", creator_Id: 1, updater_Id: 1 }        
      if(newTask_AUX.task.creator_Id==null || newTask_AUX.task.creator_Id==undefined)
        newTask_AUX.task.creator_Id = 203
      if(newTask_AUX.task.finishDate==null || newTask_AUX.task.finishDate==undefined)
        newTask_AUX.task.finishDate = newTask_AUX.task.deadlineTime
      if(newTask_AUX.comments==null || newTask_AUX.comments==undefined)
        newTask_AUX.comments = []
      
      AUX.taskComments.unshift(newTask_AUX)
      setdetalleCausa(AUX)
      setShow(false)
      window.location.reload(false);
      /*navigate('/portafolio/causas/' + causa.case.id, {
        state: { data: { id: newTask_AUX.task.id, type: 'task' } },
      })*/

    }
  },[newTask])

  useEffect(()=>{
    if(commentTask.taskId>0 && commentTask.comment){
      let AUX = causa

      for (let i = 0; i < AUX.taskComments.length; i++) {
        const task = AUX.taskComments[i];
        if(task.task.id ==commentTask.taskId ){
          AUX.taskComments[i].comments.unshift(commentTask.comment)
          //AUX.taskComments[i].task.comments
          console.log(AUX.taskComments[i].comments)
        }
      }
      setdetalleCausa(AUX)
      setAuxMini((auxMini+1))
    }
  },[commentTask])

  useEffect(()=>{
    //sendNewTask
  },[sendNewTask])

  useEffect(()=>{
    //sendNewTask
    if(messageSendNewTask){
      setShowToast(true)
      setMessageToast(messageSendNewTask) 
    }
  },[messageSendNewTask])

  useEffect(()=>{
    //sendNewTask
    if(removeTaskUser){
      if(removeTaskUser.status==0){
        setShowToast(false)
        setMessageToast(null)
      }
      if(removeTaskUser.status>1){
        setShowToast(true)
        setMessageToast(removeTaskUser.message)
      }
      if(removeTaskUser.status==2){
        setShowTaskUser(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }
    }  
  },[removeTaskUser])
  
  
  const onSubmitTaskUsersUpdate = (e) =>{
    setSpinner(<Spinner animation="grow" variant="primary" />)
    dispatch(getUpdateTarea(e))
  }
  useEffect(() => { 
    if(messageUpdateTask){
      setShowToast(true)
      setMessageToast(messageUpdateTask)
      
    }
  }, [messageUpdateTask]);
  useEffect(() => { 
        setShowSpinner(updateTask) 
  }, [updateTask]);

  useEffect(() => { 
        if(taskUpdate){
          let AUX = causa
          const newsUsers = []
          
          for (let k = 0; k < taskUpdate.newUsers.length; k++) {
            const userNew = taskUpdate.newUsers[k];
            const found = AUX.casesUsers.find(obj => obj.id == userNew)
            if(found)
              newsUsers.push(found)
          }
          for (let i = 0; i < AUX.taskComments.length; i++) {
            const obj = AUX.taskComments[i];
            if(obj.task.id==taskUpdate.taskId){
              AUX.taskComments[i].usersAsigned = AUX.taskComments[i].usersAsigned.concat(newsUsers)   
            }
          }
          setdetalleCausa(AUX)
          setShowAddUsers(false)
        } 
  }, [taskUpdate]);

  const renderTooltip = (nombre) => (
    <Tooltip id="button-tooltip">
      {nombre}
    </Tooltip>
  );
  
  
  const statusView = (status) => { 
    switch(status){
      case "approved": return "green";
      case "finished": return "green";
      case "rejected": return "red";
      default: return "orange";
    }
  }

  const [users,setUsers]= useState([])
  const [usersGeneral,setUsersGeneral]= useState([])
  
  const addUserTask = (idTask, taskUsers) =>{
    setUpdateUsersidTask(idTask)
    reset(formValues => ({ ...formValues, newUsers: [], taskId: idTask}))
    let AUX = []
    for (let i = 0; i < detalleCausa.casesUsers.length; i++) {
      const item = detalleCausa.casesUsers[i]
      const found = taskUsers.find(obj => obj.id == item.id)
      if(found==undefined)
        AUX.push({ value: item.id , label : item.nombre })
    }
    setUsers(AUX)
    setShowAddUsers(true)

    let gen = [] 
    usuarios.map( item => { 
      if(item.active) gen.push({ value: item.id , label : item.nombre })
    })
    console.log(gen)

    setUsersGeneral(gen)
  }

  
  const UsersNewTask = () =>{
    const aux = detalleCausa.casesUsers.map( item => { return { value: item.id , label : item.nombre }; })
    setUsers(aux)
    let gen = [] 
    usuarios.map( item => { 
      if(item.active) gen.push({ value: item.id , label : item.nombre })
    })
    console.log(gen)

    setUsersGeneral(gen)
    setAuxMini((auxMini+1))
  }

  const likeTaskUpdate = (taskId, statusId) => {
    dispatch(updateLikeTask({taskId, statusId}))
  }
  useEffect(() => {
    if(likeTask.message){
      setShowToast(true)
      setMessageToast(likeTask.message)
      if(likeTask.taskId>0 && likeTask.statusId ){
        let AUX = causa
        
        for (let i = 0; i < AUX.taskComments.length; i++) {
          const obj = AUX.taskComments[i];
          if(obj.task.id==likeTask.taskId){
            AUX.taskComments[i].task.taskStatus.id = likeTask.statusId
            AUX.taskComments[i].task.taskStatusId = likeTask.statusId
            AUX.taskComments[i].task.taskStatus.description = likeTask.statusId==1? "approved": "rejected"          }
        }
        setdetalleCausa(AUX)
      }
    }
  }, [likeTask])
  
  
  const comentador = (id)=>{
    if(id==null)
        return "Sin usuario"
    if(usuarios){
        const user = usuarios.find(obj => obj.id == id)
        if(user)
            return user.nombre;
        else
            return "no encontrado"
    }
  }

  const modalUserTask = (userId, idTask, userName) =>{
    ///perro
    setRemoveTaskUserId(userId)
    setRemoveTaskId(idTask)
    setRemoveTaskUserName(userName)
    setShowTaskUser(true);
  }
  const onSubmitTaskUsersRemove = (event) =>{
    dispatch(deleteTaskUser(event))
  }

  const downloadFile = (json) => {
      setDownloadId(json.commentableId)
      dispatch(getFileAction(json))
  };
  useEffect(()=>{
    setSpinnerShow(startDownload.start)

    if(startDownload.message){
        setShowToast(true)
        setMessageToast(startDownload.message)
    }else{
        setShowToast(false)
        setMessageToast(null)
    }
    if(startDownload.message==null && !startDownload.start)
        setDownloadId(0)

},[startDownload])

  useEffect(()=>{
    console.log(usuarios)
  },[startDownload])

  
  return (
    <Fragment>
      <Toast show={toastState} onClose={() => setToastState(false)} delay={3000} autohide >
        <Toast.Header>
          <span className="dot bg-warning me-2" />
          <div className="card-heading text-dark me-auto">Error </div>
          <small>Fecha </small>
        </Toast.Header>

        <Toast.Body className="text-muted">
          Hello, world! This is a toast message.
        </Toast.Body>
      </Toast>
       
      {detalleshow ?<>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton> <Modal.Title className='me-4'>Agregar Tarea Nueva</Modal.Title> {sendNewTask ? <Spinner animation="grow" variant="primary" />:null} </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmitNewTask)} >
              <Form.Group className="mb-2"> <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" placeholder="Nombre tarea" {...register("name")} required/>
              </Form.Group>
              <Form.Group className="mb-2"> <Form.Label>Descripcion</Form.Label>
                <Form.Control as="textarea" placeholder="Descripcion tarea" {...register("description")}/>
              </Form.Group>

              <Form.Group className="mb-3"> <Form.Label>Fecha Limite</Form.Label>
                <Form.Control type="date" {...register("deadlineTime")}  min={new Date().toISOString().split("T")[0]} max="9999-12-31" required/>
              </Form.Group>
              
              <Controller rules={{ required: false }} control={control} name="usersId" render={({ field: { onChange, value, name, ref } }) => (<>
                    <Form.Label>Usuarios de la Causa</Form.Label>
                    <Select inputRef={ref} classNamePrefix="addl-class" options={users} value={users.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} 
                    isMulti/>
              </>)}/>

              
              <Controller rules={{ required: false }} control={control} name="usersIdNuevos" render={({ field: { onChange, value, name, ref } }) => (<>
                    <Form.Label>Otros Usuarios</Form.Label>
                    <Select inputRef={ref} classNamePrefix="addl-class" options={usersGeneral} value={usersGeneral.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} 
                    isMulti/>
              </>)}/>

              
              <Form.Control type="text" value={detalleCausa.case.id} {...register("caseId")} hidden/>
              <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Guardar Tarea </Button>
            </Form>
          </Modal.Body>
        </Modal>
      

        <Card className="mb-4">
          <Card.Header>
            <Row className='d-flex justify-content-between'>
              <div className='w-auto'> <Button variant="primary btn-sm" onClick={handleShow}> <Icon.PlusCircleFill/> Nueva Tarea </Button> </div>

              <div className='w-auto'>
                
                <Button variant=" btn-sm btn-outline-info rounded-pill" onClick={() => handleFilter(true)}> {filterTask.type? (filterTask.order?<Icon.ArrowDownCircleFill/>:<Icon.ArrowUpCircleFill/>):null} F Limite </Button> 
                <Button variant=" btn-sm btn-outline-info rounded-pill" onClick={() => handleFilter(false)}> F Creacion {!filterTask.type? (filterTask.order?<Icon.ArrowDownCircleFill/>:<Icon.ArrowUpCircleFill/>):null} </Button> 
              </div>
              <h6 className='w-auto'> Tareas de la causa</h6>
            </Row>
          </Card.Header>
          <ListGroup>
            { detalleCausa.taskComments.map((Task, ii) => (
              <ListGroup.Item className="border-start-0 border-end-0 py-5 border-top-0 " key={ii}>
                <div className="d-flex pt-1" ref={showCalendario==Task.task.id ? myRef : null}></div>
                {showCalendario==Task.task.id ?
                  <Fragment> 
                    <Alert key="info" variant="info"> Tarea Abierta desde Calendario</Alert>
                  </Fragment>
                :null}
                <Row className='d-flex justify-content-between'>
                  <div className='w-auto'> <Badge bg={statusView(Task.task.taskStatus.description)}>{Task.task.taskStatus.description} </Badge> </div>
                  <div className='w-auto' style={{fontSize:'0.72rem'}}>Creada: {Task.task.create_At? dateFormat(Task.task.create_At,"dd-mmm-yyyy"):""} </div>
                  <div className='w-auto' style={{fontSize:'0.72rem'}}>Por: { (usuarios.find(obj => obj.id == Task.task.creator_Id))?.nombre} </div>
                  {  Task.task.taskStatus.id==2 ?
                  <div className='w-auto'>
                    <Button onClick={() => likeTaskUpdate(Task.task.id, 1)}  size="sm" variant="outline-success"><Icon.HandThumbsUpFill/> Aprobar</Button>
                    <Button onClick={() => likeTaskUpdate(Task.task.id, 4)} size="sm" variant="outline-danger"><Icon.HandThumbsDownFill/> Rechazar</Button> 
                    {
                      likeTask.taskId == Task.task.id && likeTask.show  ?
                    <div className='pt-3 w-100 text-center'><Spinner animation="grow" variant="primary" size="sm"/> </div>
                  :null}
                  </div>
                  :null}
                  <div className="w-auto text-muted text-sm fs-4">
                    {Task.usersAsigned.map((obj, index) => (<Fragment key={index}>
                      <OverlayTrigger className="w-auto" placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(obj.nombre)}>
                        <Icon.PersonSquare className="m-1 fs-4" color="Cyan"  onClick={()=>modalUserTask(obj.id, Task.task.id, obj.nombre)}/>
                      </OverlayTrigger>
                    </Fragment>))}
                  
                  
                    {Task.task.taskStatus.description =="in_progress" ? 
                    <OverlayTrigger className="w-auto"  placement="right" delay={{ show: 250, hide: 400 }}  overlay={renderTooltip("nuevo")} >
                    <Button onClick={() => addUserTask(Task.task.id, Task.usersAsigned)} variant="link" className='btn-sm w-auto'>
                      <Icon.PersonAdd className="m-1 fs-4" color="chocolate"/>
                      
                      </Button>
                    </OverlayTrigger>
                    :null}
                  </div>
                </Row>
                <div className="d-flex pt-1">
                    <div className="flex-grow-1 ps-3">
                      <h5 className="fw-bold py-2">{Task.task.name}</h5>
                      <div className="float-right p-2" style={{textAlign: "justify"}}>{Task.task.description?.split('<br/>').map( (it, i) => <div className='text-justify' key={'x'+i}>{it}</div>)} </div>
                    
                    {Task.task.taskStatus.description =="in_progress" ? 
                    <TareaUpdateDeadLine detalleCausa={detalleCausa} task={Task.task}  />      
                      :null}

                      

                      {Task.comments.length>0 ?
                        <Table responsive className="card-text">
                          <thead>
                            <tr>
                              <th>Comentario</th>
                              <th>Archivo</th>
                              <th className='text-center'>Usuario</th>
                              <th className='text-center'>Fecha</th>
                            </tr>
                          </thead>
                          <tbody>{Task.comments.map((comment, indexC) => (
                            <tr key={indexC} className="align-middle">
                              <td>{comment.content}</td>
                              <td className='text-center'>{comment.urlAttachment? 
                              <Fragment>
                                { (spinnerShow && comment.id==downloadId )? <Spinner size='sm' animation="grow" variant="primary" />:null}
                                <OverlayTrigger className="w-auto" placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(comment.nameAttachment)} >
                                    <Icon.Download onClick={() => downloadFile({commentableId:comment.id, tipoComentario:1, nameAttachment: comment.nameAttachment})} className="m-1 fs-4" color="Cyan"/>
                                </OverlayTrigger>
                            </Fragment>
                              :null}</td>
                              <td className='text-muted text-sm text-center'>{comentador(comment.creator_Id)}</td>
                              <td className='text-muted text-sm text-center'> {comment.create_At? dateFormat(comment.create_At,"dd-mmm-yyyy"):""}</td>
                            </tr>
                          ))}</tbody>
                        </Table>
                      :<Alert variant='light' className='pb-0 mb-2'> Sin Comentarios </Alert>}  
                    {Task.task.taskStatus.description =="in_progress" || Task.task.taskStatus.description=="approved" ? 
                      <ComentTaskForm IdTask={Task.task.id} IdCase={causa.case.id} Especial={{creator_Id: Task.task.creator_Id ,name: Task.task.name}}/> :null}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>

        {/* MODAL PARA ASIGNAR NUEVOS USUARIOS */}
        <Modal show={showAddUsers} onHide={handleCloseUsers}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar usuarios a la tarea</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmitUpdateUsers(onSubmitTaskUsersUpdate)} >

              <Controller rules={{ required: false }} control={controlUpdateUsers} name="newUsers" render={({ field: { onChange, value, name, ref  } }) => (<>
                  <Form.Label>Usuarios</Form.Label>
                  {showSpinner ? spinner: null} 
                  <Select inputRef={ref} classNamePrefix="addl-class" options={users} value={users.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} isMulti/>
              </>)}/>
             
              <Controller rules={{ required: false }} control={controlUpdateUsers} name="newUsersCase" render={({ field: { onChange, value, name, ref } }) => (<>
                    <Form.Label>Otros Usuarios</Form.Label>
                    <Select inputRef={ref} classNamePrefix="addl-class" options={usersGeneral} value={usersGeneral.find((c) => c.value === value)} onChange={(val) => onChange(val.map((c) =>c.value))} 
                    isMulti/>
              </>)}/>
             
              <Form.Control type="text" value={updateUsersidTask} {...registerUpdateUsers("taskId")} hidden/>
             
              <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Guardar Tarea </Button>
              
            </Form>
          </Modal.Body>
        </Modal>

        {/* MODAL ELIMINAR USUARIO ASIGNADO */}
        {/* perro */}
        <Modal show={showTaskUser} onHide={handleCloseTaskUsers}>
          <Modal.Header closeButton>
            <Modal.Title>Borrar a {removeTaskUserName} de la tarea?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Remover Usuario de la Tarea
            <Form onSubmit={handleSubmitRemoveTaskUser(onSubmitTaskUsersRemove)} >
            <Form.Control type="text" value={removeTaskUserId} {...registerRemoveTaskUser("deleteUserId")} hidden/>             
            <Form.Control type="text" value={removeTaskId} {...registerRemoveTaskUser("taskId")} hidden/>             
              <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Trash2Fill/> Remover Usuario </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>:null}


      <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={4000} autohide>
        <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Atencion!</strong>
        </Toast.Header>
        <Toast.Body className='text-white'>
            {messageToast}
        </Toast.Body>
      </Toast>
    </Fragment>
  )
}