import React, { Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import avatar1 from '../img/avatar-logo.png';
import Cookies from 'universal-cookie';
import { Button } from 'react-bootstrap';

const Header = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const username = cookies.get('user_nombre');
    if (username == undefined) { navigate("/");}

    const onClickButtonDelete = () => {
        cookies.remove("user_username", { path: "/" });
        cookies.remove("user_nombre", { path: "/" });
        cookies.remove("user_id", { path: "/" });
        navigate("/");
    }

    return (
        <Fragment>
            <header className="header">
                <div className="navbar navbar-expand-lg px-4 py-2 bg-white shadow" style={{height:'65px'}}>
                <NavLink className="sidebar-toggle text-gray-500 me-4 me-lg-5 lead d-block d-sm-none"  >
                        <Icon.List style={{color: "black" , fontSize:'20px'}}/>
                    </NavLink>
                    <NavLink to={'/informacion'} className="text-black me-4 me-lg-5 lead d-block"  >
                        <Icon.InfoCircleFill style={{color: "green" , fontSize:'20px', marginTop:"-5px"}}/> Actualizaciones y Mejoras
                    </NavLink>
                    
                    <NavLink className="navbar-brand fw-bold text-uppercase text-base" to="/Inicio">
                        <span className="d-none d-brand-partial"><img src='/logo3.png' width="70px"/> </span>
                    </NavLink>
                    <div className="ms-auto d-flex align-items-center list-unstyled mb-0">
                        <div className="nav-item dropdown ms-auto">
                            <span className="d-none d-sm-inline">Hola {username}</span>
                        </div>
                        <div className="nav-item dropdown ms-auto">
                            <NavLink className="nav-link pe-0" to="/Inicio">
                                <img className="avatar p-1" src={avatar1}/>
                            </NavLink>
                        </div>
                        <div className="nav-item dropdown ms-auto">
                            <NavLink className="nav-link pe-0" to="/Inicio">
                                <Button variant="primary" size="sm" type='button' onClick={onClickButtonDelete}>Cerrar Sesión</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment >
    );
}

export default Header;
