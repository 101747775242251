import ReactDOM from 'react-dom/client';
import App from './App';
import Header from './components/Header';
import Menu from './components/Menu';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { Fragment} from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='*' element={
                <Fragment>
                    <Header />
                    <div className="d-flex align-items-stretch">
                        <Menu/>
                        <div className="page-holder bgPrincipalColor">
                            <div className="container-fluid px-lg-0 px-xl-4">
                                <App />
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </Fragment>} />
        </Routes>
    </BrowserRouter>
);

