import React, { useState, Fragment, useEffect } from 'react'
import { useForm, Controller  } from 'react-hook-form'
import * as Icon from 'react-bootstrap-icons'
import { Container, Button, Card, Form, Modal, ListGroup, Row, Spinner, OverlayTrigger , Tooltip,
    InputGroup, Table, Alert, Toast  } from "react-bootstrap"
import dateFormat, { i18n } from "dateformat"
import  ComentMovementForm  from './ComentMovementForm'
import { useDispatch, useSelector } from 'react-redux'
import { getNewMovement, getListUsers, getFileAction } from '../Redux/CausasDucks'
import { GetListDocument, SendNewDocument } from '../Redux/MovementDucks'
import CreatableSelect from 'react-select/creatable'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'


export default function MovimientosCausa() {

    i18n.monthNames = [
        "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic",
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
    ];

    const dispatch = useDispatch()
    const [detalleCausa, setdetalleCausa] = useState(null)
    const [auxMini, setAuxMini] = useState(0)
    const [toastState, setToastState] = useState(false)
    const [show, setShow] = useState(false)
    const [showDoc, setShowDoc] = useState(false)
    const [usersGeneral, setUsersGeneral] = useState(null)
    const [showToast, setShowToast] = useState(false)
    const [messageToast, setMessageToast] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [spinnerShowDoc, setSpinnerShowDoc] = useState(false)
    const [downloadId, setDownloadId] = useState(0)
    const [listDoc, setListDoc] = useState([])
    const [extraFiles, setExtraFiles] = useState([])
    const [documentId, setDocumentId] = useState()

    const { register: register, setValue, handleSubmit: handleSubmit } = useForm()
    const { register: register2, control, handleSubmit: handleSubmit2 } = useForm()
    
    const handleClose = () => setShow(false)
    const handleCloseDoc = () => setShowDoc(false)
    const handleShow = () => setShow(true)
    const handleShowDoc = () => {
        dispatch(GetListDocument({caseId: causa.case.id}))
        setShowDoc(true)
    }

    
	const causa = useSelector(store => store.causas.detalleCausa)
	const commentMovement = useSelector(store => store.causas.commentMovement) // new comment from task
	const newMovement = useSelector(store => store.causas.newMovement)
	const usuarios = useSelector(store => store.causas.usuarios)
	const startDownload = useSelector(store => store.causas.startDownload)

	const listDocument = useSelector(store => store.movimientos.listDocument)
	const documentStatus = useSelector(store => store.movimientos.documentStatus)
    
    useEffect(()=>{
        if(newMovement){
            let AUX = causa
            let newMovement_AUX = {movement :newMovement, comments:[] }

            AUX.movComments.unshift(newMovement_AUX)
            setdetalleCausa(AUX)
            setShow(false)
        }
    },[newMovement])

    useEffect(()=>{
        setSpinnerShow(startDownload.start)

        if(startDownload.message){
            setShowToast(true)
            setMessageToast(startDownload.message)
        }else{
            setShowToast(false)
            setMessageToast(null)
        }
        if(startDownload.message==null && !startDownload.start)
            setDownloadId(0)

    },[startDownload])
    
    useEffect(() => {  
        if(usuarios.length>0){
            const gen = usuarios.map( item => { return { value: item.id , label : item.nombre }; })
            setUsersGeneral(gen)
            setAuxMini(auxMini+1)
        }else{
            dispatch(getListUsers())
            setAuxMini(auxMini+1)
            
        }
    }, [usuarios])

    useEffect(() => {  
        setdetalleCausa(causa)
      }, [causa])

    useEffect(()=>{
        if(commentMovement.movementId>0 && commentMovement.comment){
          let AUX = causa
          for (let i = 0; i < AUX.movComments.length; i++) {
            const _mov = AUX.movComments[i];
            if(_mov.movement.id ==commentMovement.movementId ){
              AUX.movComments[i].comments.unshift(commentMovement.comment)
            }
          }
          setdetalleCausa(AUX)
          setAuxMini((auxMini+1))
        }
      },[commentMovement])

    const comentador = (id)=>{
        if(id==null)
            return "Sin usuario"
        if(usuarios){
            const user = usuarios.find(obj => obj.id == id)
            
            if(user)
                return user.nombre;
            else
                return "no encontrado"
        }
    }

    const renderTooltip = (name) => (
        <Tooltip id="button-tooltip">
            {name}
        </Tooltip>
    )
    /*new task*/
    const onSubmitNewMovement = (event) => {
        dispatch(getNewMovement(event))
    }
    const onSubmitNewDocument = (event) => {

        setSpinnerShowDoc(true)
        event.documentId = documentId

        const extraParams = extraFiles.map((obj) => {
                return { name: obj.name, value: obj.type=="Fecha"? dateFormat(obj.value,"dd-mm-yyyy") :obj.value }
        })
        event.extraParams = extraParams
        dispatch(SendNewDocument(event))
    }

    useEffect(()=>{
    },[])

    const downloadFile = (json) => {
        setDownloadId(json.commentableId)
        dispatch(getFileAction(json))
    }

    useEffect(() => {
        const aux = listDocument.map( item => { return { value: item.id , label : item.nombre } })
        setListDoc(aux)
        
     }, [listDocument])

    useEffect(() => {
        if(documentStatus.status!=1)
            setSpinnerShowDoc(false)
    }, [documentStatus])

    const handleExtraFiles = (docId) =>{
        var doc = (listDocument.find(obj => obj.id == docId)).extraFields
        const list = doc.map((obj) => { return { ...obj, value: '' } });
        setExtraFiles(list)
    }
    const handleSaveExtraFiles = (date,item) =>{
        const list = extraFiles.map((obj) => {
            if (obj.name == item.name)
                return { ...obj, value: date }
            return obj
        })
        setExtraFiles(list)
    }

  return (
    <Fragment>
        <Toast show={toastState} onClose={() => setToastState(false)} delay={3000} autohide>
            <Toast.Header>
                <span className="dot bg-warning me-2" />
                <div className="card-heading text-dark me-auto">Error </div>
                <small>Fecha </small>
            </Toast.Header>
            <Toast.Body className="text-muted">
                Hello, world! This is a toast message.
            </Toast.Body>
        </Toast>
   
        {causa ?<>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Agregar Movimiento Nuevo</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmitNewMovement)} >
                    <Form.Group className="mb-2">
                    <Form.Label>Titulo</Form.Label>
                    <Form.Control type="text" placeholder="Nombre tarea" {...register("title")}/>
                    </Form.Group>
                    <Form.Group className="mb-2">
                    <Form.Label>Descripcion</Form.Label>
                    <Form.Control as="textarea" placeholder="Descripcion tarea" {...register("description")}/>
                    </Form.Group>
       
                    <Form.Control type="text" value={causa.case.id} {...register("caseId")} hidden/>

                    <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Guardar Movmiento </Button>
                </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showDoc} onHide={handleCloseDoc}>
                <Modal.Header closeButton>
                <Modal.Title>Generar Documento Nuevo</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit2(onSubmitNewDocument)} >

                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm="6"> Nombre Documento</Form.Label>

                            <CreatableSelect 
                                {...register2("documentId")}
                                name="documentId"
                                options={listDoc} 
                                isClearable 
                                onChange={(opt, meta) =>{
                                        handleExtraFiles(opt.value)
                                        setDocumentId(opt.value)
                                    }
                                }
                            />

                            {/*
                            <Controller name="documentId" render={
                                ({ field }) => ( <CreatableSelect {...field} options={listDoc} isClearable /> )} control={control} rules={{ required: false }
                            }/>
                            */}
                    </Form.Group>

                    {extraFiles.map((item, index) => (
                        item.type=="Fecha" ?
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label>{item.label}</Form.Label>
                                <DatePicker
                                    selected={item.value? item.value: new Date()}
                                    onChange={(date) => handleSaveExtraFiles(date, item)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    name={item.name}
                                    dateFormat="dd-MM-yyyy"
                                    customInput ={<Form.Control type="text" style={{marginLeft:11, width:'95%'}} className="form-control"/>}
                                /> 
                            </Form.Group>: null
                    ))}

                    <Form.Control type="text" value={causa.case.id} {...register2("caseId")} hidden/>

                    <div className="row">
                        <div className="col">
                            <Button variant="primary" className='mt-3 w-auto' type="submit"> <Icon.Send/> Guardar Documento </Button>
                        </div>
                        <div className="col">
                            { spinnerShowDoc ? <><Spinner className='mt-3'  animation="grow" variant="primary" /> {documentStatus.msg}</>: null}
                        </div>
                    </div>
                    </Form>

                </Modal.Body>
            </Modal>

            <Card className="mb-4">
                <Card.Header>
                    <Row className='d-flex justify-content-between'>
                        <div className='w-auto'> <Button variant="primary btn-sm" onClick={handleShow}> <Icon.PlusCircleFill/> Nuevo Movimiento </Button> </div>
                        <div className='w-auto'> <Button variant="primary btn-sm" onClick={handleShowDoc}> <Icon.PlusCircleFill/> Generar Documento </Button> </div>
                        <h6 className='w-auto'> Movimientos de la causa</h6>
                    </Row>
                </Card.Header>
                <ListGroup>
                { causa.movComments.map((_Mov, ii) => (
                    <ListGroup.Item className="border-start-0 border-end-0 py-5 border-top-0" key={ii}>
                        <Row className='d-flex justify-content-between'>
                            <h6 className='w-auto'> caseId {_Mov.movement.caseId} </h6>
                            {/* <h6 className='w-auto' hidden> extraMov {_Mov.movement.extraMov} </h6> */}
                   
                            <h6 className='w-auto'> ID {_Mov.movement.id} </h6>
                        
                            <div className='w-auto' style={{fontSize:'0.72rem'}}>Creada: {_Mov.movement.create_At? dateFormat(_Mov.movement.create_At,"dd-mmm-yyyy"):""}</div>
                            <div className='w-auto' style={{fontSize:'0.72rem'}}>Por: { (usuarios.find(obj => obj.id == _Mov.movement.creator_Id)).nombre} </div>
                        </Row>
                        <div className="d-flex pt-1">
                            <div className="flex-grow-1 ps-3">
                                <h5 className="fw-bold py-2">{_Mov.movement.title}</h5>
                                <div className="float-right p-2" style={{textAlign: "justify"}}>{
                                _Mov.movement.description?
                                _Mov.movement.description.split('<br/>').map( (it, i) => <div className='text-justify' key={'x'+i}>{it}</div>)
                                :null} </div>
                            
                                {_Mov.comments.length>0 ?
                                <Table responsive className="card-text">
                                    <thead>
                                    <tr>
                                        <th>Comentario</th>
                                        <th>Archivo</th>
                                        <th className='text-center'>Usuario</th>
                                        <th className='text-center'>Fecha</th>
                                    </tr>
                                    </thead>
                                    <tbody>{_Mov.comments.map((comment, indexC) => (
                                    <tr key={indexC} className="align-middle">
                                        <td>{comment.content}</td>
                                        <td className='text-center'>{comment.urlAttachment ? 
                                        <Fragment>
                                            { (spinnerShow && comment.id==downloadId )? <Spinner size='sm' animation="grow" variant="primary" />:null}
                                            <OverlayTrigger className="w-auto" placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(comment.nameAttachment)} >
                                                <Icon.Download onClick={() => downloadFile({commentableId:comment.id, tipoComentario:2, nameAttachment: comment.nameAttachment})} className="m-1 fs-4" color="Cyan"/>
                                            </OverlayTrigger>
                                        </Fragment>
                                        :
                                            comment.link ? 
                                            <Fragment>
                                                <OverlayTrigger className="w-auto" placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(comment.nameAttachment)} >
                                                    <a href={comment.link} target="_blank"><Icon.Download className="m-1 fs-4" color="Cyan"/> </a>
                                                </OverlayTrigger>
                                            </Fragment>
                                            :null
                                        }
                                        
                                        </td>
                                        <td className='text-muted text-sm text-center'>{comentador(comment.creator_Id)}</td>
                                        <td className='text-muted text-sm text-center'>{comment.create_At? dateFormat(comment.create_At,"dd-mmm-yyyy"):""} </td>
                                    </tr>
                                    ))}</tbody>
                                </Table>
                                : <Alert variant='light' className='pb-0 mb-2'> Sin Comentarios </Alert> }
                                <ComentMovementForm IdMovement={_Mov.movement.id}/>
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
                </ListGroup>
            </Card>
        </>:"null"}
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={4000} autohide>
        <Toast.Header>
            <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            />
            <strong className="me-auto">Atencion!</strong>
        </Toast.Header>
        <Toast.Body className='text-white'>
            {messageToast}
        </Toast.Body>
        </Toast>
</Fragment>
  )
}