import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const GestionTareasPage = () => {
    return (
        <Fragment>
                <div className="container-fluid px-lg-1 px-xl-3">
                    <div className="page-breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to="/inicio">Inicio</NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to="/gestion">Gestión</NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to="/gestion/tareas">Tareas</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="page-header">
                        <h1 className="page-heading">Tareas</h1>
                    </div>
                    <section>
                        <div className="card mb-4">
                            <div className="card-header">
                                <div className="card-heading">Página de inicio - Tareas</div>
                            </div>
                            <div className="card-body text-muted">
                                
                            </div>
                        </div>                        
                    </section>
                </div>
        </Fragment>
    );
}

export default GestionTareasPage;
