import axios from 'axios'
import Cookies from 'universal-cookie'

import { CleanJsonData, ClearCookies } from '../Utilities/Utilities.js'
import { type } from '@testing-library/user-event/dist/type/index.js'

//const
const data = {
    ListTasksUser: { success: false, message: null, list: [] },
    LoaderListTasksUser: false,
    ListTasksUserFilter: { success: false, message: null, list: [] },
    LoaderListTasksUserFilter: false,
}

const GET_LIST_TASKS_USER = 'GET_LIST_TASKS_USER'
const GET_LIST_TASKS_USER_FILTER = 'GET_LIST_TASKS_USER_FILTER'

const LOADER_LIST_TASK = 'LOADER_LIST_TASK'
const LOADER_LIST_TASK_FILTER = 'LOADER_LIST_TASK_FILTER'

// reducer
export default function tareasReducer(state = data, action) {
    switch (action.type) {
        case GET_LIST_TASKS_USER:
            return { ...state, ListTasksUser: action.payload }
        case GET_LIST_TASKS_USER_FILTER:
            return { ...state, ListTasksUserFilter: action.payload }
        case LOADER_LIST_TASK:
            return { ...state, LoaderListTasksUser: action.payload }
        case LOADER_LIST_TASK_FILTER:
            return { ...state, LoaderListTasksUserFilter: action.payload }
        default:
            return state
    }
}


export const GetListTasksUser = (TYPE, USERID, DATA) => async (dispatch, getState) => {

    const cookies = new Cookies()
    DATA = CleanJsonData(DATA)
    if (TYPE == "principal") {
        dispatch({
            type: GET_LIST_TASKS_USER,
            payload: { success: false, message: window.messagesAxios.getListTasksUserStart, list: [] }
        })
        dispatch({ type: LOADER_LIST_TASK, payload: true })
    } else if (TYPE == "filtro") {

        dispatch({
            type: GET_LIST_TASKS_USER_FILTER,
            payload: { success: false, message: window.messagesAxios.getListTasksUserStart, list: [] }
        })
        dispatch({ type: LOADER_LIST_TASK_FILTER, payload: true })
    }

    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_TASK_USER_LIST + USERID, DATA, { headers: { 'Authorization': `Bearer ${cookies.get('token')}` } })
        //console.log(RES)
        if (TYPE == "principal") {
            if (RES.status === 200) {
                dispatch({
                    type: GET_LIST_TASKS_USER,
                    payload: { success: false, message: window.messagesAxios.getListUsersSuccess, list: RES.data }
                })
            }
            else {
                dispatch({
                    type: GET_LIST_TASKS_USER,
                    payload: { success: false, message: window.messagesAxios.getListUsersError, list: [] }
                })
            }
            dispatch({ type: LOADER_LIST_TASK, payload: false })
        } else if (TYPE == "filtro") {
            if (RES.status === 200) {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: window.messagesAxios.getListUsersSuccess, list: RES.data }
                })
            }
            else {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: window.messagesAxios.getListUsersError, list: [] }
                })
            }
            dispatch({ type: LOADER_LIST_TASK_FILTER, payload: false })
        }

    } catch (error) {
        if (TYPE == "principal") {
            if (error.response.status === 404) {
                dispatch({
                    type: GET_LIST_TASKS_USER,
                    payload: { success: false, message: error.response.data, list: [] }
                })
            }
            else {
                dispatch({
                    type: GET_LIST_TASKS_USER,
                    payload: { success: false, message: window.messagesAxios.getListUsersError, user: null }
                })
            }
            dispatch({ type: LOADER_LIST_TASK, payload: false })
        } else if (TYPE == "filtro") {
            if (error.response.status === 404) {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: error.response.data, list: [] }
                })
            }
            else {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: window.messagesAxios.getListUsersError, user: null }
                })
            }
            dispatch({ type: LOADER_LIST_TASK_FILTER, payload: false })
        }



    }
}