// transcibe axios errors to react-hook-form
function display_errors(error, getValues, setError) {
  const errs = error.response.data || []
  var got_a_useful_message = false
  // loop over all the fields and set error:  
  for (var field of Object.keys(getValues())) {
    if (errs[field]) {
      got_a_useful_message = true
      setError(field, {
        type: "server",
        message: errs[field].join(' | ')
      })
    }
  }
  if (!got_a_useful_message) {
    alert('something has gone wrong')
  }
}

function CleanJsonData(DATA) {
  for (var propName in DATA)
    if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName];
  return DATA;
}

function ClearCookies(cookies) {
  const allCookies = cookies.getAll();
  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, { path: '/' });
  });
}


export { display_errors, CleanJsonData, ClearCookies }