import React, { Fragment, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Form, Modal, ModalHeader, Col, Row, ModalBody, ModalFooter } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import axios from 'axios';
import params from "../configs/apirest.json"
import Collapse from 'react-bootstrap/Collapse';


const SACClienteDetalleMovimientosPage = () => {
    const [state, setState] = useState({
        data: []
    });
    const navigate = useNavigate();

    const toVolver = (idCliente) => {
        console.log("idCliente: " + idCliente);
        navigate("/sac/cliente/" + idCliente);
    }

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const columnasMovimientos = [
        { name: "N°", selector: "id" },
        { name: "Nombre", selector: "name" },
        { name: "Rut", selector: "identifier" },
        { name: "Teléfono", selector: "telephone" },
        { name: "Correo", selector: "email" },
        { name: "Causas", selector: "legalCases" },
        { name: "Asuntos", selector: "legalMatter" }];

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <div className="page-breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <NavLink to="/sac">S.A.C.</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/sac/cliente">Cliente</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/sac/cliente">Detalle</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                            <NavLink to="/sac/cliente">Movimientos</NavLink>
                        </li>
                    </ul>
                </div>
                <section>
                    <div className="card mb-1">
                        <div className="card-header">
                            <div className="card-heading">Detalle del Cliente</div>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col style={{
                                    backgroundColor: 'grey',
                                    color: 'white'
                                }}>
                                    Identificador
                                </Col>
                                <Col className='lg-9'>
                                    123456789-0
                                </Col>
                                <Col style={{
                                    backgroundColor: 'grey',
                                    color: 'white'
                                }}>
                                    Nombre
                                </Col>
                                <Col className='lg-9'>
                                    cliente test
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{
                                    backgroundColor: 'grey',
                                    color: 'white'
                                }}>
                                    Teléfono
                                </Col>
                                <Col className='lg-9'>
                                    12345678
                                </Col>
                                <Col style={{
                                    backgroundColor: 'grey',
                                    color: 'white'
                                }}>
                                    Email
                                </Col>
                                <Col className='lg-9'>
                                    cliente@test.cl
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card mb-1">
                        <div className="card-header">
                            <div className="card-heading">Juicio N° 0001</div>
                        </div>
                        <div className="card-body text-muted">
                            <Row>
                                <Col>
                                    Código
                                </Col>
                                <Col>
                                    D-52855-2014
                                </Col>
                                <Col>
                                    Causa
                                </Col>
                                <Col>
                                    causa test
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Cliente
                                </Col>
                                <Col>
                                    Cliente test
                                </Col>
                                <Col>
                                    Etapa
                                </Col>
                                <Col>
                                    estapa test
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card mb-1">
                        <div className="card-header">
                            <div className="card-heading">Movimientos</div>
                        </div>
                        <div className="card-body text-muted">
                            <Form>
                                <div>
                                    <DataTable className='table'
                                        columns={columnasMovimientos}
                                        data={state.data}
                                        noDataComponent="No hay movimientos que cumplan el criterio de despliegue"
                                        pagination
                                        paginationComponentOptions={paginationOptions}
                                    >
                                    </DataTable>
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="card mb-4">
                        <div className="card-body text-muted">
                            <Button variant='primary' type="button" onClick={() => toVolver(1)}>Volver</Button>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default SACClienteDetalleMovimientosPage;
