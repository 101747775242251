import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { Button, Card, Form, Row, Col, Toast } from "react-bootstrap"
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { getInfoCase, putCaseUpdate } from '../Redux/CausasDucks'
import { getSponsorsAction } from '../Redux/GeneralDucks'


export default function DetalleCausa({ causa }) {

    const reducer = (state, newState)=>{ return {...state, ...newState} }
    const [causaView, setCausaView] = useReducer(reducer, causa )
    const [causaUpdate, setCausaUpdate] = useReducer(reducer, null )
   
    const { register: registerCase, handleSubmit: handleSubmitCase, control : controlUpdate } = useForm()
    
	const sponsorsList = useSelector(store => store.general.sponsors)
	const infoCase = useSelector(store => store.causas.infoCase)
	const updateCase = useSelector(store => store.causas.updateCase)
    const [tribunales, setTribunales] = useState([])
    const [groups, setGroups] = useState([])
    const [caseTypes, setCaseTypes] = useState([])
    const [caseTypesDetail, setCaseTypesDetail] = useState([])
    const [internalStates, setInternalStates] = useState([])
    const [lineaNegocios, setLineaNegocios] = useState([])
    const [procesess, setProcesess] = useState([])
    const [sponsor, setSponsor] = useState([])
    const [showToast,setShowToast]= useState(false)
    const [messageToast,setMessageToast]= useState("")
    const [infoFull,setInfoFull]= useState(false)
    
    const dispatch = useDispatch()
    
    const onUpdateCase = (event) => {
        if(causaUpdate.grupos) event.groupId = causaUpdate.grupos.value
        if(causaUpdate.tiposCausas) event.caseTypeId = causaUpdate.tiposCausas.value
        if(causaUpdate.tribunales) event.tribunalId = causaUpdate.tribunales.value
        if(causaUpdate.caseTypeDetailId) event.caseTypeDetailId = causaUpdate.caseTypeDetailId.value
        if(causaUpdate.internalStatesId) event.internalStatesId = causaUpdate.internalStatesId.value
        if(causaUpdate.lineaNegocioId) event.lineaNegocioId = causaUpdate.lineaNegocioId.value
        if(causaUpdate.procesessUpd) event.processId = causaUpdate.procesessUpd.value        
        if(causaUpdate.sponsorUpd) event.sponsorId = causaUpdate.sponsorUpd.value
        event.clientId = causaView.case.clientId
        dispatch(putCaseUpdate(event));
    } 
    useEffect(()=>{
        dispatch(getSponsorsAction())
    },[])


    useEffect(()=>{

        if(updateCase.status>0){
            setShowToast(true)
            setMessageToast(updateCase.message)
        }
    },[updateCase])

    useEffect(() => {
        if(sponsorsList.length>0)
            setSponsor(sponsorsList.map( item => { return { value: item.id , label : item.nombre }; }) )
    }, [sponsorsList])
    useEffect(()=>{
        if(causa.case.sponsorId){
            let aux = causaUpdate ? causaUpdate: {}
            aux.sponsorUpd=sponsor.find(({ value }) => value === causa.case.sponsorId)
            setCausaUpdate(aux)
        }
    },[sponsor])

    useEffect(() => { 
        if(!infoFull) return
        let aux = causaUpdate ? causaUpdate: {}
        aux.grupos = { value: causa.case.group.id , label : causa.case.group.description }
        aux.tiposCausas = { value: causa.case.caseType.id , label : causa.case.caseType.description }
        aux.code = causa.case.code? causa.case.code:""
        aux.title = causa.case.title? causa.case.title:""
        aux.defendedIdentifier = causa.case.defendedIdentifier? causa.case.defendedIdentifier:""
        aux.defendedName = causa.case.defendedName? causa.case.defendedName:""

        aux.tribunales = { value: causa.case.tribunal.id , label : causa.case.tribunal.nombre }
        if(causa.case.caseTypeDetailId) aux.caseTypeDetailId = caseTypesDetail.find(({ value }) => value === causa.case.caseTypeDetailId)
        if(causa.case.lineaNegocioId) aux.lineaNegocioId = lineaNegocios.find(({ value }) => value === causa.case.lineaNegocioId)
        if(causa.case.processId) aux.procesessUpd = procesess.find(({ value }) => value === causa.case.processId)
        setCausaUpdate(aux)
    }, [infoFull])

    useEffect(() => {
        let aux_group = [] 
         if(infoCase){
            const aux_caseTypes = infoCase.caseTypes.map( item => { return { value: item.id , label : item.description }; })
            infoCase.groups.map( item => { if(item.description.search("SAC")<0) aux_group.push({ value: item.id , label : item.description }) })
            const aux_caseTypesDetail = infoCase.caseTypesDetail.map( item => { return { value: item.id , label : item.description }; })
            const aux_internalStates = infoCase.internalStates.map( item => { return { value: item.id , label : item.description }; })
            const aux_lineaNegocios = infoCase.lineaNegocios.map( item => { return { value: item.id , label : item.descripcion }; })
            const aux_procesess = infoCase.procesess.map( item => { return { value: item.id , label : item.description }; })            
            const aux_tribunales = infoCase.tribunales.map( item => { return { value: item.id , label : item.nombre }; })
            
            setCaseTypesDetail(aux_caseTypesDetail)
            setInternalStates(aux_internalStates)
            setLineaNegocios(aux_lineaNegocios)
            setTribunales(aux_tribunales)
            setCaseTypes(aux_caseTypes)
            setProcesess(aux_procesess)
            setGroups(aux_group)
            setInfoFull(true)
        }else{
            dispatch(getInfoCase())
        }
    }, [infoCase])
   

    const changeUpdateFormTribunal = (e) =>{
        let aux = causaUpdate
        aux.tribunales=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormGrupo = (e) =>{
        let aux = causaUpdate
        aux.grupos=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormTipoCausa = (e) =>{
        let aux = causaUpdate
        aux.tiposCausas=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormCode = (e) =>{
        let aux = causaUpdate
        aux.code=e.target.value
        setCausaUpdate(aux)
    }
    const changeUpdateFormTitle = (e) =>{
        let aux = causaUpdate
        aux.title=e.target.value
        setCausaUpdate(aux)
    }
    const changeUpdateFormDefendedName = (e) =>{
        let aux = causaUpdate
        aux.defendedName=e.target.value
        setCausaUpdate(aux)
    }
    const changeUpdateFormDefendedIdentifier = (e) =>{
        let aux = causaUpdate
        aux.defendedIdentifier=e.target.value
        setCausaUpdate(aux)
    }
    const changeUpdateFormCaseTypeDetailId = (e) =>{
        let aux = causaUpdate
        aux.caseTypeDetailId=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormLineaNegocioId = (e) =>{
        let aux = causaUpdate
        aux.lineaNegocioId=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormProcesess = (e) =>{
        let aux = causaUpdate
        aux.procesessUpd=e
        setCausaUpdate(aux)
    }
    const changeUpdateFormSponsors = (e) =>{
        let aux = causaUpdate
        aux.sponsorUpd=e
        setCausaUpdate(aux)
    }
    

    return (

    <Fragment>
        <Card className="mb-4">
            <Card.Header>
                Antecedentes de la Causa
            </Card.Header>
            <Card.Body>
                {causaUpdate ? 
                <Col lg={12}>
                    <Form onSubmit={handleSubmitCase(onUpdateCase)}>
                        <Form.Control value={causaView.case.id} {...registerCase("caseId")} hidden/>
                        <Row className='pt-3'>
                            <Col lg={12}>
                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label d-flex">Grupo <div className='text-red ms-1'> obligatorio</div></Form.Label>
                                        <Controller rules={{ required: false}} control={controlUpdate} name="groupId" render={({ field: { onChange, value, name, ref } }) => (<CreatableSelect inputRef={ref} options={groups} isClearable value={causaUpdate.grupos} onChange={changeUpdateFormGrupo} /> )}/>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label d-flex">Tipo de Causa <div className='text-red ms-1'> obligatorio</div></Form.Label>
                                        <Controller name="caseTypeId" render={({ field }) => (<CreatableSelect {...field} options={caseTypes} isClearable value={ causaUpdate.tiposCausas} onChange={changeUpdateFormTipoCausa}/>)} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Rol</Form.Label>
                                        <Form.Control type="text" placeholder="Rol Causa" className="form-control" value={causaUpdate.code} {...registerCase("code", {onChange: changeUpdateFormCode})}/>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Titulo</Form.Label>
                                        <Form.Control type="text" placeholder="Titulo Causa" className="form-control" value={causaUpdate.title} {...registerCase("title", {onChange: changeUpdateFormTitle})}/>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Nombre Defendido</Form.Label>
                                        <Form.Control type="text" placeholder="Nombre Defendido" className="form-control" value={causaUpdate.defendedName} {...registerCase("defendedName", {onChange: changeUpdateFormDefendedName})}/>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Rut Defendido</Form.Label>
                                        <Form.Control type="text" placeholder="Rut Defendido" className="form-control" value={causaUpdate.defendedIdentifier} {...registerCase("defendedIdentifier", {onChange: changeUpdateFormDefendedIdentifier})}/>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Tribunal</Form.Label>
                                        <Controller  name="tribunalId"  control={controlUpdate}  rules={{ required: false }}  render={({ field }) => ( <CreatableSelect {...field} options={tribunales} isClearable value={causaUpdate.tribunales} onChange={changeUpdateFormTribunal}/> )}/>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Detalle Tipo de Causa</Form.Label>
                                        <Controller name="caseTypeDetailId" render={({ field }) => (<CreatableSelect {...field} options={caseTypesDetail} isClearable value={causaUpdate.caseTypeDetailId} onChange={changeUpdateFormCaseTypeDetailId} /> )} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>
                                <Row className="mb-2" hidden>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Estado Interno Inicial</Form.Label>
                                        <Controller name="internalStatesId" render={({ field }) => (<CreatableSelect {...field} options={internalStates} isClearable /> )} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Linea Negocios</Form.Label>
                                        <Controller name="lineaNegocioId" render={({ field }) => (<CreatableSelect {...field} options={lineaNegocios} isClearable value={causaUpdate.lineaNegocioId} onChange={changeUpdateFormLineaNegocioId}   /> )} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Tipo de Procedimiento</Form.Label>
                                        <Controller name="processId" render={({ field }) => (<CreatableSelect {...field} options={procesess} isClearable value={causaUpdate.procesessUpd} onChange={changeUpdateFormProcesess} /> )} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Patrocinante</Form.Label>
                                        <Controller name="processId" render={({ field }) => (<CreatableSelect {...field} options={sponsor} isClearable value={causaUpdate.sponsorUpd} onChange={changeUpdateFormSponsors} /> )} control={controlUpdate} rules={{ required: false }} />
                                    </Col>
                                </Row>

                                <Button className="mt-3 mb-3" variant="success" type="submit"> Guardar Causa</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                :null}
            </Card.Body>
        </Card>
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed top-0 end-0 mt-3 me-3' style={{zIndex: "10000"}} delay={4000} autohide>
                <Toast.Header>
                    <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                    />
                    <strong className="me-auto">Atencion!</strong>
                </Toast.Header>
                <Toast.Body> {messageToast} </Toast.Body>
            </Toast>
    </Fragment>
    )
}