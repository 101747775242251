import React, { Fragment, useState, useEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Form, Col, Row, Collapse, Button, Alert, Spinner, InputGroup } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { NavLink, useNavigate } from 'react-router-dom'
import tableCustomStyles from '../components/TableStyle'
import CreatableSelect from 'react-select/creatable'

import { useDispatch, useSelector } from 'react-redux'
import { getInfoCase, getListUsers } from '../Redux/CausasDucks'
import { SearchEstadoDiarioAction} from '../Redux/EstadoDiarioDucks'
import { useForm, Controller } from 'react-hook-form'

import { JsonToExcel } from "react-json-to-excel"


const PortafolioEstadoDiarioPage = () => {
	const { register, handleSubmit, control, reset} = useForm()
    const dispatch = useDispatch()
    
    const [grupo, setGrupo] = useState([])
    const [tribunales, setTribunales] = useState([])
    const [grupoTribunales, setGrupoTribunales] = useState([])
    const [usersGeneral,setUsersGeneral]= useState([])
    const [causasXls, setCausasXls] = useState([])
    const [itemFormulario, setItemFormulario] = useState(false)
    const [groupId, setGroupId] = useState(null) // Estado para almacenar el valor seleccionado
    

	const estadoDiario = useSelector(store => store.estadoDiario.estadoDiario)
	const infoCase = useSelector(store => store.causas.infoCase)
	const usuarios = useSelector(store => store.causas.usuarios)
	const estadoDiarioBusqueda = useSelector(store => store.estadoDiario.estadoDiarioBusqueda)

	const onSubmitFilter = (event) => {
        event.groupId =groupId.value? groupId.value:null
        event.grupoTribunalId = event.grupoTribunalId? event.grupoTribunalId.value:null
        event.tribunalId = event.tribunalId? event.tribunalId.value:null
        event.userId = event.userId? event.userId.value:null
        dispatch(SearchEstadoDiarioAction(event))
    }

 
    useEffect(() => { dispatch(getListUsers()) }, [])
    
    
    useEffect(() => { 
        let gen = [] 
        usuarios.map( item => { 
          if(item.active) gen.push({ value: item.id , label : item.nombre })
        })
        setUsersGeneral(gen)
    }, [usuarios])



    useEffect(() => {
        if(infoCase){
            const aux = infoCase.groups.map( item => { return { value: item.id , label : item.description }; })
            setGrupo(aux)
            const tri = infoCase.tribunales.map( item => { return { value: item.id , label : item.nombre }; })
            setTribunales(tri)
            const gtri = infoCase.grupoTribunales.map( item => { return { value: item.id , label : item.name }; })
            setGrupoTribunales(gtri)
        }
     }, [infoCase])
    
    useEffect(() => { dispatch(getInfoCase()); },[])

    useEffect(() => {
        if (estadoDiario.length > 0) {
            let aux = estadoDiario.map(causa => ({
                ...causa,
                telephone: causa.client.telephone,
                identifier: causa.client.identifier,
                client: causa.client.name
            }))
            setCausasXls(aux)
        }
     },[estadoDiario])



    const columnasCausas = [
        {name: "Id", cell: (row) => 
         <NavLink className="button btn-primary p-2 rounded" to={"/portafolio/causas/"+row.id} end><Icon.JournalBookmark/> {row.id}</NavLink>,
         ignoreRowClick: true,
         button: true },
        { name: "Código", selector: "code" },
        {name: "Causa", cell: (row) => <Fragment> {row.title} </Fragment>},
        {name: "Juzgado", cell: (row) => <Fragment> {row.juzgado} </Fragment>},
        {name: "Cliente", cell: (row) => <Fragment> {row.client.name}<br/> {row.client.identifier}<br/> {row.client.telephone} </Fragment>},
        {name: "SAC", cell: (row) => <Fragment> {row.client.sac}</Fragment>},
    ];

    const paginationOptions = {
        rowsPerPageText: 'Mostrar por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleSwitch = (e) => {
        reset({
            grupoTribunalId: null, // Cambia por el nombre correcto del campo
            tribunalId: null // Cambia por el nombre correcto del campo
        })
        setItemFormulario( e.target.checked)
    } 

    // Función para manejar el cambio de `groupId`
    const handleGroupIdChange = (newValue) => {
        setGroupId(newValue); // Actualizar el estado con el nuevo valor seleccionado
    };

    return (
        <Fragment>
           
            <div className="container-fluid px-lg-1 px-xl-3">
                <section>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className='box'>
                                <div className='row row-cols-md-auto g-10 align-items-center'>
                                    <div className="col-md-10">
                                        <h2 className="page-heading">Estado Diario (Roleo)</h2>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-9 pt-4">
                                    <Form onSubmit={handleSubmit(onSubmitFilter)}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column sm="2"> Fecha </Form.Label>
                                                    <Col sm="3"> <Form.Control type="date" className="form-control" {...register("fechaBusqueda")}/> </Col>
                                                    <Form.Label column sm="2"> Grupo </Form.Label>
                                                    <Col sm="3"> 
                                                    <CreatableSelect value={groupId} onChange={handleGroupIdChange} options={grupo} isClearable />
                                                    {/*<Controller name="groupId" render={({ field }) => (<CreatableSelect {...field} options={grupo} isClearable /> )} control={control} rules={{ required: false }} /> */}
                                                    </Col>
                                                    
                                                </Form.Group>
                                                
                                                <Form.Group as={Row} className="mb-2 mt-4">
                                                    
                                                    <Form.Label column sm="2"></Form.Label>
                                                    <Col sm="3">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        label="Buscar Por"
                                                        onChange={handleSwitch}
                                                    />
                                                    </Col>

                                                    {itemFormulario ? 
                                                    <Fragment>
                                                        <Form.Label column sm="2"> Grupo Tribunales</Form.Label>
                                                        <Col sm="3"> <Controller name="grupoTribunalId" render={({ field }) => (<CreatableSelect {...field} options={grupoTribunales} isClearable /> )} control={control} rules={{ required: false }} /> </Col>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <Form.Label column sm="2"> Tribunal </Form.Label>
                                                        <Col sm="3"> <Controller name="tribunalId" render={({ field }) => (<CreatableSelect {...field} options={tribunales} isClearable /> )} control={control} rules={{ required: false }} /> </Col>
                                                    </Fragment>
                                                    }
                                                </Form.Group>
                                                {/*<Form.Group as={Row} className="mb-2">
                                                    <Form.Label column sm="2"> Usuarios de la Causa </Form.Label>
                                                    <Col sm="3"> <Controller name="userId" render={({ field }) => (<CreatableSelect {...field} options={usersGeneral} isClearable/>)} rules={{ required: false }} control={control} /> </Col>
                                                </Form.Group>*/}
                                                <Form.Group as={Row} className="mb-2 mt-4">
                                                    <Form.Label column sm="2"> </Form.Label>
                                                    <Col sm="3"></Col>
                                                    <Form.Label column sm="2"> </Form.Label>
                                                    <Col sm="3"> <Button className='w-100' type="submit" variant="primary"><Icon.Search/></Button> </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <div className="card-body text-muted">
                            {estadoDiario.length>0 ?  
                                <Fragment>
                                    <div className="col-3 ">
                                        <JsonToExcel
                                            btnClassName="btn btn-outline-primary border border-primary text-info btn-sm"
                                            title={"Descargar"}
                                            data={causasXls}
                                            fileName="EstadoDiario_Roleo"
                                            btnColor={null}
                                        /> 
                                    </div>
                                    <Form>
                                        <div>
                                            <DataTable className='table'
                                                title="Causas"
                                                columns={columnasCausas}
                                                customStyles={tableCustomStyles}
                                                data={estadoDiario}
                                                pagination
                                                paginationComponentOptions={paginationOptions}/>
                                        </div>
                                    </Form>
                                </Fragment>
                                :
                                estadoDiarioBusqueda.status!=0 ? <Alert variant='light' className='pb-0 mb-2 fs-4'> {estadoDiarioBusqueda.status==1? <Spinner animation="grow" variant="primary" />:null} {estadoDiarioBusqueda.message} </Alert>: null
                            }
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default PortafolioEstadoDiarioPage;
