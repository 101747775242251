import React, { useState, useEffect } from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Button, Form, InputGroup, Spinner, ToastContainer, Toast } from "react-bootstrap"
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import dateFormat from "dateformat"
import { useDispatch, useSelector } from 'react-redux'
import { getUpdateTarea } from '../Redux/CausasDucks'

export default function TareaUpdateDeadLine({detalleCausa, task}) {
    const { register: register, handleSubmit: handleSubmit} = useForm()
    const [deadLine, setDeadLine] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [taskIdUpdate, setTaskIdUpdate] = useState(0);
    const [spinner, setSpinner] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const dispatch = useDispatch()

	const updateTask = useSelector(store => store.causas.updateTask)
	const messageDeadLine = useSelector(store => store.causas.messageDeadLine)

    useEffect(() => {  
        setSpinner(<Spinner animation="grow" variant="primary" />)

        setDeadLine(task.deadlineTime ? dateFormat(task.deadlineTime, "yyyy-mm-dd"):'')
    }, [task]);

    const onSubmitUpdateTask = (event) => {
        dispatch(getUpdateTarea(event))
        setTaskIdUpdate(event.taskId)
    }
    useEffect(() => { 
        if(taskIdUpdate == task.id)
            setShowSpinner(updateTask) 
    }, [updateTask]);
    useEffect(() => { 
        if(taskIdUpdate == task.id && messageDeadLine){
            setShowToast(true)
            setTaskIdUpdate(0)
        }
    }, [messageDeadLine]);
    
    return (<Fragment>
        <Form onSubmit={handleSubmit(onSubmitUpdateTask)}  className='d-flex justify-content-end'>
            <Form.Group className="mb-3 w-auto">
                <InputGroup>
                <InputGroup.Text id="basic-addon1">F Limite {showSpinner ? spinner: null}</InputGroup.Text>
                <input className='border' type="date" {...register('deadlineTime', {onChange: (e) => {setDeadLine(e.target.value)} })}  min={new Date().toISOString().split("T")[0]} max="9999-12-31" value={deadLine?deadLine:''} />
                <input value={task.id}  {...register("taskId")} hidden/>
                <Button variant="outline-primary" type='submit'> <Icon.Send/> </Button>
                </InputGroup>
            </Form.Group>
        </Form>
        <Toast show={showToast} onClose={(e)=> setShowToast(false)} bg="info"  className='position-fixed bottom-0 end-0' style={{zIndex: "12"}} delay={3000} autohide>
            
            <Toast.Header>
                <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
                />
                <strong className="me-auto">Atencion!</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>
                {messageDeadLine}
            </Toast.Body>
        </Toast>
    </Fragment>)
}