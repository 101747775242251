import axios from 'axios'
import Cookies from 'universal-cookie'
// constantes

const data = {
    listDocument: [],
    newDocument: null,
    documentStatus: {status: 0, msg:""}
}

const GET_INFO_LIST_DOCUMENT_SUCCESS = 'GET_INFO_LIST_DOCUMENT_SUCCESS'
const SET_NEW_DOCUMENT_SUCCESS = 'SET_NEW_DOCUMENT_SUCCESS'
const GET_NEW_DOCUMENT_SUCCESS = 'GET_NEW_DOCUMENT_SUCCESS'

// reducer
export default function movementReducer(state = data, action){
    switch(action.type){
        case GET_INFO_LIST_DOCUMENT_SUCCESS:
            return {...state, listDocument: action.payload}
        case SET_NEW_DOCUMENT_SUCCESS:
            return {...state, newDocument: action.payload}
        case GET_NEW_DOCUMENT_SUCCESS:
            return {...state, documentStatus: action.payload}
        default:
            return state
    }
}

export const SendNewDocument = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA) 
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies()
    DATA.userId = cookies.get('user_id')

    dispatch({
        type: GET_NEW_DOCUMENT_SUCCESS,
        payload: {status: 1, msg:"Generando documento, espere hasta su descarga"}
    })


    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_NEW_DOCUMENT,DATA)
        //console.log(RES)
        if(RES.status === 200 ){
            console.log(RES)
            dispatch({
                type: GET_NEW_DOCUMENT_SUCCESS,
                payload: {status: 2, msg:"Documento Creado, Descargando"}
            })

            ////////////////////
            window.archivo = RES

        // Convierte la cadena base64 en un Blob
        const byteCharacters = atob(RES.data.fileContents);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: RES.data.contentType });

        // Crea un URL a partir del Blob
        const url = URL.createObjectURL(blob);

        // Crea un enlace y haz click en él
        const link = document.createElement('a');
        link.href = url;
        link.download = RES.data.fileDownloadName;
        link.click();

        //////////////////////////




            
            dispatch({
                type: SET_NEW_DOCUMENT_SUCCESS,
                payload: RES.data
            })
        }else{
            dispatch({
                type: GET_NEW_DOCUMENT_SUCCESS,
                payload: {status: 3, msg:"Error en Generar el documento"}
            })
        }
        setTimeout(() => {
            dispatch({
                type: GET_NEW_DOCUMENT_SUCCESS,
                payload: {status: 0, msg:""}
            })
        }, 5000)

    } catch (error) {
        console.log(error)
        dispatch({
            type: GET_NEW_DOCUMENT_SUCCESS,
            payload: {status: 3, msg:"Error en Generar el documento"}
        })
        setTimeout(() => {
            dispatch({
                type: GET_NEW_DOCUMENT_SUCCESS,
                payload: {status: 0, msg:""}
            })
        }, 5000)
    }
}

// Parametros para la causa
export const GetListDocument = (DATA) => async (dispatch, getState) => {

    try {
        const RES = await axios.get(window.API_LIST_DOCUMENT+"?caseId="+DATA.caseId)
        //console.log(RES)
        if(RES.status === 200 ){
            dispatch({
                type: GET_NEW_DOCUMENT_SUCCESS,
                payload: RES.data
            })
            dispatch({
                type: GET_INFO_LIST_DOCUMENT_SUCCESS,
                payload: RES.data
            })
        }else{
            console.log(RES)
        }

    } catch (error) {
        console.log(error)
    }
}