import axios from 'axios'
import { X } from 'react-bootstrap-icons'
import Cookies from 'universal-cookie'
// constantes

const data = {
    estadoDiario: [],
    NewClient: [],
    estadoDiarioBusqueda: {status: 0, message:null},
}

const GET_ESTADO_DIARIO_SUCCESS = 'GET_ESTADO_DIARIO_SUCCESS'
const GET_ESTADO_DIARIO_BUSQUEDA = 'GET_ESTADO_DIARIO_BUSQUEDA'

// reducer
export default function estadioDiariosReducer(state = data, action){
    switch(action.type){
        case GET_ESTADO_DIARIO_SUCCESS:
            return {...state, estadoDiario: action.payload}        
        case GET_ESTADO_DIARIO_BUSQUEDA:
            return {...state, estadoDiarioBusqueda: action.payload}        
        default:
            return state
    }
}


//  para la causa
export const SearchEstadoDiarioAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA) 
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies()
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: GET_ESTADO_DIARIO_SUCCESS,
        payload: []
    })
    dispatch({
        type: GET_ESTADO_DIARIO_BUSQUEDA,
        payload:  {status: 1, message:"Buscando Estado Diario, esto puede demorar, espere por favor..."}
    })

    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_ESTADO_DIARIO,DATA)
        //console.log(RES)
        if(RES.status === 200 ){
            dispatch({
                type: GET_ESTADO_DIARIO_SUCCESS,
                payload: RES.data
            })
            if(RES.data.length>0){
                dispatch({
                    type: GET_ESTADO_DIARIO_BUSQUEDA,
                    payload:  {status: 2, message:"Estados Diarios Encontradas"}
                })
                setTimeout(() => {
                    dispatch({
                        type: GET_ESTADO_DIARIO_BUSQUEDA,
                        payload:  {status: 0, message:null}
                    })
                }, 3000);
            }else{
                dispatch({
                    type: GET_ESTADO_DIARIO_BUSQUEDA,
                    payload:  {status: 3, message:"Busqueda no Encontró Estados Diarios"}
                })
            }

        }else{
            dispatch({
                type: GET_ESTADO_DIARIO_BUSQUEDA,
                payload:  {status: 4, message:"No se pudo Buscar Estados Diarios"}
            })
        }

    } catch (error) {
        dispatch({
            type: GET_ESTADO_DIARIO_BUSQUEDA,
            payload:  {status: 4, message:"Error al Buscar Estados Diarios"}
        })
    }
}



/*
// Parametros para la causa
export const GetInfoClient = () => async (dispatch, getState) => {
    
    try { // nombre rut telefono direccion
        const RES = await axios.get(window.API_INFO_CLIENT)
        //console.log(RES)
        if(RES.status === 200 ){

            let branchOffices = []
            let countries = []
            let sacUsers = []
            let regions= []
            RES.data.branchOffices.map(item => { 
                branchOffices.push({ value: item.id, label: item.description, code:item.abreviatura })
            })
            RES.data.countries.map(item => { 
                countries.push({ value: item.id, label: item.description})
            })
            RES.data.sacUsers.map(item => { 
                sacUsers.push({ value: item.id, label: item.email})
            })
            RES.data.regions.map(item => { 
                regions.push({ value: item.id, label: item.name})
            })
            

            RES.data.branchOffices = branchOffices
            RES.data.countries = countries
            RES.data.sacUsers = sacUsers
            RES.data.regions = regions
            
            dispatch({
                type: GET_INFO_CLIENT_SUCCESS,
                payload: RES.data
            })
        }else{
            console.log(RES)
        }

    } catch (error) {
        console.log(error)
    }
}*/