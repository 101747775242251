import axios from 'axios'
import { Alert } from 'react-bootstrap'
import Cookies from 'universal-cookie'
// constantes


const data = {
    informacionSistema: [],
    causas: [],
    novedades: [],
    novedadesBusqueda: { status: 0, message: null },
    causasBusqueda: { status: 0, message: null },
    clientesBusqueda: { status: 0, message: null },
    clientes: [],
    tareas_usuario: [],
    ListTasksUserFilter: { success: false, message: null, list: [] },
    LoaderListTasksUser: false,
    LoaderListTasksUserFilter: false,
    detalleCausa: null,
    usuarios: [],
    newTask: null,
    newMovement: null,
    updateTask: false,
    messageUpdateTask: null,
    taskUpdate: null,
    sendNewTask: false,
    sendNewMovement: false,
    messageSendNewTask: null,
    messageSendNewMovement: null,
    likeTask: {
        show: false,
        message: null,
        taskId: 0,
        statusId: null
    },
    commentTask: {
        show: false,
        message: null,
        taskId: 0,
        comment: null
    },
    commentMovement: {
        show: false,
        message: null,
        movementId: 0,
        comment: null
    },
    infoCase: null,
    startDownload: { start: false, message: null },
    sendNewCase: false,
    messageSendNewCase: null,
    newCase: null,
    updateCase: { status: 0, message: null },
    updateClient: { status: 0, message: null, client: null },
    removeTaskUser: { status: 0, message: null },
    actualizaciones: [],
}

// types

const GET_UPDATES_SUCCESS = 'GET_UPDATES_SUCCESS'
const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
const GET_CAUSA_DETAIL_SUCCESS = 'GET_CAUSA_DETAIL_SUCCESS'
const GET_TASK_USER_SUCCESS = 'GET_TASK_USER_SUCCESS'
const GET_LIST_TASKS_USER_FILTER = 'GET_LIST_TASKS_USER_FILTER'
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'
const GET_REPORT_NOVEDADES_SUCCESS = 'GET_REPORT_NOVEDADES_SUCCESS'
const GET_NEW_TASK_SUCCESS = 'GET_NEW_TASK_SUCCESS'
const GET_NEW_MOVEMENT_SUCCESS = 'GET_NEW_MOVEMENT_SUCCESS'
const GET_UPDATE_DEADLINE_SUCCESS = 'GET_UPDATE_DEADLINE_SUCCESS'
const SENDING_NEW_TASK = 'SENDING_NEW_TASK'
const UPDATE_LIKE_TASK = 'UPDATE_LIKE_TASK'
const ADD_COMMENT_TASK = 'ADD_COMMENT_TASK'
const ADD_COMMENT_MOVEMENT = 'ADD_COMMENT_MOVEMENT'
const SENDING_NEW_MOVEMENT = 'SENDING_NEW_MOVEMENT'
const GET_INFO_CASE_SUCCESS = 'GET_INFO_CASE_SUCCESS'
const SET_START_DOWNLOAD_FILE = 'SET_START_DOWNLOAD_FILE'
const GET_NEW_CASE_SUCCESS = 'GET_NEW_CASE_SUCCESS'
const SENDING_NEW_CASE = 'SENDING_NEW_CASE'
const PUT_SUCCESS_UPDATE_CASE = 'PUT_SUCCESS_UPDATE_CASE'
const SEARCH_NOVEDADES = 'SEARCH_NOVEDADES'
const SEARCH_CASE = 'SEARCH_CASE'
const SEARCH_CLIENTS = 'SEARCH_CLIENTS'
const PUT_SUCCESS_UPDATE_CLIENT = 'PUT_SUCCESS_UPDATE_CLIENT'
const GET_INFORMACION_SISTEMA = 'GET_INFORMACION_SISTEMA'
const GET_DELETE_TASK_USER = 'GET_DELETE_TASK_USER'

const LOADER_LIST_TASK = 'LOADER_LIST_TASK'
const LOADER_LIST_TASK_FILTER = 'LOADER_LIST_TASK_FILTER'

// reducer
export default function causasReducer(state = data, action) {
    switch (action.type) {
        case GET_CLIENTS_SUCCESS:
            return { ...state, clientes: action.payload }
        case SEARCH_CLIENTS:
            return { ...state, clientesBusqueda: action.payload }
        case GET_CAUSA_DETAIL_SUCCESS:
            return { ...state, detalleCausa: action.payload }
        case GET_REPORT_SUCCESS:
            return { ...state, causas: action.payload }
        case GET_REPORT_NOVEDADES_SUCCESS:
            return { ...state, novedades: action.payload }
        case GET_TASK_USER_SUCCESS:
            return { ...state, tareas_usuario: action.payload }
        case GET_LIST_TASKS_USER_FILTER:
            return { ...state, ListTasksUserFilter: action.payload }
        case LOADER_LIST_TASK:
            return { ...state, LoaderListTasksUser: action.payload }
        case LOADER_LIST_TASK_FILTER:
            return { ...state, LoaderListTasksUserFilter: action.payload }
        case GET_USER_LIST_SUCCESS:
            return { ...state, usuarios: action.payload }
        case GET_NEW_TASK_SUCCESS:
            return { ...state, newTask: action.payload }
        case GET_UPDATE_DEADLINE_SUCCESS:
            return { ...state, updateTask: action.payload.updateTask, messageUpdateTask: action.payload.messageUpdateTask, taskUpdate: action.payload.taskUpdate }
        case SENDING_NEW_TASK:
            return { ...state, sendNewTask: action.payload.sendNewTask, messageSendNewTask: action.payload.messageSendNewTask }
        case UPDATE_LIKE_TASK:
            return { ...state, likeTask: action.payload }
        case ADD_COMMENT_TASK:
            return { ...state, commentTask: action.payload }
        case ADD_COMMENT_MOVEMENT:
            return { ...state, commentMovement: action.payload }
        case SENDING_NEW_MOVEMENT:
            return { ...state, sendNewMovement: action.payload.sendNewMovement, messageSendNewMovement: action.payload.messageSendNewMovement }
        case GET_NEW_MOVEMENT_SUCCESS:
            return { ...state, newMovement: action.payload }
        case GET_INFO_CASE_SUCCESS:
            return { ...state, infoCase: action.payload }
        case SET_START_DOWNLOAD_FILE:
            return { ...state, startDownload: action.payload }
        case SENDING_NEW_CASE:
            return { ...state, sendNewCase: action.payload.sendNewCase, messageSendNewCase: action.payload.messageSendNewCase }
        case GET_NEW_CASE_SUCCESS:
            return { ...state, newCase: action.payload }
        case PUT_SUCCESS_UPDATE_CASE:
            return { ...state, updateCase: action.payload }
        case SEARCH_NOVEDADES:
            return { ...state, novedadesBusqueda: action.payload }
        case SEARCH_CASE:
            return { ...state, causasBusqueda: action.payload }
        case PUT_SUCCESS_UPDATE_CLIENT:
            return { ...state, updateClient: action.payload }
        case GET_INFORMACION_SISTEMA:
            return { ...state, informacionSistema: action.payload }
        case GET_UPDATES_SUCCESS:
            return { ...state, actualizaciones: action.payload }
        case GET_DELETE_TASK_USER:
            return { ...state, removeTaskUser: action.payload }
        default:
            return state
    }
}


export const CreateNewCase = (DATA, causa) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: SENDING_NEW_CASE,
        payload: { sendNewCase: true, messageSendNewCase: null }
    })

    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_NEW_CASE, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: SENDING_NEW_CASE,
                payload: { sendNewCase: false, messageSendNewCase: "CAUSA CREADA, redireccionando" }
            })

            dispatch({
                type: GET_NEW_CASE_SUCCESS,
                payload: RES.data
            })

        } else {
            dispatch({
                type: SENDING_NEW_CASE,
                payload: { sendNewCase: false, messageSendNewCase: "CAUSA NO PUDO SER CREADA" }
            })
        }

        setTimeout(() => {
            dispatch({
                type: GET_NEW_CASE_SUCCESS,
                payload: null
            })

            dispatch({
                type: SENDING_NEW_CASE,
                payload: { sendNewCase: false, messageSendNewCase: null }
            })
        }, 1500)
        /*
          setTimeout(() => {
        alert("xd")
        dispatch({
            type: SENDING_NEW_CASE,
            payload: {sendNewTask:false, messageSendNewTask: null}
        })
    }, 1500)
    return
        */

    } catch (error) {
        alert("error")
        console.log(error)
    }
}

// Parametros para la causa
export const getInfoCase = () => async (dispatch, getState) => {

    try { // nombre rut telefono direccion
        const RES = await axios.get(window.API_INFO_CASE)
        //console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_INFO_CASE_SUCCESS,
                payload: RES.data
            })
        } else {
            console.log("error")
            console.log(RES)
        }

    } catch (error) {
        console.log(error)
    }
}

// 
export const addCommentMovement = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]
    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: ADD_COMMENT_MOVEMENT,
        payload: {
            show: true,
            message: null,
            movementId: DATA.commentableId,
            comment: null
        }
    })

    if (DATA.file) {
        const toBase64 = (theFile) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(theFile)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        var FileBase64 = await toBase64(DATA.file)
        console.log("toBase64")
        console.log(toBase64)
        FileBase64 = FileBase64.split("base64,")

        var fname = (DATA.file.name).replaceAll("'", "");
        fname = fname.replaceAll('Ñ', "n");
        fname = fname.replaceAll('ñ', "n");
        fname = fname.replaceAll('á', "a");
        fname = fname.replaceAll('é', "e");
        fname = fname.replaceAll('í', "i");
        fname = fname.replaceAll('ó', "o");
        fname = fname.replaceAll('ú', "u");
        fname = fname.replaceAll('Á', "A");
        fname = fname.replaceAll('É', "E");
        fname = fname.replaceAll('Í', "I");
        fname = fname.replaceAll('Ó', "O");
        fname = fname.replaceAll('Ú', "U");

        DATA.fileName = fname
        DATA.fileType = DATA.file.type
        DATA.fileSize = DATA.file.size
        DATA.file = FileBase64[1]
    }
    /*
        setTimeout(() => {
            dispatch({
                type: ADD_COMMENT_MOVEMENT,
                payload: {
                    show: false,
                    message: "comentario agregado, CORREGIR API",
                    movementId: DATA.commentableId,
                    comment: {
                        "movementId": 131955,
                        "content": DATA.content,
                        "urlAttachment": null,
                        "nameAttachment": null,
                        "id": 9999999,
                        "create_At": "2019-06-14T22:43:28",
                        "update_At": "2019-06-14T22:43:28",
                        "creator_Id": 2,
                        "updater_Id": 2
                    },
                }
            })
        }, 1000)
        return
    */

    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_ADD_COMMENT, DATA)

        if (RES.status === 200) {
            dispatch({
                type: ADD_COMMENT_MOVEMENT,
                payload: {
                    show: false,
                    message: "comentario agregado",
                    movementId: DATA.commentableId,
                    comment: RES.data
                }
            })

        } else {

            dispatch({
                type: ADD_COMMENT_MOVEMENT,
                payload: {
                    show: false,
                    message: "No se pudo guardar el comentario",
                    movementId: DATA.commentableId,
                    comment: null
                }
            })
        }
        setTimeout(() => {
            dispatch({
                type: ADD_COMMENT_MOVEMENT,
                payload: {
                    show: true,
                    message: null,
                    movementId: 0,
                    comment: null
                }
            })
        }, 1500)

    } catch (error) {
        console.log(error)
    }
}


export const addCommentTask = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]
    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')
    dispatch({
        type: ADD_COMMENT_TASK,
        payload: {
            show: true,
            message: null,
            taskId: DATA.commentableId,
            comment: null
        }
    })

    if (DATA.file) {
        const toBase64 = (theFile) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(theFile)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        var FileBase64 = await toBase64(DATA.file)
        console.log("toBase64")
        console.log(toBase64)
        FileBase64 = FileBase64.split("base64,")

        var fname = (DATA.file.name).replaceAll("'", "");
        fname = fname.replaceAll('Ñ', "n");
        fname = fname.replaceAll('ñ', "n");
        fname = fname.replaceAll('á', "a");
        fname = fname.replaceAll('é', "e");
        fname = fname.replaceAll('í', "i");
        fname = fname.replaceAll('ó', "o");
        fname = fname.replaceAll('ú', "u");
        fname = fname.replaceAll('Á', "A");
        fname = fname.replaceAll('É', "E");
        fname = fname.replaceAll('Í', "I");
        fname = fname.replaceAll('Ó', "O");
        fname = fname.replaceAll('Ú', "U");

        DATA.fileName = fname
        DATA.fileType = DATA.file.type
        DATA.fileSize = DATA.file.size
        DATA.file = FileBase64[1]
    }
    /*
    setTimeout(() => {
        dispatch({
            type: ADD_COMMENT_TASK,
            payload: {
                show: false,
                message: "comentario agregado, CORREGIR API",
                taskId: DATA.commentableId,
                comment: {
                    "taskId": 131955,
                    "content": DATA.content,
                    "urlAttachment": null,
                    "nameAttachment": null,
                    "id": 9999999,
                    "create_At": "2019-06-14T22:43:28",
                    "update_At": "2019-06-14T22:43:28",
                    "creator_Id": 2,
                    "updater_Id": 2
                },
            }
        })
    }, 1000)
    return
    */


    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_ADD_COMMENT, DATA)

        //console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: ADD_COMMENT_TASK,
                payload: {
                    show: false,
                    message: "comentario agregado",
                    taskId: DATA.commentableId,
                    comment: RES.data
                }
            })

        } else {
            dispatch({
                type: ADD_COMMENT_TASK,
                payload: {
                    show: false,
                    message: "No se pudo guardar el comentario",
                    taskId: DATA.commentableId,
                    comment: null
                }
            })
        }

        setTimeout(() => {
            dispatch({
                type: ADD_COMMENT_TASK,
                payload: {
                    show: false,
                    message: null,
                    taskId: 0,
                    comment: null
                }
            })
        }, 1000)

    } catch (error) {
        console.log(error)
    }
}

export const updateLikeTask = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: UPDATE_LIKE_TASK,
        payload: {
            show: true,
            message: null,
            taskId: DATA.taskId,
            statusId: null,
        }
    })

    try { // nombre rut telefono direccion
        const RES = await axios.put(window.API_LIKE_TASK_UPDATE + "taskId=" + DATA.taskId + "&statusId=" + DATA.statusId + "&updaterId=" + DATA.creatorId)
        //console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: UPDATE_LIKE_TASK,
                payload: {
                    show: false,
                    message: "tarea Actualizada",
                    taskId: DATA.taskId,
                    statusId: DATA.statusId,
                }
            })
        } else {
            dispatch({
                type: UPDATE_LIKE_TASK,
                payload: {
                    show: false,
                    message: "tarea No pudo serActualizada",
                    taskId: DATA.taskId,
                    statusId: null,
                }
            })
        }

    } catch (error) {
        console.log(error)
    }
}

/*
    lista de usuarios
*/
export const getListUsers = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_ALLUSER)
        //console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_USER_LIST_SUCCESS,
                payload: RES.data
            })
        } else {
            alert("hubo un problema, reportelo por el sistema de requerimiento a TI, por favor")
        }

    } catch (error) {
        console.log(error)
    }
}

// CREAR NUEVO MOVIMIENTO
export const getNewMovement = (DATA, causa) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]


    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    if (!DATA.creatorId) {
        alert("Error en los datos del envio, por favor cierre su sesión y refresque el sistema, ctrl+f5")
    }

    dispatch({
        type: SENDING_NEW_MOVEMENT,
        payload: { sendNewMovement: true, messageSendNewMovement: null }
    })

    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_NEW_MOVEMENT, DATA)
        console.log(RES)

        if (RES.status === 200) {
            dispatch({
                type: SENDING_NEW_MOVEMENT,
                payload: { sendNewMovement: false, messageSendNewMovement: "MOVIMIENTO CREADO" }
            })

            dispatch({
                type: GET_NEW_MOVEMENT_SUCCESS,
                payload: RES.data
            })

        } else {
            dispatch({
                type: SENDING_NEW_MOVEMENT,
                payload: { sendNewMovement: false, messageSendNewMovement: "MOVIMIENTO NO PUDO SER CREADO" }
            })
        }
        setTimeout(() => {
            dispatch({
                type: GET_NEW_MOVEMENT_SUCCESS,
                payload: null
            })

            dispatch({
                type: SENDING_NEW_MOVEMENT,
                payload: { sendNewMovement: false, messageSendNewMovement: null }
            })
        }, 1500)
    } catch (error) {
        console.log(error)
    }
}


export const getNewTask = (DATA, causa) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    if (DATA.id && DATA.deadlineTime && DATA.detalleCausa) {
        DATA.detalleCausa.taskComments = DATA.detalleCausa.taskComments.map(obj => {
            if (obj.task.id == DATA.id)
                obj.task.deadlineTime = DATA.deadlineTime + "T23:59:59"
            return obj;
        })
    }

    dispatch({
        type: SENDING_NEW_TASK,
        payload: { sendNewTask: true, messageSendNewTask: null }
    })

    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_NEW_TASK, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: SENDING_NEW_TASK,
                payload: { sendNewTask: false, messageSendNewTask: "TAREA CREADA" }
            })

            dispatch({
                type: GET_NEW_TASK_SUCCESS,
                payload: RES.data
            })

        } else {
            dispatch({
                type: SENDING_NEW_TASK,
                payload: { sendNewTask: false, messageSendNewTask: "TAREA NO PUDO SER CREADA" }
            })
        }

        setTimeout(() => {
            dispatch({
                type: GET_NEW_TASK_SUCCESS,
                payload: null
            })

            dispatch({
                type: SENDING_NEW_TASK,
                payload: { sendNewTask: false, messageSendNewTask: null }
            })
        }, 1500)
        /*
          setTimeout(() => {
        alert("xd")
        dispatch({
            type: SENDING_NEW_TASK,
            payload: {sendNewTask:false, messageSendNewTask: null}
        })
    }, 1500)
    return
        */

    } catch (error) {
        alert("error")
        console.log(error)
    }
}
/*
    lista de tareas del usuario
*/
export const getListTarea = (TYPE, USERID, DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    if (TYPE == "principal") {
        dispatch({
            type: GET_TASK_USER_SUCCESS,
            payload: []
        })
        dispatch({ type: LOADER_LIST_TASK, payload: true })
    } else if (TYPE == "filtro") {

        dispatch({
            type: GET_LIST_TASKS_USER_FILTER,
            payload: { success: false, message: "buscando", list: [] }
        })
        dispatch({ type: LOADER_LIST_TASK_FILTER, payload: true })
    }
    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_TASK_USER_LIST + USERID, DATA/*, { headers: { 'Authorization': `Bearer ${cookies.get('token')}` } }*/)
        //console.log(RES)
        if (TYPE == "principal") {
            if (RES.status === 200) {
                dispatch({
                    type: GET_TASK_USER_SUCCESS,
                    payload: RES.data
                })
            }
            else {
                dispatch({
                    type: GET_TASK_USER_SUCCESS,
                    payload: []
                })
            }
            dispatch({ type: LOADER_LIST_TASK, payload: false })
        } else if (TYPE == "filtro") {
            if (RES.status === 200) {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: "exito", list: RES.data }
                })
            }
            else {
                dispatch({
                    type: GET_LIST_TASKS_USER_FILTER,
                    payload: { success: false, message: "error", list: [] }
                })
            }
            dispatch({ type: LOADER_LIST_TASK_FILTER, payload: false })
        }

    } catch (error) {
        if (TYPE == "principal") {

            dispatch({
                type: GET_TASK_USER_SUCCESS,
                payload: []
            })
            dispatch({ type: LOADER_LIST_TASK, payload: false })
        } else if (TYPE == "filtro") {
            dispatch({
                type: GET_LIST_TASKS_USER_FILTER,
                payload: { success: false, message: error.message, list: [] }
            })
            dispatch({ type: LOADER_LIST_TASK_FILTER, payload: false })
        }
    }
    /*
  
    try { // nombre rut telefono direccion
        const RES = await axios.post(window.API_TASK_USER_LIST+DATA.idUsuario, DATA)
        //console.log(RES)
        if(RES.status === 200 ){
            dispatch({
                type: GET_TASK_USER_SUCCESS,
                payload: RES.data
            })
        }else{
            alert("hubo un problema, reportelo por el sistema de requerimiento a TI, por favor")
        }

    } catch (error) {
        console.log(error)
    }*/
}


export const getUpdateTarea = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: GET_UPDATE_DEADLINE_SUCCESS,
        payload: { updateTask: true, messageUpdateTask: null, taskUpdate: null }
    })



    if (DATA.deadlineTime)
        DATA.deadlineTime = DATA.deadlineTime + "T" + (new Date().toISOString().split("T")[1])

    try { //nombre rut telefono direccion
        const RES = await axios.put(window.API_TASK_UPDATE, DATA)
        if (DATA.deadlineTime) {
            let Message = "Fecha Limite no puede ser menor a la registrada";
            if (RES.status === 200) Message = "Fecha Limite Actualizada Correctamente, si no aparece, refresque la pagina"
            dispatch({
                type: GET_UPDATE_DEADLINE_SUCCESS,
                payload: { updateTask: false, messageUpdateTask: Message, taskUpdate: null }
            })
        }
        DATA.newUsers = DATA.newUsers.concat(DATA.newUsersCase);
        if (DATA.newUsers) {
            let Message = "Selección no pudo ser registrada";
            if (RES.status === 200) {
                Message = "Usuarios Actualizados Correctamente"
                console.log(DATA)
                dispatch({
                    type: GET_UPDATE_DEADLINE_SUCCESS,
                    payload: { updateTask: false, messageUpdateTask: Message, taskUpdate: DATA }
                })
            } else {
                dispatch({
                    type: GET_UPDATE_DEADLINE_SUCCESS,
                    payload: { updateTask: false, messageUpdateTask: Message, taskUpdate: null }
                })
            }
        } else {
            alert("upa")
        }
    } catch (error) {
        console.log(error)
    }
}

/*
    portafolio/causas/IDCAUSA
*/
export const getCaseDetailAction = (DATA) => async (dispatch, getState) => {

    for (var propName in DATA) {
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]
    }

    dispatch({
        type: GET_CAUSA_DETAIL_SUCCESS,
        payload: null
    })

    console.log(DATA)

    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_GET_CASE + DATA)
        //console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_CAUSA_DETAIL_SUCCESS,
                payload: RES.data
            })
        } else {
            alert("hubo un problema, reportelo por el sistema de requerimiento a TI, por favor")
        }

    } catch (error) {
        console.log(error)
    }
}

//Busqueda Avanzada
export const getAdminClientsAdvancedAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    dispatch({
        type: SEARCH_CLIENTS,
        payload: { status: 1, message: "Buscando Clientes" }
    })


    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_SEARCH_CLIENT_ADVANCE, DATA)
        if (RES.status === 200) {
            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: RES.data
            })

            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_CLIENTS,
                    payload: { status: 2, message: "Clientes Encontrados" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_CLIENTS,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_CLIENTS,
                    payload: { status: 3, message: "Busqueda no Encontró Clientes" }
                })
            }
        } else {
            dispatch({
                type: SEARCH_CLIENTS,
                payload: { status: 4, message: "Error al Buscar Clientes" }
            })
        }

    } catch (error) {
        dispatch({
            type: SEARCH_CLIENTS,
            payload: { status: 3, message: "Busqueda no Encontró Clientes" }
        })
    }
}

// Busqueda Simple
export const getAdminClientsAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    dispatch({
        type: SEARCH_CLIENTS,
        payload: { status: 1, message: "Buscando Clientes" }
    })

    try { //nombre rut telefono direccion
        const RES = await axios.post(window.API_SEARCH_SIMPLE_CLIENT, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: RES.data
            })
            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_CLIENTS,
                    payload: { status: 2, message: "Clientes Encontrados" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_CLIENTS,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_CLIENTS,
                    payload: { status: 3, message: "Busqueda no Encontró Clientes" }
                })
            }
        } else {
            dispatch({
                type: SEARCH_CLIENTS,
                payload: { status: 3, message: "Busqueda no Encontró Clientes" }
            })
        }

    } catch (error) {
        dispatch({
            type: SEARCH_CLIENTS,
            payload: { status: 3, message: "Busqueda no Encontró Clientes" }
        })
    }
}

// acciones
export const getCausasAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined /*|| DATA[propName] === ""*/) delete DATA[propName]

    dispatch({
        type: GET_REPORT_SUCCESS,
        payload: []
    })

    dispatch({
        type: SEARCH_CASE,
        payload: { status: 1, message: "Buscando Causas" }
    })


    try {
        const RES = await axios.post(window.API_SEARCH_CASE, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_REPORT_SUCCESS,
                payload: RES.data
            })
            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_CASE,
                    payload: { status: 2, message: "Causas Encontradas" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_CASE,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_CASE,
                    payload: { status: 3, message: "Busqueda no Encontró Causas" }
                })
            }
        } else {
            //alert("hubo un problema, reportelo por el sistema de requerimiento a TI, por favor")
            dispatch({
                type: SEARCH_CASE,
                payload: { status: 4, message: "Error al Buscar Novedades" }
            })
        }

    } catch (error) {
        console.log(error)
    }
}

// acciones
export const getNovedadesAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined /*|| DATA[propName] === ""*/) delete DATA[propName]

    dispatch({
        type: GET_REPORT_NOVEDADES_SUCCESS,
        payload: []
    })

    dispatch({
        type: SEARCH_NOVEDADES,
        payload: { status: 1, message: "Buscando Novedades" }
    })

    try {
        const RES = await axios.post(window.API_SEARCH_NOVEDADES, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_REPORT_NOVEDADES_SUCCESS,
                payload: RES.data
            })
            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_NOVEDADES,
                    payload: { status: 2, message: "Novedades Encontradas" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_NOVEDADES,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_NOVEDADES,
                    payload: { status: 3, message: "Busqueda no Encontró Novedades" }
                })
            }
        } else {
            dispatch({
                type: SEARCH_NOVEDADES,
                payload: { status: 4, message: "No se pudo Buscar Novedades" }
            })
        }
    } catch (error) {
        dispatch({
            type: SEARCH_NOVEDADES,
            payload: { status: 4, message: "Error al Buscar Novedades" }
        })
    }
}


export const getCausasFilterAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    dispatch({
        type: GET_REPORT_SUCCESS,
        payload: []
    })

    dispatch({
        type: SEARCH_CASE,
        payload: { status: 1, message: "Buscando Causas" }
    })



    try {
        const RES = await axios.post(window.API_SEARCH_CASE_ADVANCE, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_REPORT_SUCCESS,
                payload: RES.data
            })
            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_CASE,
                    payload: { status: 2, message: "Causas Encontradas" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_CASE,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_CASE,
                    payload: { status: 3, message: "Busqueda no Encontró Causas" }
                })
            }
        } else {
            dispatch({
                type: SEARCH_CASE,
                payload: { status: 4, message: "Error al Buscar Novedades" }
            })
        }

    } catch (error) {
        console.log(error)
    }
}

export const getNovedadesFilterAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    dispatch({
        type: GET_REPORT_NOVEDADES_SUCCESS,
        payload: []
    })

    dispatch({
        type: SEARCH_NOVEDADES,
        payload: { status: 1, message: "Buscando Novedades" }
    })


    try {
        const RES = await axios.post(window.API_SEARCH_NOVEDADES_ADVANCE, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_REPORT_NOVEDADES_SUCCESS,
                payload: RES.data
            })

            if (RES.data.length > 0) {
                dispatch({
                    type: SEARCH_NOVEDADES,
                    payload: { status: 2, message: "Novedades Encontradas" }
                })
                setTimeout(() => {
                    dispatch({
                        type: SEARCH_NOVEDADES,
                        payload: { status: 0, message: null }
                    })
                }, 3000);
            } else {
                dispatch({
                    type: SEARCH_NOVEDADES,
                    payload: { status: 3, message: "Busqueda no Encontró Novedades" }
                })
            }
        } else {
            alert("hubo un problema, reportelo por el sistema de requerimiento a TI, por favor")
        }

    } catch (error) {
        console.log(error)
        dispatch({
            type: SEARCH_NOVEDADES,
            payload: { status: 4, message: "Error al Buscar Novedades" }
        })
    }
}


export const getFileAction = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    dispatch({
        type: SET_START_DOWNLOAD_FILE,
        payload: { start: true, message: "Descargando Archivo, Espere un momento" }
    })
    try {

        axios({
            url: window.API_FILE_DOWNLOAD,
            method: "POST",
            responseType: "blob", // importante
            data: DATA,
            onDownloadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(percentCompleted)
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', DATA.nameAttachment)
            document.body.appendChild(link);
            link.click();

            dispatch({
                type: SET_START_DOWNLOAD_FILE,
                payload: { start: false, message: "Archivo Descargado" }
            })
            setTimeout(() => {
                dispatch({
                    type: SET_START_DOWNLOAD_FILE,
                    payload: { start: false, message: null }
                })
            }, 1000)
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: SET_START_DOWNLOAD_FILE,
            payload: { start: false, message: "El Archivo no Pudo ser descargado, reintente o comuniquese con el proveedor" }
        })
        setTimeout(() => {
            dispatch({
                type: SET_START_DOWNLOAD_FILE,
                payload: { start: false, message: null }
            })
        }, 1000)
    }
}


export const putCaseUpdate = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]

    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: PUT_SUCCESS_UPDATE_CASE,
        payload: { status: 0, message: null }
    })
    dispatch({
        type: GET_CAUSA_DETAIL_SUCCESS,
        payload: null
    })


    try {
        const RES = await axios.put(window.API_UPDATE_CASE, DATA)
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CASE,
                payload: { status: 1, message: "CAUSA ACTUALIZADA" }
            })

            dispatch({
                type: GET_CAUSA_DETAIL_SUCCESS,
                payload: RES.data
            })

        } else {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CASE,
                payload: { status: 2, message: "CAUSA NO PUDO SER ACTUALIZADA" }
            })
        }

        setTimeout(() => {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CASE,
                payload: { status: 0, message: null }
            })
        }, 1000)

    } catch (error) {
        console.log(error)
        dispatch({
            type: PUT_SUCCESS_UPDATE_CASE,
            payload: { status: 2, message: "CAUSA NO PUDO SER ACTUALIZADA, COMUNIQUESE CON EL PROVEEDOR" }
        })
        setTimeout(() => {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CASE,
                payload: { status: 0, message: null }
            })
        }, 1000)
    }
}


export const putClientUpdate = (DATA) => async (dispatch, getState) => {
    for (var propName in DATA)
        if (DATA[propName] === null || DATA[propName] === undefined || DATA[propName] === "") delete DATA[propName]


    const cookies = new Cookies();
    DATA.creatorId = cookies.get('user_id')

    dispatch({
        type: PUT_SUCCESS_UPDATE_CLIENT,
        payload: { status: 1, message: "ACTUALIZANDO CLIENTE", client: null }
    })

    let clientes = DATA.clientes
    delete DATA.clientes


    try {
        const RES = await axios.put(window.API_UPDATE_CLIENT, DATA)
        console.log(RES)
        if (RES.status === 200) {
            for (var i in clientes) {
                if (clientes[i].clientId == DATA.clientId) {
                    let aux = clientes[i];
                    clientes[i].desc = { ...aux, DATA };
                    break;
                }
            }

            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: clientes
            })

            dispatch({
                type: PUT_SUCCESS_UPDATE_CLIENT,
                payload: { status: 2, message: "CLIENTE ACTUALIZADO", client: DATA }
            })
            setTimeout(() => {
                dispatch({
                    type: PUT_SUCCESS_UPDATE_CLIENT,
                    payload: { status: 0, message: null, client: null }
                })
            }, 1500)
        } else {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CLIENT,
                payload: { status: 3, message: "CLIENTE NO PUDO SER ACTUALIZADO", client: null }
            })
        }
    } catch (error) {
        console.log(error)
        dispatch({
            type: PUT_SUCCESS_UPDATE_CLIENT,
            payload: { status: 3, message: "CLIENTE NO PUDO SER ACTUALIZADO, COMUNIQUESE CON EL PROVEEDOR", client: null }
        })
        setTimeout(() => {
            dispatch({
                type: PUT_SUCCESS_UPDATE_CLIENT,
                payload: { status: 0, message: null, client: null }
            })
        }, 1500)
    }
}

export const getActualizaciones = () => async (dispatch, getState) => {
    const cookies = new Cookies()
    try {
        const RES = await axios.get(window.API_UPDATES_INFO, { updaterId: cookies.get('user_id') })
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_UPDATES_SUCCESS,
                payload: RES.data
            })
        }
    } catch (error) {
        console.log(error)
    }
}


export const deleteTaskUser = (data) => async (dispatch, getState) => {
    for (var propName in data)
        if (data[propName] === null || data[propName] === undefined || data[propName] === "") delete data[propName]

    const cookies = new Cookies();
    data.userId = cookies.get('user_id')


    dispatch({
        type: GET_DELETE_TASK_USER,
        payload: { status: 1, message: "Removiendo Usuario Asignado" }
    })

    try {
        const RES = await axios.delete(window.API_DELETE_TASK_USER, { data: data })
        console.log(RES)
        if (RES.status === 200) {
            dispatch({
                type: GET_DELETE_TASK_USER,
                payload: { status: 2, message: "Usuario Asignado Removido" }
            })
        }

    } catch (error) {
        console.log(error)
        if (error.response.status === 401) {
            dispatch({
                type: GET_DELETE_TASK_USER,
                payload: { status: 3, message: "No Autorizado para remover Usuarios" }
            })
        }
    } finally {
        setTimeout(() => {
            dispatch({
                type: GET_DELETE_TASK_USER,
                payload: { status: 0, message: null }
            })
        }, 2000);
    }
}
