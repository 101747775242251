import React, { Fragment, useState, useEffect } from 'react'
import { Form, Modal, Col, Row, ModalHeader, ModalBody, ModalFooter, Button} from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import { GetInfoClient, SendNewClient } from '../../Redux/ClientesDucks'
import Select from 'react-select'


const NuevoCliente = ({CloseModal}) => {

    const dispatch = useDispatch()

    const { control, register: register, handleSubmit: handleSubmit } = useForm()

	const InfoClients = useSelector(store => store.clientes.InfoClients)

    useEffect(() => { dispatch(GetInfoClient()) }, []);
    useEffect(() => { console.log(InfoClients) }, [InfoClients]);
    
    const onSendClient = (event) => { dispatch(SendNewClient(event)) }

    return (
        <Fragment>
                <Form onSubmit={handleSubmit(onSendClient)} >
                    <ModalHeader>
                        <h3>Nuevo Cliente</h3>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg={12}>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Nombre Completo</Form.Label>
                                        <Form.Control type="text" {...register('name')} className="form-control" required/>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Identificador</Form.Label>
                                        <Form.Control type="text" {...register('identifier')} className="form-control" required/>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Clave Unica</Form.Label>
                                        <Form.Control type="text" {...register('claveUnica')} className="form-control" required/>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Correo</Form.Label>
                                        <Form.Control type="email" {...register('email')} className="form-control" required/>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Movil ej:+56912345678</Form.Label>
                                        <Form.Control type="tel" className="form-control" placeholder='+56912345678' {...register('telephone')}  pattern="^\+56[1-9](?:\d{8}|\d{9})$" title="El telefono no tiene el formato correcto " required/>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Nacionalidad</Form.Label>
                                        <Controller
                                            control={control}
                                            name="idCountry"
                                            render={({ field: { onChange, value, ref }}) => (
                                                <Select
                                                inputRef={ref}
                                                options={InfoClients?.countries}
                                                onChange={val => onChange(val.value)}
                                            />
                                            )}/>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Tipo Entidad</Form.Label>
                                        <Form.Select className="form-control" {...register('idIdentityType')}>
                                            <option value="0" selected>Seleccione</option>
                                            {InfoClients?.identityTypes?.map(object => { return(
                                                <option key={object.id} value={object.id} >{object.description} </option>
                                            )})}
                                        </Form.Select>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Sexo</Form.Label>
                                        <Form.Select className="form-control" {...register('idGenre')}>
                                            <option value="0" selected>Seleccione</option>
                                            {InfoClients?.genres?.map(object => {return(
                                                <option key={object.id} value={object.id} >{object.description} </option>
                                            )})}
                                        </Form.Select>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Estado Civil</Form.Label>
                                        <Form.Select className="form-control" {...register('idMaritalStatus')}>
                                            <option value="0" selected>Seleccione</option>
                                            {InfoClients?.maritalStatus?.map(elemento => { return(
                                                <option key={elemento.id} value={elemento.id} >{elemento.description} </option>
                                            )})}
                                        </Form.Select>
                                    </Col>
                                </Row>                            
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Dirección</Form.Label>
                                        <Form.Control type="text" className="form-control" {...register('address')} />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Región</Form.Label>
                                        <Controller
                                            control={control}
                                            name="idRegion"
                                            render={({ field: { onChange, value, ref }}) => (
                                                <Select
                                                inputRef={ref}
                                                options={InfoClients?.regions}
                                                onChange={val => onChange(val.value)}
                                            />
                                            )}/>

                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Sucursal</Form.Label>
                                        <Controller
                                            control={control}
                                            name="branchOfficeId"
                                            render={({ field: { onChange, value, ref }}) => (
                                                <Select
                                                inputRef={ref}
                                                options={InfoClients?.branchOffices}
                                                onChange={val => onChange(val.value)}
                                            />
                                            )}/>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">Abogado SAC</Form.Label>
                                        <Controller
                                            control={control}
                                            name="idSac"
                                            render={({ field: { onChange, value, ref }}) => (
                                                <Select
                                                inputRef={ref}
                                                options={InfoClients?.sacUsers}
                                                onChange={val => onChange(val.value)}
                                            />
                                            )}/>
                                    </Col>
                                    <Col className="form-outline">
                                        <Form.Label className="form-label">SAC asignado</Form.Label>
                                            <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                label="Es SAC asignado"
                                                {...register('saC5')}
                                            />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label className="form-label">Observaciones</Form.Label>
                                        <Form.Control as="textarea" {...register('observations')} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col className="form-outline text-right">
                                <Button variant="secondary" type="button" onClick={CloseModal} className="me-1" > Cerrar </Button>
                                <Button variant="primary" type="submit" className="me-1" > Guardar </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Form>
        </Fragment>
    );
}

export default NuevoCliente;