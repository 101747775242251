import React, { Fragment, useState, useEffect } from 'react'
import { Card, Form, Button, FloatingLabel, Modal, Toast, Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import tableCustomStyles from '../components/TableStyle'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'

import { getRutMonitoringAction, getOptionsMonitoringAction, updateMonitoringRutAction } from '../Redux/GeneralDucks'
import { useForm } from 'react-hook-form'
import moment from 'moment';

const FilterComponent = ({ filterRut, filterName, filterTribunal, filterText, filterDateStart, filterDateEnd,
    onFilter, onFilterDateStart, onFilterDateEnd, onFilterRut, onFilterName, onFilterTribunal, onClear }) => (
    <Row>
        <Col>
            Rut
            <Form.Control
                //id="searchStart"
                type="text"
                placeholder="Rut"
                aria-label="Search Input"
                value={filterRut}
                onChange={onFilterRut}
            />
        </Col>
        <Col>
            Nombre
            <Form.Control
                //id="search"
                type="text"
                placeholder="Nombre"
                aria-label="Search Input"
                value={filterName}
                onChange={onFilterName}
            />
        </Col>
        <Col>
            Tribunal
            <Form.Control
                //id="searchStart"
                type="text"
                placeholder="Tribunal"
                aria-label="Search Input"
                value={filterTribunal}
                onChange={onFilterTribunal}
            />
        </Col>
        <Col>
            Desde
            <Form.Control
                id="searchStart"
                type="date"
                aria-label="Search Input"
                value={filterDateStart}
                onChange={onFilterDateStart}
            />
        </Col>
        <Col>
            Hasta
            <Form.Control
                id="searchEnd"
                type="date"
                aria-label="Search Input"
                value={filterDateEnd}
                onChange={onFilterDateEnd}
            />
        </Col>
        <Col>
            Sac
            <Form.Control
                id="search"
                type="text"
                placeholder="Sac"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
        </Col>
        <Col>
            <div className='text-white'>.</div>
            <Button type="button" onClick={onClear}>
                X
            </Button>
        </Col >
        <strong className='mt-2'>Nota: Pueden aparece causas de clientes que no estan en Legal365 (Lex)</strong>
    </Row >
);

const PortafolioEstadoMonitoreoRut = () => {
    const { register, handleSubmit, control, reset } = useForm()
    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const [toastShow, setToastShow] = useState(false)

    const [rutActivo, setRutActivo] = useState(null)
    const [text, setText] = useState("Causas encontradas (no se encuentran el L365)")
    const [selectAplica, setSelectAplica] = useState(false)
    const [selectOptionsMonitoring, setSelectOptionsMonitoring] = useState(false)


    const rutsMonitoring = useSelector(store => store.general.rutsMonitoring)
    const optionsMonitoring = useSelector(store => store.general.optionsMonitoring)
    const closeModal = useSelector(store => store.general.closeModal)


    const [filterText, setFilterText] = useState('')
    const [filterDateStart, setFilterDateStart] = useState('')
    const [filterDateEnd, setFilterDateEnd] = useState('')
    const [filterRut, setFilterRut] = useState('')
    const [filterName, setFilterName] = useState('')
    const [filterTribunal, setFilterTribunal] = useState('')


    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)


    const handleShow = (row) => {
        setShow(true)
        setRutActivo(row)

    }
    const handleClose = () => setShow(false)
    const onChangeAplica = (event) => {
        setSelectAplica(event.value)
    }
    const columnasCausas = [
        {
            name: "Rut", cell: (row) =>
                <Button onClick={() => handleShow(row)} className="button btn-primary p-2 rounded"> {row.identifier} </Button>,
            ignoreRowClick: true,
            button: true
        },
        { name: "Nombre", cell: (row) => row.name, selector: row => row.name, sortable: false },
        { name: "Tipo", cell: (row) => row.type, selector: row => row.type, sortable: false },
        { name: "Rol", cell: (row) => row.rol, selector: row => row.rol, sortable: false },
        { name: "Caratulado", cell: (row) => row.labeled, selector: row => row.labeled, sortable: false },
        { name: "Proc", cell: (row) => row.proc, selector: row => row.proc, sortable: false },
        { name: "Tribunal", cell: (row) => row.tribunal, selector: row => row.tribunal, sortable: false },
        { name: "Actualizacion", cell: (row) => row.modificationDate, selector: row => row.modificationDate, sortable: true },
        { name: "Ingreso", cell: (row) => row.admissionDate, selector: row => row.admissionDate, sortable: true },
        { name: "SAC", cell: (row) => row.sac, selector: row => row.sac, sortable: true },
    ];

    /*const filteredItems = rutsMonitoring.filter(
        item => item.sac && item.sac.toLowerCase().includes(filterText.toLowerCase()),
    );*/
    const filteredItems = rutsMonitoring.filter(item => {

        const textMatch = filterText ? item.sac && item.sac.toLowerCase().includes(filterText.toLowerCase()) : true
        const RutMatch = filterRut ? item.sac && item.identifier.toLowerCase().includes(filterRut.toLocaleLowerCase()): true
        const NameMatch = filterName ? item.sac && item.name.toLowerCase().includes(filterName.toLocaleLowerCase()): true
        const TribunalMatch = filterTribunal ? item.sac && item.tribunal.toLowerCase().includes(filterTribunal.toLocaleLowerCase()): true

        const fecha = Number(moment(item.modificationDate, 'DD/MM/YYYY').format('YYYYMMDD')); // Especifica el formato correcto
        const startDateMatch = filterDateStart ? fecha >= Number(moment(filterDateStart).format('YYYYMMDD')) : true;
        const endDateMatch = filterDateEnd ? fecha <= Number(moment(filterDateEnd).format('YYYYMMDD')) : true;
        return textMatch && startDateMatch && endDateMatch && RutMatch && NameMatch && TribunalMatch
    });



    const paginationOptions = {
        rowsPerPageText: 'Mostrar',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    useEffect(() => {
        dispatch(getRutMonitoringAction())
        dispatch(getOptionsMonitoringAction())
    }, [])

    useEffect(() => {
        let aux = optionsMonitoring.map((item) => { return { value: item.id, label: item.name } })
        for (var propName in aux) if (aux[propName] === null) delete aux[propName]
        console.log(optionsMonitoring)
        setSelectOptionsMonitoring(aux)
        //setSearchUsers(false)

    }, [optionsMonitoring])


    useEffect(() => {
        if (rutsMonitoring)
            setText("Causas encontradas: " + rutsMonitoring.length + " (no se encuentran el L365)")
    }, [rutsMonitoring])

    const onSubmitForm = (event) => {
        event.MonitoringRutId = rutActivo.id
        event.MonitoringOptionId = selectAplica
        dispatch(updateMonitoringRutAction(event))
    }

    useEffect(() => {
        if (closeModal.message.length > 0) {
            handleClose()
            setToastShow(true)
        }
    }, [closeModal])
    const toastToggleShow = () => setToastShow(!toastShow);



    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
            if (filterDateStart) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterDateStart('');
            }
            if (filterDateEnd) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterDateEnd('');
            }

        };

        return (
            <>
                <FilterComponent  onFilterRut={e => setFilterRut(e.target.value)} onFilterName={e => setFilterName(e.target.value)} onFilterTribunal={e => setFilterTribunal(e.target.value)}  onFilter={e => setFilterText(e.target.value)} onFilterDateStart={e => setFilterDateStart(e.target.value)} onFilterDateEnd={e => setFilterDateEnd(e.target.value)} onClear={handleClear} filterText={filterText} filterDateStart={filterDateStart} filterDateEnd={filterDateEnd} />
            </>
        );
    }, [filterText, filterDateStart, filterDateEnd, resetPaginationToggle]);

    return (
        <Fragment>
            <div className="container-fluid px-lg-1 px-xl-3">
                <Card className="mb-4 w-100" style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title><h2 className="page-heading">Monitoreo de Rut</h2></Card.Title>
                        <Card.Text>
                            <DataTable className='table'
                                title={text}
                                columns={columnasCausas}
                                customStyles={tableCustomStyles}
                                data={filteredItems/*rutsMonitoring*/}
                                sortable={true}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeaderComponent={subHeaderComponentMemo}
                                paginationComponentOptions={paginationOptions}
                                subHeader
                                persistTableHead />
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clasificar Causa </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmitForm)}>
                        {rutActivo ?
                            <h6>{rutActivo.identifier} <br />{rutActivo.rol} <br /> {rutActivo.labeled}</h6> : ""
                        }


                        <Form.Label className=''>¿Aplica?</Form.Label>
                        <Select
                            onChange={(e) => onChangeAplica(e)}
                            options={selectOptionsMonitoring}
                            className="basic-single mb-4"
                            classNamePrefix="select"
                            //defaultValue={colourOptions[0]}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="color"
                            required
                        />

                        <Form > </Form>

                        <FloatingLabel controlId="floatingTextarea2" label="Comments">
                            <Form.Control
                                as="textarea"
                                maxLength={255}
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                {...register("result")}
                            />
                        </FloatingLabel>

                        <Button type="submit" className="button btn-primary p-2 mt-3 rounded"> Guardar </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Toast show={toastShow} onClose={toastToggleShow} bg="info" className='position-fixed top-0 end-0 mt-3 me-3' style={{ zIndex: "10000" }} delay={2000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Monitoreo de rut</strong>
                </Toast.Header>
                <Toast.Body>{closeModal.message}</Toast.Body>
            </Toast>
        </Fragment>


    );
}

export default PortafolioEstadoMonitoreoRut;
